<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        BOM列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">
        <template #caozuo="scope">


            <el-dropdown>
                <el-button type="primary">
                    操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <router-link :to="'/bom/xq?id=' + scope.row.id" style="margin-right: 5px;"> <el-button
                                    type="primary"  target="_blank">详情</el-button></router-link>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="dialogFormVisible = true; currItem = scope.row; name = scope.row.name"
                                type="warning" >修改名称</el-button>

                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="dialogFormVisible2 = true; currItem = scope.row; name = scope.row.name"
                            type="primary">复制</el-button>

                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="remove(scope.row.id)"  type="danger" >删除</el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>


        </template>

    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="修改BOM">


        <div class="flex d">
            <div class="l">名称</div>
            <div class="r"><el-input type="text" name="" id="" v-model="name" placeholder="请输入BOM的名称" /></div>
        </div>

        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible2" title="复制BOM">


        <div class="flex d">
            <div class="l">名称</div>
            <div class="r"><el-input type="text" name="" id="" v-model="name" placeholder="请输入BOM的名称" /></div>
        </div>

        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="copy()">
                    复制
                </el-button>
            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from "vue"
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { removeForId, upForId, copyForId } from "@/api/bom"
import { useBom } from "@/use/bom"
import { ElMessage } from "element-plus";
const dialogFormVisible = ref(false)
const currItem = ref()
const { search, searchData, tableObjet, headers } = useBom()
const name = ref()
const dialogFormVisible2 = ref()
const remove = (id) => {
    removeForId(id).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}

const copy = () => {
    if (name.value == "") {
        ElMessage.error("请输入BOM名称")
        return
    }
    copyForId(currItem.value.id, name.value).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible2.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit = () => {
    if (name.value == "") {
        ElMessage.error("请输入BOM名称")
        return
    }
    upForId(currItem.value.id, name.value).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>

<style lang="scss" scoped></style>