<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        BOM列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">
        <template #caozuo="scope">

           
            <el-button @click="hy(scope.row.id)" type="primary" round size="small">还原</el-button>
          
            <el-button @click="remove(scope.row.id)" round size="small" type="warning">彻底删除</el-button>
        </template>

    </TableComponent>

  
  
</template>
<script setup>
import { ref } from "vue"
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { removeForId, upForId,copyForId,huanyuan } from "@/api/bom"
import { useBom2 } from "@/use/bom"
import { ElMessage } from "element-plus";
const dialogFormVisible = ref(false)
const currItem = ref()
const { search, searchData, tableObjet, headers } = useBom2()
const name = ref()
const dialogFormVisible2=ref()
const remove = (id) => {
    removeForId(id).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const hy=(id)=>{
    huanyuan(id).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}


const copy = () => {
    if (name.value == "") {
        ElMessage.error("请输入BOM名称")
        return
    }
    copyForId(currItem.value.id, name.value).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible2.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit = () => {
    if (name.value == "") {
        ElMessage.error("请输入BOM名称")
        return
    }
    upForId(currItem.value.id, name.value).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>
<style lang="scss" scoped></style>