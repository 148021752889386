<template>
    <div v-if="!showMobile">
        <div class="homepage">
            <div class="ttt">
                极动仓库管理
            </div>


            <div class="cardlist" v-show="store.user_type == 1">
                <div class="card">
                    <div>
                        <svg t="1680055021147" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="12576" width="60" height="60">
                            <path
                                d="M835.5 743.9c-1-2.5-2.1-4.9-3.2-7.4-13.3-29.5-50.6-38.9-76.3-19.2l-0.6 0.6c-17 17-21.7 42.6-12.3 64.7 13.1 30.7 20.3 64.4 20.3 99.8 0 1.5-1.2 2.7-2.7 2.7H254.6c-1.5 0-2.7-1.2-2.7-2.7 0-138.8 111.3-250.8 249.3-254.4h4c13.1 0 26-1 38.7-3 4.9-0.7 9.9-1.6 15-2.8 0.3-0.1 0.6-0.1 1-0.2 40.4-9 77.9-28 109.4-55.8 0.2-0.2 0.5-0.4 0.7-0.7 3.8-3.4 7.5-6.9 11.2-10.5 47-47 72.9-109.6 72.9-176.1s-25.9-129-72.9-176.1c-47-47-109.6-72.9-176.1-72.9s-129 25.9-176.1 72.9c-47 47-72.9 109.6-72.9 176.1S282 507.9 329 555c4.2 4.2 8.6 8.3 13.1 12.2-31.7 16.6-60.5 37.9-86.1 63.4-32.7 32.7-58.3 70.7-76.2 113.1-18.6 43.9-28 90.5-28 138.5 0 20.5 1.8 41.1 5.3 61.3 4.2 24 25 41.4 49.3 41.4h602.5c24.3 0 45.1-17.5 49.3-41.4 3.5-20.2 5.3-40.8 5.3-61.3 0-47.8-9.4-94.4-28-138.3z m-326-513.7c78.8 2.3 142.7 66.4 144.6 145.2C656 453 598.2 517.7 523.5 527c-5.3-0.2-10.5-0.4-15.8-0.4-6.5 0-13 0.2-19.4 0.5-74.2-8.4-132.1-71.6-132.1-148-0.1-83.6 69.1-151.4 153.3-148.9z"
                                fill="#80DEEA" opacity=".8" p-id="12577"></path>
                            <path d="M212.9 929.3m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                                opacity=".8" p-id="12578"></path>
                            <path d="M416.9 573.9m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                                opacity=".8" p-id="12579"></path>
                            <path d="M802.5 929.8m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3"
                                opacity=".8" p-id="12580"></path>
                        </svg>
                    </div>
                    <div>

                        <div class="num">
                            5

                        </div>
                        <div class="cardtitle">
                            仓库数量
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div>
                        <svg t="1680054682987" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="6987" width="60" height="60">
                            <path d="M390.095238 755.809524h243.809524v146.285714H390.095238z" fill="#f4ea2a" p-id="6988">
                            </path>
                            <path
                                d="M731.428571 975.238095H292.571429c-26.819048 0-48.761905-21.942857-48.761905-48.761905s21.942857-48.761905 48.761905-48.761904h438.857142c26.819048 0 48.761905 21.942857 48.761905 48.761904s-21.942857 48.761905-48.761905 48.761905zM926.47619 780.190476H97.52381c-53.638095 0-97.52381-43.885714-97.52381-97.523809V146.285714c0-53.638095 43.885714-97.52381 97.52381-97.523809h828.95238c53.638095 0 97.52381 43.885714 97.52381 97.523809v536.380953c0 53.638095-43.885714 97.52381-97.52381 97.523809z"
                                fill="#f4ea2a" p-id="6989" data-spm-anchor-id="a313x.7781069.0.i20" class=""></path>
                            <path
                                d="M195.047619 572.952381c-14.628571-14.628571-14.628571-36.571429 0-51.2l185.295238-185.295238c14.628571-14.628571 36.571429-14.628571 51.2 0l131.657143 131.657143 212.114286-212.114286c14.628571-14.628571 36.571429-14.628571 51.2 0 14.628571 14.628571 14.628571 36.571429 0 51.2l-238.933334 238.933333c-14.628571 14.628571-36.571429 14.628571-51.2 0L407.161905 414.47619l-158.476191 158.476191c-17.066667 14.628571-39.009524 14.628571-53.638095 0z"
                                fill="#13227a" p-id="6990" data-spm-anchor-id="a313x.7781069.0.i21" class="selected"></path>
                        </svg>
                    </div>
                    <div>

                        <div class="num" style="color:#f00">
                            {{ data.allPrice }}
                        </div>
                        <div class="cardtitle">
                            总成本价值
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div>
                        <svg t="1680054682987" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="6987" width="60" height="60">
                            <path d="M390.095238 755.809524h243.809524v146.285714H390.095238z" fill="#f4ea2a" p-id="6988">
                            </path>
                            <path
                                d="M731.428571 975.238095H292.571429c-26.819048 0-48.761905-21.942857-48.761905-48.761905s21.942857-48.761905 48.761905-48.761904h438.857142c26.819048 0 48.761905 21.942857 48.761905 48.761904s-21.942857 48.761905-48.761905 48.761905zM926.47619 780.190476H97.52381c-53.638095 0-97.52381-43.885714-97.52381-97.523809V146.285714c0-53.638095 43.885714-97.52381 97.52381-97.523809h828.95238c53.638095 0 97.52381 43.885714 97.52381 97.523809v536.380953c0 53.638095-43.885714 97.52381-97.52381 97.523809z"
                                fill="#f4ea2a" p-id="6989" data-spm-anchor-id="a313x.7781069.0.i20" class=""></path>
                            <path
                                d="M195.047619 572.952381c-14.628571-14.628571-14.628571-36.571429 0-51.2l185.295238-185.295238c14.628571-14.628571 36.571429-14.628571 51.2 0l131.657143 131.657143 212.114286-212.114286c14.628571-14.628571 36.571429-14.628571 51.2 0 14.628571 14.628571 14.628571 36.571429 0 51.2l-238.933334 238.933333c-14.628571 14.628571-36.571429 14.628571-51.2 0L407.161905 414.47619l-158.476191 158.476191c-17.066667 14.628571-39.009524 14.628571-53.638095 0z"
                                fill="#13227a" p-id="6990" data-spm-anchor-id="a313x.7781069.0.i21" class="selected"></path>
                        </svg>
                    </div>
                    <div>

                        <div class="num" style="color:#f00">
                            {{data.bxPrice}}
                        </div>
                        <div class="cardtitle">
                            总变现价值
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div>
                        <svg t="1680054501544" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4208" width="60" height="60">
                            <path
                                d="M253.5 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-76.6 45.5-145.3 116-175.2 22.8-9.7 49.1 1 58.8 23.8 9.7 22.8-1 49.1-23.8 58.8C177 625.2 153 661.5 153 702c0 55.4 45.1 100.5 100.5 100.5S354 757.4 354 702c0-24.8 20.1-44.9 44.9-44.9s44.9 20.1 44.9 44.9c-0.1 104.9-85.4 190.2-190.3 190.2zM768.1 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-104.9 85.3-190.2 190.2-190.2S958.3 597.1 958.3 702c0 104.9-85.3 190.2-190.2 190.2z m0-290.7c-55.4 0-100.5 45.1-100.5 100.5s45.1 100.5 100.5 100.5S868.6 757.4 868.6 702s-45.1-100.5-100.5-100.5z"
                                fill="#36ab60" p-id="4209"></path>
                            <path
                                d="M768.1 746.9c-19.7 0-37.8-13.1-43.2-33L579.5 184.7c-3.7-13.5-0.9-28 7.6-39.1 8.5-11.1 21.7-17.7 35.7-17.7h184.8c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9h-126l129.8 472.4c6.6 23.9-7.5 48.6-31.4 55.1-4 1.2-8 1.7-11.9 1.7z"
                                fill="#36ab60" p-id="4210"></path>
                            <path
                                d="M484.4 746.9H253.5c-15.9 0-30.6-8.4-38.6-22.1-8.1-13.7-8.3-30.6-0.6-44.5l137.9-249.8-25.3-80.7h-73.3c-24.8 0-44.9-20.1-44.9-44.9s20.1-44.9 44.9-44.9h106.2c19.6 0 36.9 12.7 42.8 31.4l32.1 102.4H690c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9H462.9l64.3 204.9c4.3 13.6 1.8 28.5-6.6 40-8.5 11.6-21.9 18.4-36.2 18.4z m-154.9-89.7h93.7l-34-108.2-59.7 108.2z"
                                fill="#36ab60" p-id="4211"></path>
                        </svg>
                    </div>
                    <div>

                        <div class="num">
                            {{ data.pNum }}

                        </div>
                        <div class="cardtitle">
                            原材料数量
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div>
                        <svg t="1680054643852" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="6522" width="60" height="60">
                            <path
                                d="M749.149091 595.130182a170.682182 170.682182 0 1 1 0 341.317818 170.682182 170.682182 0 0 1 0-341.317818zM651.636364 77.218909v482.955636a219.461818 219.461818 0 0 0-86.667637 327.68H182.318545c-25.6 0-42.682182-17.035636-42.682181-42.635636V196.701091c0-21.317818 12.8-38.4 34.117818-42.682182L600.436364 47.383273c21.317818-4.282182 42.682182 8.517818 51.2 29.835636z m55.249454 579.584a17.082182 17.082182 0 0 0-28.997818 12.101818c0 4.514909 1.722182 8.843636 4.840727 11.962182l34.210909 34.071273h-36.026181a17.035636 17.035636 0 0 0 0 34.071273h51.2v34.071272h-51.2a17.035636 17.035636 0 0 0 0 34.071273h51.2v45.428364a16.989091 16.989091 0 0 0 34.024727 0v-45.381819h51.2a17.408 17.408 0 0 0 17.128727-17.221818 16.896 16.896 0 0 0-5.026909-11.915636 16.849455 16.849455 0 0 0-12.008727-4.980364h-51.2v-34.071272h51.2a17.035636 17.035636 0 0 0 0-33.978182h-36.026182l34.164364-34.164364a16.756364 16.756364 0 0 0 4.840727-11.962182 17.082182 17.082182 0 0 0-28.904727-12.101818l-42.356364 42.216727z m-12.567273-195.584l187.717819 72.564364c17.082182 8.517818 25.6 21.317818 25.6 38.4l0.046545 42.402909a218.903273 218.903273 0 0 0-213.410909-68.887273V461.218909z m-213.364363 42.682182H310.318545c-25.6 0-42.682182 17.082182-42.682181 42.682182 0 21.317818 17.082182 38.4 38.4 42.635636h174.917818c25.6 0 42.682182-17.035636 42.682182-42.635636s-17.082182-42.682182-42.682182-42.682182z m0-213.317818H310.318545c-25.6 0-42.682182 17.035636-42.682181 42.635636 0 21.364364 17.082182 38.4 38.4 42.682182h174.917818c25.6 0 42.682182-17.082182 42.682182-42.682182s-17.082182-42.635636-42.682182-42.635636z"
                                fill="#7159EB" p-id="6523"></path>
                        </svg>
                    </div>
                    <div>
                        <div class="num">
                            {{ data.wxcPrice }}

                        </div>
                        <div class="cardtitle">
                            外协仓总价值
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div>
                        <svg t="1680054577381" class="icon" viewBox="0 0 1033 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="5467" width="60" height="60">
                            <path
                                d="M853.333333 975.238095H170.666667c-53.638095 0-97.52381-43.885714-97.52381-97.523809V146.285714c0-53.638095 43.885714-97.52381 97.52381-97.523809h682.666666c53.638095 0 97.52381 43.885714 97.52381 97.523809v731.428572c0 53.638095-43.885714 97.52381-97.52381 97.523809z"
                                fill="#2C9EF4" p-id="5468"></path>
                            <path
                                d="M877.714286 48.761905H146.285714C65.828571 48.761905 0 114.590476 0 195.047619v165.790476C0 429.104762 58.514286 487.619048 129.219048 487.619048s129.219048-58.514286 129.219047-129.219048c0 70.704762 58.514286 129.219048 129.219048 129.219048s129.219048-58.514286 129.219047-129.219048c0 70.704762 58.514286 129.219048 129.219048 129.219048s129.219048-58.514286 129.219048-129.219048c0 70.704762 58.514286 129.219048 129.219047 129.219048s129.219048-58.514286 129.219048-129.219048V195.047619C1024 114.590476 958.171429 48.761905 877.714286 48.761905z"
                                fill="#95CEF9" p-id="5469"></path>
                            <path
                                d="M633.904762 975.238095H390.095238v-170.666666c0-68.266667 53.638095-121.904762 121.904762-121.904762s121.904762 53.638095 121.904762 121.904762v170.666666z"
                                fill="#FFFFFF" p-id="5470"></path>
                        </svg>
                    </div>
                    <div>
                        <div class="num">
                            {{ data.zcPrice }}

                        </div>
                        <div class="cardtitle">
                            资产总价值
                        </div>

                    </div>
                </div>


                <div class="card">
                    <div>
                        <svg t="1680054404255" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="2819" width="60" height="60">
                            <path
                                d="M821.333333 256a32 32 0 0 1 64 0v565.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H256c-64.8 0-117.333333-52.533333-117.333333-117.333334V202.666667c0-64.8 52.533333-117.333333 117.333333-117.333334h597.333333a32 32 0 0 1 0 64H256a53.333333 53.333333 0 0 0-53.333333 53.333334v618.666666a53.333333 53.333333 0 0 0 53.333333 53.333334h512a53.333333 53.333333 0 0 0 53.333333-53.333334V256zM341.333333 437.333333a32 32 0 0 1 0-64h341.333334a32 32 0 0 1 0 64H341.333333z m0 170.666667a32 32 0 0 1 0-64h213.333334a32 32 0 0 1 0 64H341.333333z"
                                fill="#1296db" p-id="2820"></path>
                        </svg>
                    </div>
                    <div>

                        <div class="num">
                            0

                        </div>
                        <div class="cardtitle">
                            线下订单数量
                        </div>
                    </div>
                </div>
                <!-- <div class="card">
                <div>
                    <el-icon size="80px" color="#fc5677">
                        <Histogram />
                    </el-icon>
                </div>
                <div>
                   
                    <div class="num">
                        {{ homeData.totalRevenue }}￥
                    </div>
                    <div class="cardtitle">
                        今日营收
                    </div>
                </div>
            </div> -->
                <div class="card">
                    <div>
                        <svg t="1680054501544" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4208" width="60" height="60">
                            <path
                                d="M253.5 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-76.6 45.5-145.3 116-175.2 22.8-9.7 49.1 1 58.8 23.8 9.7 22.8-1 49.1-23.8 58.8C177 625.2 153 661.5 153 702c0 55.4 45.1 100.5 100.5 100.5S354 757.4 354 702c0-24.8 20.1-44.9 44.9-44.9s44.9 20.1 44.9 44.9c-0.1 104.9-85.4 190.2-190.3 190.2zM768.1 892.2c-104.9 0-190.2-85.3-190.2-190.2 0-104.9 85.3-190.2 190.2-190.2S958.3 597.1 958.3 702c0 104.9-85.3 190.2-190.2 190.2z m0-290.7c-55.4 0-100.5 45.1-100.5 100.5s45.1 100.5 100.5 100.5S868.6 757.4 868.6 702s-45.1-100.5-100.5-100.5z"
                                fill="#36ab60" p-id="4209"></path>
                            <path
                                d="M768.1 746.9c-19.7 0-37.8-13.1-43.2-33L579.5 184.7c-3.7-13.5-0.9-28 7.6-39.1 8.5-11.1 21.7-17.7 35.7-17.7h184.8c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9h-126l129.8 472.4c6.6 23.9-7.5 48.6-31.4 55.1-4 1.2-8 1.7-11.9 1.7z"
                                fill="#36ab60" p-id="4210"></path>
                            <path
                                d="M484.4 746.9H253.5c-15.9 0-30.6-8.4-38.6-22.1-8.1-13.7-8.3-30.6-0.6-44.5l137.9-249.8-25.3-80.7h-73.3c-24.8 0-44.9-20.1-44.9-44.9s20.1-44.9 44.9-44.9h106.2c19.6 0 36.9 12.7 42.8 31.4l32.1 102.4H690c24.8 0 44.9 20.1 44.9 44.9s-20.1 44.9-44.9 44.9H462.9l64.3 204.9c4.3 13.6 1.8 28.5-6.6 40-8.5 11.6-21.9 18.4-36.2 18.4z m-154.9-89.7h93.7l-34-108.2-59.7 108.2z"
                                fill="#36ab60" p-id="4211"></path>
                        </svg>
                    </div>
                    <div>

                        <div class="num">
                            {{ data.bomNum }}

                        </div>
                        <div class="cardtitle">
                            BOM表数量
                        </div>
                    </div>
                </div>



            </div>

        </div>


        <div class="flex" style="display: none;">

            <div id="chart" v-show="store.user_type == 1">

            </div>
            <div id="chart4">

                <svg width="800" height="500" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <title>Layer 1</title>
                        <rect width="0" id="svg_10" height="1" y="285.69492" x="321.72931" stroke="#000" fill="#ffff56" />
                        <path id="svg_25" d="m340.72932,-0.30508l100,0l0,100l-100,0l0,-100z" opacity="undefined"
                            stroke="#000" fill="#ffd4aa" />
                        <rect stroke="#000" id="svg_26" height="100" width="100" y="-0.30508" x="240.72931"
                            fill="#ffd4aa" />
                        <rect x="0.72931" id="svg_27" height="30" width="800" y="109.69492" stroke="#000" fill="#b2b2b2" />
                        <path stroke="#000" id="svg_28"
                            d="m-11.98257,149.69491l486.54238,2.54238l-0.54238,98.98179l329.29263,0.67659l3.38984,300.50609l-818.68247,-1.69492l0,-401.01193z"
                            opacity="undefined" fill="#aaffd4" />
                        <rect stroke="#000" id="svg_30" height="80" width="155.93209" y="419.69491" x="0.72931"
                            fill="#ffd4aa" />
                        <rect stroke="#000" id="svg_31" height="250" width="100" y="154.69492" x="268.95806"
                            fill="#ffff00" />
                        <rect stroke="#000" id="svg_32" height="80" width="70" y="302.41374" x="396.83102" fill="#7fff00" />
                        <rect stroke="#000" id="svg_33" height="80" width="320.33889" y="419.69492" x="480.39041"
                            fill="#ffaaff" />
                        <rect id="svg_35" height="80" width="320" y="302.41373" x="480.72932" stroke="#000"
                            fill="#aad4ff" />
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="24"
                            stroke-width="0" id="svg_36" y="132.83047" x="358.09155" stroke="#000" fill="#000000">公路</text>
                        <text font-weight="bold" xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'"
                            font-size="12" id="svg_37" y="15.74577" x="273.7388" stroke="#000" fill="#ffd4aa">电池仓</text>
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_38" y="14.74577" x="372.72932" stroke="#000" fill="#007f3f">成品仓</text>
                        <path id="svg_39" d="m0.72931,151.69492l250,0l0,250l-250,0l0,-250z" opacity="undefined"
                            stroke="#000" fill="#aaff56" />
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_40" y="172.0847" x="113.22931" stroke="#000" fill="#ffd4aa">车架</text>
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_41" y="437.74576" x="57.69535" stroke="#000" fill="#ffd4aa">电池仓2</text>
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_42" y="177.0847" x="288.95807" stroke="#000" fill="#ffff00">仪表控制器</text>
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_44" y="319.46458" x="420.54711" stroke="#000" fill="#ffff00">电机</text>
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_45" y="321.46462" x="634.73564" stroke="#000" fill="#aad4ff">配件</text>
                        <text xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12"
                            id="svg_46" y="438.74578" x="637.56622" stroke="#000" fill="#aad4ff">纸箱</text>
                        <path id="svg_47"
                            d="m-119.84588,-109.33869l0.745,-0.54203l0.745,0.54203l-0.28456,0.87702l-0.92087,0l-0.28456,-0.87702l-0.00001,0z"
                            stroke="#000" fill="#ffd4aa" />
                    </g>

                </svg>

            </div>
        </div>
        <div class="flex">

            <div id="chart5">

            </div>
            <div id="chart6">

            </div>
        </div>

        <div class=" kucunjianbao">
            <div class="left" style="border-radius: 5px;overflow-y: scroll;">
                <div style="height: 50px;line-height: 50px ;font-weight: bold;margin-left: 15px;">
                    入库业务流
                </div>
                <div style="background-color: #F8FAFD;height: 30px;line-height: 30px;font-size: 20px;padding-left: 20px;">
                    入库单
                </div>
                <div style="background-color: #F8FAFD;flex:1; " class="cccc" v-if="data.ck">

                    <div v-for="i in data.ck.ruku"
                        style="background-color: #78eac6;color: #000;border-radius: 5px;padding: 10px 5px; text-align: center;margin-bottom: 10px;">

                        <div>
                            {{ i.pName }}
                        </div>

                        <div style="display: flex;font-size: 12px;margin-bottom: 5px;">

                            <div style="flex:1;">
                                仓库:{{ i.name }}
                            </div>
                            <div style="flex:1;">
                                入库数量:{{ i.number }}
                            </div>

                        </div>
                        <div style="display: flex;font-size: 12px;">
                            <div style="flex:1;">
                                操作人:{{ i.userName }}
                            </div>
                            <div style="flex:1;">
                                时间:{{ i.create_time }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="center">
                <div style="height: 50px;line-height: 50px ;font-weight: bold;margin-left: 5px;">
                    仓库简报
                </div>
                <div class="flex">
                    <div class="abc" @click="ckid = 1; getJb()" :class="ckid == 1 ? 'select' : ''">
                        九江原材料仓
                    </div>
                    <div class="abc" @click="ckid = 2; getJb()" :class="ckid == 2 ? 'select' : ''">
                        九江成品仓
                    </div>

                    <div class="flex" style="flex:1;justify-content: end;margin-right: 50px;">
                        <div class="abc" @click="day = 1; getJb()" :class="day == 1 ? 'select' : ''">
                            今天
                        </div>
                        <div class="abc" @click="day = 2; getJb()" :class="day == 2 ? 'select' : ''">
                            昨天
                        </div>
                        <div class="abc" @click="day = 3; getJb()" :class="day == 3 ? 'select' : ''">
                            本周
                        </div>
                        <div class="abc" @click="day = 4; getJb()" :class="day == 4 ? 'select' : ''">
                            本月
                        </div>
                        <div class="abc" @click="day = 5; getJb()" :class="day == 5 ? 'select' : ''">
                            今年
                        </div>
                    </div>
                </div>

                <div class="flex">
                    <div class="item">
                        <div>
                            入库单数
                        </div>
                        <div style="color: #7D3EFA;font-size: 22px;margin-top: 50px;">
                            {{ jb.rukuNum }}单
                        </div>
                    </div>
                    <div class="item">
                        <div>
                            入库产品总数
                        </div>
                        <div style="color: #7D3EFA;font-size: 22px;margin-top: 50px;">
                            {{ jb.rukuAllNum }}
                        </div>
                    </div>
                    <div class="item">
                        <div>
                            入库产品价值
                        </div>
                        <div style="color: #7D3EFA;font-size: 22px;margin-top: 50px;">
                            0元
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="item">
                        <div>
                            出库单数
                        </div>
                        <div style="color: #7D3EFA;font-size: 22px;margin-top: 50px;">
                            {{ jb.chukuNum }}单
                        </div>
                    </div>
                    <div class="item">
                        <div>
                            出库产品总数
                        </div>
                        <div style="color: #7D3EFA;font-size: 22px;margin-top: 50px;">
                            {{ jb.chukuAllNum }}件
                        </div>
                    </div>
                    <div class="item">
                        <div>
                            出库产品价值
                        </div>
                        <div style="color: #7D3EFA;font-size: 22px;margin-top: 50px;">
                            0元
                        </div>
                    </div>
                </div>
            </div>
            <div class="right" style="border-radius: 5px;overflow-y: scroll;">
                <div style="height: 50px;line-height: 50px ;font-weight: bold;margin-left: 15px;">
                    出库业务流
                </div>
                <div style="background-color: #F8FAFD;height: 30px;line-height: 30px;font-size: 20px;padding-left: 20px;">
                    出库单
                </div>
                <div style="background-color: #F8FAFD;flex:1; " class="cccc" v-if="data.ck">

                    <div v-for="i in data.ck.chuku"
                        style="background-color: #f5b0ed;color: #000;border-radius: 5px;padding: 10px 5px; text-align: center;margin-bottom: 10px;">

                        <div>
                            {{ i.pName }}
                        </div>

                        <div style="display: flex;font-size: 12px;margin-bottom: 5px;">

                            <div style="flex:1;">
                                {{ i.name }}
                            </div>
                            <div style="flex:1;">
                                出库数量:{{ i.number }}
                            </div>

                        </div>
                        <div style="display: flex;font-size: 12px;">
                            <div style="flex:1;">
                                操作人:{{ i.userName }}
                            </div>
                            <div style="flex:1;">
                                时间:{{ i.create_time }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <Index />
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { listO, fenxi } from "@/api/bom"
import { getALL, jianBao } from "@/api/home"


import { ElMessage } from 'element-plus';
import { list } from '@/api/banner';
import * as echarts from 'echarts';

import Index from "@/pages/moblie/index.vue"
import { useMainStore } from "/store/mainStore.js"
const store = useMainStore();
let showMobile = ref(true)
let width = window.document.body.clientWidth
const ckid = ref(1)
const day = ref(1)

const jb = ref({})

const getJb = () => {
    jianBao({ ckid: ckid.value, day: day.value }).then(res => {
        jb.value = res.data
    })

}

getJb()
if (width <= 800) {
    showMobile.value = true


} else {
    showMobile.value = false
}

getALL().then(res => {
    data.value = res.data
    setOption5()
    setOption6();
})
const homeData = ref({})
const myChart = ref({})
const myChart3 = ref({})
const myChart4 = ref({})
const myChart5 = ref({})
const myChart6 = ref({})
/**
 * 销毁 Echart实例
 */
const dispose = () => {
    echarts.init(document.getElementById("chart")).dispose();

    //echarts.init(document.getElementById("chart4")).dispose();
    //echarts.init(document.getElementById("chart3")).dispose();
    echarts.init(document.getElementById("chart5")).dispose();
    echarts.init(document.getElementById("chart6")).dispose();
}
/**
 * 初始化 Echart实例
 */
const init = () => {
    myChart.value = echarts.init(document.getElementById("chart"))
    //myChart4.value = echarts.init(document.getElementById("chart4"))
    // myChart3.value = echarts.init(document.getElementById("chart3"))
    myChart5.value = echarts.init(document.getElementById("chart5"))
    myChart6.value = echarts.init(document.getElementById("chart6"))

}
onMounted(() => {

    if (!showMobile.value) {
        dispose();
        init();

        setOption()
        // setOption6();
    }

})


const setOption = () => {
    setTimeout(function () {
        let option = {
            title: {
                text: '产品销售占比图',
                subtext: '',
                left: 'left'
            },
            legend: {},
            tooltip: {
                trigger: 'axis',
                showContent: false
            },
            dataset: {
                source: [
                    ['product', '2012', '2013', '2014', '2015', '2016', '2017'],
                    ['LX', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
                    ['NX2.4', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
                    ['NX4.0', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
                    ['LX4.0', 25.2, 37.1, 41.2, 18, 33.9, 49.1]
                ]
            },
            xAxis: { type: 'category' },
            yAxis: { gridIndex: 0 },
            grid: { top: '55%' },
            series: [
                {
                    type: 'line',
                    smooth: true,
                    seriesLayoutBy: 'row',
                    emphasis: { focus: 'series' }
                },
                {
                    type: 'line',
                    smooth: true,
                    seriesLayoutBy: 'row',
                    emphasis: { focus: 'series' }
                },
                {
                    type: 'line',
                    smooth: true,
                    seriesLayoutBy: 'row',
                    emphasis: { focus: 'series' }
                },
                {
                    type: 'line',
                    smooth: true,
                    seriesLayoutBy: 'row',
                    emphasis: { focus: 'series' }
                },
                {
                    type: 'pie',
                    id: 'pie',
                    radius: '30%',
                    center: ['50%', '25%'],
                    emphasis: {
                        focus: 'self'
                    },
                    label: {
                        formatter: '{b}: {@2012} ({d}%)'
                    },
                    encode: {
                        itemName: 'product',
                        value: '2012',
                        tooltip: '2012'
                    }
                }
            ]
        };
        myChart.value.on('updateAxisPointer', function (event) {
            const xAxisInfo = event.axesInfo[0];
            if (xAxisInfo) {
                const dimension = xAxisInfo.value + 1;
                myChart.value.setOption({

                    series: {
                        id: 'pie',
                        label: {
                            formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                        },
                        encode: {
                            value: dimension,
                            tooltip: dimension
                        }
                    }
                });
            }
        });
        myChart.value.setOption(option);
    });
}
const setOption6 = () => {
    let series = [
        {
            data: data.value.jilu.map(i => i.ruku),
            type: 'bar',
            stack: 'a',
            name: '入库'
        },
        {
            data: data.value.jilu.map(i => i.chuku),
            type: 'bar',
            stack: 'a',
            name: '出库'
        }
    ];
    const stackInfo = {};
    for (let i = 0; i < series[0].data.length; ++i) {
        for (let j = 0; j < series.length; ++j) {
            const stackName = series[j].stack;
            if (!stackName) {
                continue;
            }
            if (!stackInfo[stackName]) {
                stackInfo[stackName] = {
                    stackStart: [],
                    stackEnd: []
                };
            }
            const info = stackInfo[stackName];
            const data = series[j].data[i];
            if (data && data !== '-') {
                if (info.stackStart[i] == null) {
                    info.stackStart[i] = j;
                }
                info.stackEnd[i] = j;
            }
        }
    }
    for (let i = 0; i < series.length; ++i) {
        const data = series[i].data;
        const info = stackInfo[series[i].stack];
        for (let j = 0; j < series[i].data.length; ++j) {
            // const isStart = info.stackStart[j] === i;
            const isEnd = info.stackEnd[j] === i;
            const topBorder = isEnd ? 20 : 0;
            const bottomBorder = 0;
            data[j] = {
                value: data[j],
                itemStyle: {
                    borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
                }
            };
        }
    }
   
    let d = data.value.jilu.map(i => i.data)
    let option = {
        title: {
            text: '出入库记录',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: d
        },
        yAxis: {
            type: 'value'
        },
        series: series
    };
    myChart6.value.setOption(option);
}
const setOption5 = () => {
    let option = {
        title: {
            text: '仓库库存',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: '仓库名称',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: data.value.pNum, name: '九江原材料仓' },
                    { value: 0, name: '九江成品仓' },
                    { value: data.value.zcNum, name: '浩宇喷漆仓' },
                    { value: data.value.hyNum, name: '智彩喷漆仓' },
                    { value: data.value.yslNum, name: '永双利电池仓' }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    myChart5.value.setOption(option);

}
const headers3 = [
    {
        label: "产品ID",
        prop: 'p_id'
    },
    {
        label: "产品名称",
        prop: 'name'
    },
    {
        label: "所需数量",
        prop: 'n_number'
    },
    {
        label: "库存",
        prop: 'total'
    },
    {
        label: "需要补充",
        prop: 'buy_number'
    },
];
const hasList = ref([])
const pList = ref([])
const currSelectId = ref()
const number = ref()
const data = ref([])
// const js = () => {

//     if (!currSelectId.value) {
//         ElMessage.error("请选择要分析的BOM表")
//         return
//     }

//     if (!number.value) {
//         ElMessage.error("请输入要计算的数量")
//         return
//     }
//     fenxi(currSelectId.value, number.value).then(res => {
//         data.value = res.data
//     })
// }

// const dc = () => {

//     if (!currSelectId.value) {
//         ElMessage.error("请选择要分析的BOM表")
//         return
//     }

//     if (!number.value) {
//         ElMessage.error("请输入要计算的数量")
//         return
//     }
//     fenxi(currSelectId.value, number.value, true).then(res => {
//         data.value = res.data
//     })
// }
// listO().then(res => {
//     pList.value = res.data
// })
</script>
<style lang="scss">
.select {
    background-color: #5677fc;
    color: #fff;
}

.homepage {
    .cardlist {
        display: flex;
        width: 100%;
        margin-top: 10px;
        height: 110px;
        margin: auto;
        margin-left: 20px;
    }

    .card {
        flex: 1;
        margin-right: 20px;
        background-color: #fff;
        width: 350px;
        height: 100px;
        display: flex;
        border-radius: 5px;

        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
            width: 360px;
            height: 110px;
            box-shadow: 15px 15px 15px #aaa;
        }

        div {
            text-align: center;
        }

        .cardtitle {
            margin-bottom: 10px;
            font-size: 12px;
            color: var(--el-color-primary);
        }

        .num {

            margin: 10px;
            font-size: 20px;
        }
    }
}





.el-carousel__item {
    text-align: center;



    img {
        height: 25vh;
        width: 100%;
    }

}

.ck {
    width: 80vw;
    height: 40vw;


    color: #fff;
    margin: auto;
    box-sizing: border-box;
    margin-top: 20px;

    .a {
        text-align: center;
        height: 10vw;
        align-items: center;
        margin: 10px;
    }

    .b {
        text-align: center;
        align-items: center;
        height: 20vw;
        margin: 10px;
    }

    .l {
        cursor: pointer;
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        flex-direction: column;
    }

    .r {
        flex-direction: column;
        cursor: pointer;
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

#chart,
#chart3,
#chart4,
#chart5,
#chart6,
#chart2 {
    flex: 1;
    height: 500px;
    border-radius: 5px;
    margin: 10px 20px;
    background-color: #fff;
    box-shadow: 5px 5px 5px #eee;
    padding: 20px;
}

.kucunjianbao {
    display: flex;
    height: 35vh;
    justify-content: space-around;

    .left,
    .right {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 18%;
        height: 100%;

        .cccc {}
    }

    .center {
        display: flex;
        flex-direction: column;
        width: 60%;
        background-color: #fff;

        .abc {
            margin-left: 20px;
            height: 30px;
            line-height: 30px;
            border-radius: 5px;
            border: 1px solid #eee;
            padding: 2px 5px;
            cursor: pointer;
        }

        .item {

            background-color: #F4F9FE;
            flex: 1;
            height: 12vh;
            margin: 5px;
            border-radius: 5px;
            margin-top: 1.5vh;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

    }



}

.ttt {
    background-image: url("/public/titleBG2.png");
    text-align: center;
    color: #fff;
    font-size: 35px;
    background-size: 100%, 100%;
    height: 80px;
    line-height: 80px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
}

#chart4 {
    display: flex;
    justify-content: center;
}

.ck2 {

    width: 700px;
    height: 500px;
    background-color: #5677fc;
}
</style>
  