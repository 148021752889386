<template>
    <XIASearchBar @search="search" :searchData="searchData"/>
    <div class="title">
        出入库记录
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers" :psize="5">
    
        <template #img="scoped">
        
            <div>
               <img :src="'https://erp.jimoveebikes.com/storage/'+scoped.row.img" style="width: 100px;height: 100px;">
            </div>
        </template>
        <template #type="scoped">
        
        <div>
            {{scoped.row.type==1?'出库':'入库'}}
        </div>
    </template>
    </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useJilu} from "@/use/kucun"
const {search,searchData,tableObjet,headers}=useJilu()
</script>
<style lang="scss" scoped>
</style>