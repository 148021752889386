<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        组件列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #img="scoped">

            <div>
                <img :src="'https://erp.jimoveebikes.com/storage/' + scoped.row.img" style="width: 100px;height: 100px;">
            </div>
        </template>
        <template #qrcode="scoped">

            <div @click="fangda(scoped.row)">
                <vue-qr :logoScale="0.2" logoSrc="favicon.ico" :margin="5"
                    :text="'https://erp.jimoveebikes.com/#/?zid=' + scoped.row.id" :size="100"></vue-qr>
            </div>
        </template>

        <template #caozuo="scoped">
            <div>
                <div style="margin-bottom: 5px;">
                    <el-button @click="remove(scoped.row.id)">删除</el-button>
           
                </div>
                <div>
                    <RouterLink :to="'/product/zjedit?id=' + scoped.row.id"><el-button>详情</el-button></RouterLink>
                </div>
            </div>
        </template>
    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="查看二维码">
            <div id="qrcode">
                <div style="text-align: center; color: #000;font-weight: bold;">
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;组件名称：{{ leaseItem.name }}
                    </div>
                    <div style="margin-bottom: 5px;text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>


                    <vue-qr :logoScale="0.2" logoSrc="favicon.ico" :margin="5"
                        :text="'https://erp.jimoveebikes.com/#/?zid=' + leaseItem.id" :size="180"></vue-qr>

                   
                </div>

            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="dayin()">
                        打印
                    </el-button>

                    <el-button type="primary" @click="dialogFormVisible = false">
                        ok
                    </el-button>
                </span>
            </template>
        </el-dialog>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {ref} from "vue"
import { useZujian } from "@/use/product"

import {removeZJForId} from "@/api/product"
import { ElMessage } from 'element-plus';
const dialogFormVisible=ref()
const leaseItem=ref()
const fangda = (item) => {
    dialogFormVisible.value = true
    leaseItem.value = item
}
const remove=(id)=>{

    removeZJForId(id).then(res=>{
        ElMessage.info(res.msg)
        search()
    })
}
const { search, searchData, tableObjet, headers } = useZujian()
</script>
<style lang="scss" scoped></style>