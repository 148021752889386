<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        添加零件
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers" :height="400">

        <template #img="scoped">

            <div>
                <img :src="'https://erp.jimoveebikes.com/storage/' + scoped.row.img" style="width: 100px;height: 100px;">
            </div>
        </template>
        <template #include_tax="scoped">

            <el-tag>
                {{ scoped.row.include_tax == 1 ? '含税' : '不含税' }}
            </el-tag>

        </template>
        <template #caozuo="scoped">

            <div class="flex">
                <el-button @click="dialogFormVisible = true; currItem = scoped.row">
                    添加
                </el-button>

            </div>
        </template>
    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="出入库操作">
        <div class="flex d">
            <div class="l">产品名称</div>
            <div style="font-weight: bold;" class="r">{{ currItem.name }}</div>
        </div>
        <div class="flex d">
            <div class="l">所属供应商</div>
            <div style="color:red;font-weight: bold;" class="r">{{ currItem.s_name }}</div>
        </div>
        <div class="flex d">
            <div class="l">型号</div>
            <div class="r"> <el-tag>{{ currItem.model }}</el-tag></div>
        </div>
        <div class="flex d">
            <div class="l">编号</div>
            <div class="r"><el-tag>{{ currItem.number }}</el-tag></div>
        </div>
        <div class="flex d">
            <div class="l">所需数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入数量" /></div>
        </div>
        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    添加
                </el-button>
            </span>

        </template>
    </el-dialog>

    <div class="title">当前BOM表信息</div>




    <div>
        <div style="margin:20px;color: #5677fc;">已添加的零件列表</div>
        <el-table :data="list" class="table" :border="true" sum-text="合计" :max-height="400">
            <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers2" :key="key">
                <template #default="scope">
                    <div v-if="i.prop == 'caozuo'">
                        <el-button @click="remove(scope.row)">移除</el-button>
                    </div>
                    <div v-else-if="i.prop == 'img'">
                        <div>
                            <img :src="'http://127.0.0.1:8089/storage/' + scope.row.img"
                                style="width: 100px;height: 100px;">
                        </div>
                    </div>
                    <div v-else>
                        {{ scope.row[i.prop] }}
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <div style="margin:20px;align-items:center" class="flex">

            <div style="margin-right:20px;color: #5677fc;">
                添加组件
            </div>

            <el-select v-model="zuhe" clearable placeholder="请选择需要添加的组件" filterable style="margin-right:20px">
                <el-option v-for="o in bomList" :key="o.value" :label="o.label" :value="o.value" />
            </el-select>
            <el-input placeholder="请输入数量" v-model="bom_number" style="width:200px;margin-right:20px"></el-input>
            <el-button @click="addToBom">添加</el-button>
        </div>

        <el-table :data="hasList" class="table" :border="true" sum-text="合计" :max-height="400">
            <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers3" :key="key">
                <template #default="scope">
                    <div v-if="i.prop == 'caozuo'">
                        <el-button @click="removeBom(scope.row)">移除</el-button>
                    </div>

                    <div v-else>
                        {{ scope.row[i.prop] }}
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <div class="flex" style="margin-top:20px;margin-bottom:100px">
            <el-input placeholder="请输入BOM表名称" style="width:200px;margin-right:20px" v-model="name"></el-input>
            <el-input placeholder="请输入BOM备注" style="width:200px;margin-right:20px" v-model="remark"></el-input>
            <el-button @click="add">
                创建BOM表
            </el-button>
        </div>
    </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useProduct } from "@/use/product"
import { addBom, listO } from "@/api/bom"
import {getZujianListO} from "@/api/product"
import { ref } from "vue"
import { ElMessage } from 'element-plus';
const { search, searchData, tableObjet, headers } = useProduct()
const zuhe = ref("")
const name = ref("")
const remark = ref()
const bomList = ref([])
const hasList = ref([])
const bom_number=ref()
const headers2 = [
    {
        label: "id",
        prop: "id",
    },

    {
        label: "产品名称",
        prop: "name",
    },
    {
        label: "编号",
        prop: "number",
    },
    {
        label: "型号",
        prop: "model",
    },
    {
        label: "参数",
        prop: "parameter",
    },
    {
        label: "说明",
        prop: "illustrate",
    },
    {
        label: "照片",
        prop: "img",
    },
    {
        label: "分类",
        prop: "t_name",
    },

    {
        label: "所属供应商",
        prop: "s_name",
    },
    {
        label: "价格",
        prop: "price",
    },
    {
        label: "是否含税",
        prop: "include_tax",
    },

    {
        label: "所需数量",
        prop: "n_number",
    },
    {
        label: "操作",
        prop: "caozuo"
    }
];
const headers3 = [
    {
        label: "id",
        prop: "id",
    },

    {
        label: "组件名称",
        prop: "name",
    },


    {
        label: "所需数量",
        prop: "number",
    },
    {
        label: "操作",
        prop: "caozuo"
    }
];

getZujianListO().then(res => {
    bomList.value = res.data
})
const currItem = ref()

const number = ref(1)
const dialogFormVisible = ref(false)
const list = ref([])

const commit = () => {
    if (number.value <= 0) {
        ElMessage.error("请输入正确的数量")
        return
    }
    let has = false

    list.value.forEach(i => {
        if (i.id == currItem.value.id) {
            has = true
        }
    })
    if (has) {
        ElMessage.error("当前产品已添加到BOM表")
        return
    }
    currItem.value.n_number = number.value
    list.value.push(currItem.value)
    ElMessage.success("添加成功")
    dialogFormVisible.value = false

}
const remove = (item) => {
    list.value = list.value.filter(i => {
        return i.id != item.id
    })

}
const removeBom = (item) => {
    hasList.value = hasList.value.filter(i => {
        
        return i.id != item.id
    })

}
const add = () => {
    if (!name.value) {
        ElMessage.error("请输入Bom表的名称")
        return
    }
    if(list.value.length==0){
        ElMessage.error("请添加产品")
        return
    }
    let data = list.value.map(i => { return { id: i.id, number: i.n_number } })
    addBom({ list: JSON.stringify(data), name: name.value, remark: remark.value, bomList: JSON.stringify(hasList.value) }).then(res => {
        if (res.code == 200) {
            list.value = []
            name.value = ""
            remark.value = ""
            data=[]
            
            hasList.value=[]
            
            ElMessage.success(res.msg)
        } else {
            ElMessage.error(res.msg)
        }
    })
}

const addToBom=()=>{
    if(!zuhe.value){
        ElMessage.error("请选择要加入的BOM表")
        return
    }
    if(!bom_number.value){
        ElMessage.error("请输入数量")
        return
    }

    let has=false
    hasList.value.forEach(i=>{
        if(i.id==zuhe.value){
            has=true
        }
    })
    if(has){
        ElMessage.error("该BOM已添加")
        return
    }
    let item={}
   
    bomList.value.forEach(i=>{
       
        if(i.value==zuhe.value){

            item={number:bom_number.value,id:i.value,name:i.label}
        }
    })
   



    hasList.value.push(item)
}
</script>
<style lang="scss" scoped></style>