<template>
    <div>
        <FormComponent @submit="submit" title="运营配置" canEdit="true" :data="data" :isUpdateForm="true"></FormComponent>
    </div>
</template>

<script setup>
import FormComponent from '@/components/FormComponent.vue';
import { reactive, ref, watch, watchEffect,onMounted } from 'vue';
import { useGetStoreList } from "@/use/store"
import { getStoreConfig, updateStoreConfig } from "@/api/store"
import { ElMessage } from 'element-plus';
import {useMainStore} from "../../../store/mainStore"
const mainStore=useMainStore();
const admin_id = ref(mainStore.currSelectAdmin_id);
const store_id = ref(mainStore.currSelectStore_id);
const { stores_option } = useGetStoreList(admin_id);
const data = reactive({

    store_id: {
        label: "门店",
        type: "select",
        value: null,
        require:true,
        placeholder: "请选择门店",
        options: stores_option
    },
    
    open: {
        label: "运营开关",
        type: "switch",
        
        value: true
    },
    store_type: {
        label: "门店类型",
        type: "select",
        
        value: "",
        options:[
        {
        label:"默认(租五次可以提现)",
        value:1
      },
      {
        label:"模式A(前N次租金归平台)",
        value:2
      },
      {
        label:"模式B(次月提现)",
        value:3
      }
        ]
    },
    min_lease_count: {
        label: "最低租赁天数",
        type: "text",
        
        value: 150
    },
    server_fee: {
        label: "平台分成比例",
        type: "text",
        value: 0.6,
        require:true,
    },
    //  price_15: {
    //     label: "15天租金",
    //     type: "text",
    //     value: 300
    // },
    open_sz_yh: {
        label: "首租优惠",
        type: "switch",
        
        value: false
    },
    sz_yh_price: {
        label: "首租30天优惠",
        type: "text",
        
        value: ""
    },
    sz_yh_price_d15: {
        label: "首租15天优惠",
        type: "text",
        
        value: ""
    },
    sz_d15_fc: {
        label: "15天门店分成",
        type: "text",
        
        value: ""
    },
    open_15: {
        label: "15天开启",
        type: "switch",
        
        value: true
    },
    // car_price: {
    //     label: "30天租金",
    //     type: "text",
    //     value: 500
    // },
    
    // price_60: {
    //     label: "60天租金",
    //     type: "text",
    //     value: 900
    // },
    open_60: {
        label: "60天开启",
        type: "switch",
        
        value: true
    },
    // price_90: {
    //     label: "90天租金",
    //     type: "text",
    //     value: 1200
    // },
    open_90: {
        label: "90天开启",
        type: "switch",
        
        value: true
    },
    open_yh: {
        label: "续租优惠",
        type: "switch",
        
        value: true
    },
    kmd: {
        label: "跨门店换车",
        type: "switch",
        
        value: true
    },
    car_change_review: {
        label: "换车审核",
        type: "switch",
        
        value: true
        
    },
    pm_id:{
        label: "业务经理ID",
        type:"text" 
    },
    id_check: {
        label: "实名认证",
        type: "switch",
        
        value: true
    },
    pass_word: {
        label: "操作密码",
        type: "password",
        require:true,
        value: ""
    }
    // pay_type: {
    //     label: "支付方式",
    //     type: "select",
    //     placeholder: "请选择支付方式",
    //     value: null,
    //     require:true,
    //     options: [
    //         {
    //             label: "线上",
    //             value: 1
    //         },
    //         {
    //             label: "线下",
    //             value: 2
    //         }
    //     ]
    // }

})



watchEffect(() => {
    if (data.store_id.value)
        store_id.value = data.store_id.value
      //  mainStore.currSelectStore_id=data.store_id.value

})

watch(store_id, async (i) => {
    const res = await getStoreConfig(i);
    data.open.value = !!res.data.open
    data.car_change_review.value = !!res.data.car_change_review
    data.id_check.value = !!res.data.id_check
    data.pm_id.value = res.data.pm_id
    data.server_fee.value = res.data.server_fee
    data.sz_yh_price.value=res.data.sz_yh_price
    data.open_sz_yh.value=res.data.open_sz_yh==1
    data.sz_yh_price_d15.value=res.data.sz_yh_price_d15
    data.sz_d15_fc.value=res.data.sz_d15_fc
    
   // data.min_lease_count.value=res.data.min_lease_count
    // data.price_15.value=res.data.price_15
    // data.price_60.value=res.data.price_60
    // data.price_90.value=res.data.price_90
    data.open_15.value=res.data.open_15==1
    data.open_60.value=res.data.open_60==1
    data.open_90.value=res.data.open_90==1
    data.store_type.value=res.data.store_type

    
    
})
const submit = async (params) => {
    const { code, msg } = await updateStoreConfig(params);
    if (code == 200) {
        ElMessage.success(msg)
    } else {
        ElMessage.error(msg)
    }
}
</script>

<style lang="scss" scoped>
</style>