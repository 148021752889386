<template>
    <div style="height: 100vh;display: flex;flex-direction: column;box-sizing: border-box;">
        <div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <RouterLink to="/" style="color: #5677fc; width: 20vw; text-align: center;">返回</RouterLink>
            <div style="flex:1;text-align: center;padding-right: calc(20vw - 80px);font-weight: bold;">
                资产列表
            </div>
            <el-button @click="dialogFormVisible2 = true" type="primary"
                style="margin-right: 5px;margin-left: 5px;width: 80px;">新增</el-button>
        </div>

        <div class="flex" style="margin-top: 10px;">
            <el-input placeholder="输入名称搜索" style="margin:0 10px;flex:1;" v-model="name" ></el-input>
            <el-select v-model="type" placeholder="选择分类"  clearable style="flex:1;" >
                <el-option v-for="i in o"  :value="i.value">{{ i.label }}</el-option>
            </el-select>
            <el-button @click="search" type="primary" style="margin-right: 5px;margin-left: 5px;">搜索</el-button>
        </div>
        <div style="flex:1">
            <div class="flex h">

                <div>
                    资产名称
                </div>
                <div>
                    分类
                </div>
                <div>
                    价值
                </div>
                <div>
                    数量
                </div>


                <div>
                    操作
                </div>

            </div>
            <div class="flex item" v-for="i in data" :key="i.id">

                <div>
                    {{ i.name }}
                </div>
                <div>
                    {{ i.t_name }}
                </div>
                <div style="font-size: 12px;">
                    <el-tag>{{ i.price }}</el-tag>
                </div>

                <div>
                    {{ i.number }}
                </div>


                <div>

                    <el-button @click="currItem = i; dialogFormVisible = true" type="info" size="small"> 详情</el-button>

                </div>





            </div>
        </div>

        <div class="flex" style="padding: 20px;box-sizing: border-box;align-items: center;">
            <div>
                共{{ page }}页&nbsp;&nbsp;&nbsp;当前第{{ currPage }} 页
            </div>
            <el-button style="margin: 0 10px;" @click="currPage--" :disabled="currPage == 1">上一页</el-button>
            <el-button style="margin: 0 10px;" @click="currPage++" :disabled="currPage == page">下一页</el-button>
        </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="详情" width="100%">


        <div class="flex d">
            <div class="l">资产名称</div>
            <div class="r">{{ currItem.name }}</div>
        </div>
        <div class="flex d">
            <div class="l">类型</div>
            <div class="r">{{ currItem.t_name }}</div>
        </div>
        <div class="flex d">
            <div class="l">价值</div>
            <div class="r"><el-tag>{{ currItem.price }}</el-tag></div>
        </div>

        <div class="flex d">
            <div class="l">数量</div>
            <div class="r">{{ currItem.number }}</div>
        </div>
        <div class="flex d">
            <div class="l">总价值</div>
            <div class="r">{{ currItem.price * currItem.number }}</div>
        </div>
        <div class="flex d">
            <div class="l">可使用年限</div>
            <div class="r">{{ currItem.nx }}</div>
        </div>
        <div class="flex d">
            <div class="l">已使用年限</div>
            <div class="r">{{ currItem.ysy_nx }}</div>
        </div>
        <div class="flex d">
            <div class="l">折旧率</div>
            <div class="r">{{ currItem.zjl }}%</div>
        </div>
        <div class="flex d">
            <div class="l">折旧后总价值</div>
            <div class="r"> {{ currItem.number * currItem.price - ((currItem.number * currItem.price) * currItem.zjl *
                currItem.ysy_nx * 0.01) }}</div>
        </div>
        <template #footer>

            <span class="dialog-footer">


                <el-button @click="dialogFormVisible = false" size="small">关闭</el-button>
                <el-button @click="item2 = currItem; dialogFormVisible3 = true; dialogFormVisible = false" type="info"
                    size="small"> 修改</el-button>
                <el-popconfirm title="确定删除该资产?" @confirm="removeAsset(currItem.id)">
                    <template #reference>
                        <el-button type="danger" size="small">删除</el-button>
                    </template>
                </el-popconfirm>
            </span>

        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible2" title="新增" width="100%">


        <div class="flex d">
            <div class="l">资产名称</div>
            <div class="r"><input type="text" v-model="item.name" placeholder="请输入资产名称"> </div>
        </div>
        <div class="flex d">
            <div class="l">分类</div>
            <div class="r">
                <el-select v-model="item.type">
                    <el-option v-for="i in o" :value="i.value">{{ i.label }}</el-option>
                </el-select>
            </div>
        </div>
        <div class="flex d">
            <div class="l">价值</div>
            <div class="r"><input type="text" v-model="item.price" placeholder="请输入资产价值"></div>
        </div>

        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><input type="text" v-model="item.number" placeholder="请输入资产数量"></div>
        </div>

        <div class="flex d">
            <div class="l">可使用年限</div>
            <div class="r"><input type="text" v-model="item.nx" placeholder="请输入可使用年限"></div>
        </div>
        <div class="flex d">
            <div class="l">已使用年限</div>
            <div class="r"><input type="text" v-model="item.ysy_nx" placeholder="请输入已使用年限"></div>
        </div>
        <div class="flex d">
            <div class="l">折旧率%</div>
            <div class="r"><input type="text" v-model="item.zjl" placeholder="请输入资产折旧率"></div>
        </div>

        <template #footer>

            <span class="dialog-footer">


                <el-button @click="dialogFormVisible2 = false">关闭</el-button>
                <el-button @click="commit2" type="primary">新增</el-button>
            </span>

        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible3" title="修改" width="100%">


        <div class="flex d">
            <div class="l">资产名称</div>
            <div class="r"><input type="text" v-model="item2.name" placeholder="请输入资产名称"> </div>
        </div>
        <div class="flex d">
            <div class="l">分类</div>
            <div class="r">
                <el-select v-model="item2.asset_type_id">
                    <el-option v-for="i in o" :value="i.value">{{ i.label }}</el-option>
                </el-select>
            </div>
        </div>
        <div class="flex d">
            <div class="l">价值</div>
            <div class="r"><input type="text" v-model="item2.price" placeholder="请输入资产价值"></div>
        </div>

        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><input type="text" v-model="item2.number" placeholder="请输入资产数量"></div>
        </div>

        <div class="flex d">
            <div class="l">可使用年限</div>
            <div class="r"><input type="text" v-model="item2.nx" placeholder="请输入可使用年限"></div>
        </div>
        <div class="flex d">
            <div class="l">已使用年限</div>
            <div class="r"><input type="text" v-model="item2.ysy_nx" placeholder="请输入已使用年限"></div>
        </div>
        <div class="flex d">
            <div class="l">折旧率%</div>
            <div class="r"><input type="text" v-model="item2.zjl" placeholder="请输入资产折旧率"></div>
        </div>

        <template #footer>

            <span class="dialog-footer">


                <el-button @click="dialogFormVisible3 = false">关闭</el-button>
                <el-button @click="commit3" type="primary">修改</el-button>

            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue"
import { getZCLB } from "@/api/mobile"
import { useIndex } from "@/use/asset"
import { ElMessage } from 'element-plus';
const { o } = useIndex()
const dialogFormVisible = ref(false)
const dialogFormVisible2 = ref(false)
const dialogFormVisible3 = ref(false)

const name = ref("")
const page = ref(10)
const currPage = ref(1)
const data = ref([])
const type=ref("")
const currItem = ref()
const item = ref({
    name: "",
    price: "",
    number: "",
    type: "",
    zjl: "",
    nx: "",
    ysy_nx: "",
    type: null

})
const item2 = ref()

const commit3 = () => {
    xgAsset({ ...item2.value }).then(res => {
        if (res.code == 200) {
            dialogFormVisible3.value = false
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })

}
import { addAsset, removeAssetForId, xgAsset } from "@/api/asset"

const search = () => {
    getZCLB({ name: name.value, currPage: currPage.value,type:type.value }).then(res => {
        if (res.code === 200) {
            data.value = res.data.data
            page.value = res.data.page
        }
    })
}
watch(currPage, val => {
    search()
})
search()
const removeAsset = (id) => {
    removeAssetForId({ id }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit2 = () => {

    addAsset({ ...item.value }).then(res => {
        if (res.code == 200) {
            dialogFormVisible2.value = false
            ElMessage.success(res.msg)
            item.value = {
                name: "",
                price: "",
                number: "",
                type: "",
                zjl: "",
                nx: "",
                ysy_nx: "",
                type: null

            }
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>
<style scoped lang="scss">
.h {
    margin-top: 20px;
    height: 30px;
    align-items: center;
    background-color: #eee;

    div {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 12px;

    }
}

input {
    border: 1px solid #aaa;
    padding-left: 10px
}

.item {
    height: 50px;
    align-items: center;

    div {
        flex: 1;
        text-align: center;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
    }

}

.l {
    width: 100px;
}

.r {
    flex: 1;
}
</style>