import {$post} from "./base"
export async function assetTypeList(params){
    return $post("/asset/assetTypeList",params);
}
export async function index(params){
    return $post("/asset/index",params);
}
export async function addAssetType(params){
    return $post("/asset/addAssetType",params);
}
export async function addAsset(params){
    return $post("/asset/addAsset",params);
}
export async function xgAsset(params){
    return $post("/asset/xgAsset",params);
}
export async function assetTypeListO(){
    return $post("/asset/assetTypeListO");
}
export async function removeAssetType(params){
    return $post("/asset/removeAssetType",params);
}
export async function removeAssetForId(params){
    return $post("/asset/removeAssetForId",params);
}
export async function hfAssetForId(params){
    return $post("/asset/hfAssetForId",params);
}


export async function hsz(params){
    return $post("/asset/hsz",params);
}


export async function getAssetDetail(params){
    return $post("/asset/getAssetDetail",params);
}


