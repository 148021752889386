<template>
    <div class="flex">

        <input type="file" @change="change">
        <el-button @click="piliang">导入BOM</el-button>
    </div>

    <div class="tips">
        表格模板 按erp上导出的格式即可

    </div>
   
</template>

<script setup>
import {importBom} from "@/api/bom"
import { ElMessage } from "element-plus";
let file = null

const piliang = () => {

    if (file == null) {
        alert("请上传文件")

    } else {
        ElMessage.info("正在导入")
        importBom({file}).then(res=>{

            ElMessage.info(res.msg)
        })

    }
}
const change = (e) => {
    file = e.target.files[0];
    let allowedExtensions = /(\.xls)$/i;
    if (!allowedExtensions.exec(file.name)) {
        file = null
        alert('只允许上传xls格式的文件!');
        e.target.value = '';
        return false;
    }

}
</script>