
import {index,addAsset,addAssetType,assetTypeList,assetTypeListO,hsz} from "@/api/asset"
import { reactive, ref } from "vue";
import { useBase,  } from "@/use/base";

export function useIndex() {
  const o=ref([])
  const searchData = reactive({
    name: {
      label: "资产名称",
      type: "text",
      placeholder: "输入资产名称搜索",
      value: "",
    },
    type: {
      label: "分类",
      type: "select",
      placeholder: "选择分类",
      value: "",
      options: [],
    },
  });
  const headers = [
    {
      label: "资产名称",
      prop: "name",
    },
    {
      label: "二维码",
      prop: "qrcode",
    },
    {
      label: "所属分类",
      prop: "t_name",
    },
    {
      label: "数量",
      prop: "number",
    },
    {
      label: "单价",
      prop: "price",
    },
    {
      label: "总价",
      prop: "all_price",
    },
    {
      label: "当前使用年限",
      prop: "ysy_nx",
    },
    {
      label: "使用年限",
      prop: "nx",
    },
    {
      label: "折旧率%",
      prop: "zjl",
    },
    {
      label: "折旧后总价值",
      prop: "zj_price",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  assetTypeListO().then((res) => {
    searchData.type.options = res.data;
    o.value=res.data;
  });

  const { search, tableObjet } = useBase(
    index,
    searchData,
    null,
    null
  );

  return { headers, searchData, search, tableObjet,o };
}
export function useHSZ() {
  const o=ref([])
  const searchData = reactive({
    name: {
      label: "资产名称",
      type: "text",
      placeholder: "输入资产名称搜索",
      value: "",
    },
    type: {
      label: "分类",
      type: "select",
      placeholder: "选择分类",
      value: "",
      options: [],
    },
  });
  const headers = [
    {
      label: "资产名称",
      prop: "name",
    },

    {
      label: "所属分类",
      prop: "t_name",
    },
    {
      label: "数量",
      prop: "number",
    },
    {
      label: "单价",
      prop: "price",
    },
    {
      label: "总价",
      prop: "all_price",
    },
    {
      label: "当前使用年限",
      prop: "ysy_nx",
    },
    {
      label: "使用年限",
      prop: "nx",
    },
    {
      label: "折旧率%",
      prop: "zjl",
    },
    {
      label: "折旧后总价值",
      prop: "zj_price",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  assetTypeListO().then((res) => {
    searchData.type.options = res.data;
    o.value=res.data;
  });

  const { search, tableObjet } = useBase(
    hsz,
    searchData,
    null,
    null
  );

  return { headers, searchData, search, tableObjet,o };
}
export function useType() {

  const searchData = reactive({
    name: {
      label: "分类名称",
      type: "text",
      placeholder: "输入分类名称搜索",
      value: "",
    },

  });
  const headers = [
    {
      label: "分类名称",
      prop: "name",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];


  const { search, tableObjet } = useBase(
    assetTypeList,
    searchData,
    null,
    null
  );

  return { headers, searchData, search, tableObjet };
}
