<template>
    <div class="flex" style="align-items: center;justify-content: center;">
        <div v-if="data && data.bom" style="margin: 20px;text-align: center;">
            
            <span style="color:#5677fc;margin-left:20px;margin-right:20px">{{ data.bom.name
            }}</span>
        </div>
        <el-button @click="daochu()" type="primary">导出</el-button>
        <el-button @click="dayin()" icon="Printer" type="primary">打印</el-button>
    </div>

    <div style="margin:20px;align-items:center" class="flex">

        <div style="margin-right:20px">
            添加新的零件
        </div>

        <el-select v-model="p" clearable placeholder="请选择要添加的零件" filterable style="margin-right:20px">
            <el-option v-for="o in pO" :key="o.value" :label="o.label" :value="o.value" />
        </el-select>
        <el-input placeholder="请输入数量" v-model="p_number" style="width:200px;margin-right:20px"></el-input>
       
        <el-button icon="Plus" circle @click="addPToBom" type="primary"/>
    </div>


    <div style="margin: 20px;color: #5677fc;">
        包含的零件
    </div>

    <div class="flex" style="margin: 20px;">
        <el-input placeholder="输入产品名称筛选" v-model="pName" style="width: 200px;margin-right: 5px;"/>
        <el-input placeholder="输入产品编号筛选" v-model="pNumber" style="width: 200px;"/>

    </div>

    <div v-if="data">

        <el-table :data="data.pList" class="table" :border="true" sum-text="合计" :max-height="600">
            <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers3" :key="key">
                <template #default="scope">
                    <div v-if="i.prop == 'caozuo'">
                        <el-button @click="edit(scope.row)" circle icon="Edit"  type="warning">
                            
                        </el-button>
                        

                        <el-popconfirm title="将此产品移出BOM?" @confirm="removeBom(scope.row['bp_id'])">
                    <template #reference>
                        <el-button  circle icon="delete"  type="danger" ></el-button>
                    </template>
                </el-popconfirm>
                        
                    </div>
                    <div v-if="i.prop == 'img'">
                        <img :src="'https://erp.jimoveebikes.com/storage/' + scope.row[i.prop]"
                            style="width: 100px;height: 100px;">

                    </div>
                    <div v-else-if="i.prop == 'include_tax'">
                        {{ scope.row[i.prop] == 1 ? "含税" : "不含税" }}
                    </div>
                    <div v-else>
                        {{ scope.row[i.prop] }}
                    </div>
                </template>
            </el-table-column>

        </el-table>

    </div>
    <div v-if="data" id="qrcode" style="display: none;">

        <div v-if="data && data.bom" style="display: flex;align-items: center;justify-content: center;height: 110px;">

            <span style="color:#000;margin-left:20px;margin-right:20px;font-weight: bold;">{{ data.bom.name
            }}</span>
        </div>
        <table border="1" style="table-layout: fixed;width: 100%;">
            <tr style="height: 110px;">


                <td>
                    <div style="flex:1;text-align: center;">
                        产品名称
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        简称
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        产品编号
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        产品型号
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        所需数量
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        共用车型
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        二维码
                    </div>
                </td>


            </tr>
            <tr v-for="i in data.pList" style="height: 110px;">
                <td>
                    <div style="flex:1;text-align: center;font-size: 12px;">
                        {{ i.name }}
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        {{ i.remark }}
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        {{ i.number }}
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;font-size: 8px;">
                        {{ i.model }}
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        {{ i.n_number }}
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;font-size: 8px;">
                        {{ i.boms }}
                    </div>
                </td>
                <td>
                    <div style="flex:1;text-align: center;">
                        <vue-qr :logoScale="0.2" logoSrc="favicon.ico" :margin="5"
                            :text="'https://erp.jimoveebikes.com/#/?id=' + i.id" :size="100"></vue-qr>
                    </div>
                </td>
            </tr>
        </table>

    </div>

    <!-- <div style="margin:20px;align-items:center" class="flex">

        <div style="margin-right:20px">
            添加新组件
        </div>

        <el-select v-model="zuhe" clearable placeholder="请选择需要添加的组件" filterable style="margin-right:20px">
            <el-option v-for="o in bomList" :key="o.value" :label="o.label" :value="o.value" />
        </el-select>
        <el-input placeholder="请输入数量" v-model="bom_number" style="width:200px;margin-right:20px"></el-input>
       

        <el-button icon="Plus" circle @click="addBToBom" type="primary"/>
    </div> -->
    <!-- <div v-if="data && data.zBom">
        <div style="margin: 20px;color: #5677fc;">
            包含的组件
        </div>


        <el-table :data="data.zBom" class="table" :border="true" sum-text="合计" :max-height="400">
            <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers4" :key="key">
                <template #default="scope">
                    <div v-if="i.prop == 'caozuo'">
                        <router-link :to="'/product/zjedit?id=' + scope.row['id']">
                            <el-button>查看详情</el-button></router-link>

                        <el-button >
                            编辑数量
                        </el-button>
                        <el-button @click="removeBom(scope.row['bp_id'])">
                            移除
                        </el-button>
                    </div>
                    <div v-else-if="i.prop == 'img'">

                        <img :src="'https://erp.jimoveebikes.com/storage/' + scope.row[i.prop]"
                            style="width: 100px;height: 100px;">
                    </div>


                    <div v-else>
                        {{ scope.row[i.prop] }}
                    </div>
                </template>
            </el-table-column>
        </el-table>



    </div> -->


    <el-dialog v-model="dialogFormVisible" title="修改">

        
        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="text" name="" id="" v-model="currItem.n_number" placeholder="请输入要修改的数量" /></div>
        </div>
        <div class="flex d">
            <div class="l">单价</div>
            <div class="r"><el-input type="number" name="" id="" v-model="currItem.price" placeholder="请输入单价" /></div>
        </div>
        <div class="flex d">
            <div class="l">是否含税</div>
            <div class="r"><el-switch  name="" id="" v-model="currItem.include_tax"  /></div>
        </div>
        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router"
import { reactive, watch, ref } from "vue";
import { xq, daochuForId } from "@/api/bom"
import { getProductListO, getZujianListO } from '@/api/product'
import { listO, removeBomFormId, addBToBomFromId, upBPForId } from "@/api/bom"
import { ElMessage } from "element-plus";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import print from "print-js"
import { getLodop } from "@/use/LodopFuncs"
// 导入 jsPDF 库
import jsPDF from 'jspdf';
const dialogFormVisible = ref(false)
const currItem = ref()
const number = ref()
const route = useRoute();
const router = useRouter();
const p = ref()
const pO = ref([])
const id = ref(route.query.id)
const bomList = ref([])
const zuhe = ref("")
const bom_number = ref()
const p_number = ref()
const pName=ref("")
const pNumber=ref("")

const edit=(item)=>{
    item.include_tax=item.include_tax==1
    dialogFormVisible.value = true;
     currItem.value = item;
}
watch(pName,v=>{
    xq(id.value,pName.value,pNumber.value).then(res => {
    data.value = res.data
})
})
watch(pNumber,v=>{
    xq(id.value,pName.value,pNumber.value).then(res => {
    data.value = res.data
})
})
const daochu = () => {
    daochuForId(id.value).then(res => {

    })
}
const dayin = () => {
    const LODOP = getLodop()

    LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('qrcode').innerHTML)
    //  LODOP.ADD_PRINT_HTM(0,0,'100%','100%',document.getElementById('qrcode').innerHTML)
    // LODOP.SET_PRINT_PAGESIZE(1, 650, 710, "qrcode");
    LODOP.PREVIEW();
    //LODOP.PRINT();
    LODOP.PRINT();
    return
    print({
        printable: 'qrcode',
        type: "html",
        style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

    })
}
getProductListO().then(res => {
    pO.value = res.data.map(i=>{
        return {label:i.label+"-"+i.number,value:i.value}
    })


})
const headers3 = [
    {
        label: "id",
        prop: "id",
    },
    {
        label: "产品名称",
        prop: "name",
    },
    {
        label: "产品分类",
        prop: "t_name",
    },
    {
        label: "产品编号",
        prop: "number",
    },
    {
        label: "产品型号",
        prop: "number",
    },
    {
        label: "产品图片",
        prop: "img",
    },
    {
        label: "所属供应商",
        prop: "s_name",
    },

    {
        label: "单价",
        prop: "price",
    },
    {
        label: "是否含税",
        prop: "include_tax",
    },
    {
        label: "库存数量",
        prop: "total",
    },
    {
        label: "所需数量",
        prop: "n_number",
    },
    {
        label: "操作",
        prop: "caozuo"
    }
]
const headers5 = [

    {
        label: "产品名称",
        prop: "name",
    },

    {
        label: "产品编号",
        prop: "number",
    },
    {
        label: "产品型号",
        prop: "number",
    },
    {
        label: "产品图片",
        prop: "img",
    },
    {
        label: "所需数量",
        prop: "n_number",
    }
]
const headers4 = [
    {
        label: "id",
        prop: "id",
    },
    {
        label: "组件名称",
        prop: "name",
    },
    {
        label: "图片",
        prop: "img",
    },
    {
        label: "造价",
        prop: "price",
    },
    {
        label: "可以组装的数量",
        prop: "c_number",
    },
    {
        label: "所需数量",
        prop: "n_number",
    },
    {
        label: "操作",
        prop: "caozuo",
    },
]
const data = ref()

getZujianListO().then(res => {
    bomList.value = res.data
})
xq(id.value,pName.value,pNumber.value).then(res => {
    data.value = res.data
})
watch(() => route.query.id, i => {
    id.value = i
    xq(id.value,pName.value,pNumber.value).then(res => {
        data.value = res.data
    })
})
const addBToBom = () => {

    addBToBomFromId({ b_id: data.value.bom.id, type: 2, number: bom_number.value, id: zuhe.value }).then(res => {
        if (res.code == 200) {
            xq(id.value).then(res => {
                data.value = res.data
            })
            ElMessage.success(res.msg)
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const addPToBom = () => {
    addBToBomFromId({ b_id: data.value.bom.id, type: 1, number: p_number.value, id: p.value }).then(res => {
        if (res.code == 200) {
            xq(id.value,"","").then(res => {
                data.value = res.data
            })
            ElMessage.success(res.msg)
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const removeBom = (i) => {
    removeBomFormId(i).then(res => {
        if (res.code == 200) {
            xq(id.value,"","").then(res => {
                data.value = res.data
                console.log(id.value);
            })
            ElMessage.success(res.msg)

        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit = () => {
    if (number.value == "") {
        ElMessage.error("请输入要修改的数量")
        return
    }
    upBPForId({...currItem.value}).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
            // search()
            xq(id.value,"","").then(res => {
                data.value = res.data
            })

        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>

<style scoped lang="scss">
.aaaa {}

.table {
    width: 100vw;

    margin-bottom: 10px;

    background-color: var(--card-color);

    ::v-deep th .cell {
        text-align: center !important;
        color: #666 !important;


    }

    ::v-deep td .cell {
        text-align: center !important;
        color: #777;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}
</style>