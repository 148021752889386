import {addSupplier,getSupplierType,getSupplierIndustry,getSupplierList} from "@/api/supplier"
import {reactive,ref} from "vue"
import { ElMessage } from "element-plus";
import { useBase, useHandleEdit } from "@/use/base"
export function useAddSupplier() {
    const sType=ref([])
    const sIndustry=ref([])

    getSupplierIndustry().then(res=>{
        if(res.code==200){
            sIndustry.value=res.data
        }
    })
    getSupplierType().then(res=>{
        if(res.code==200){
            sType.value=res.data
        }
    })
    const formData = reactive({
        name: {
            type: 'text',
            label: "名称",
            require: true,
            placeholder: "请输入供应商名称",
            value: "",
        },
        
        s_type_id: {
            type: 'select',
            label: "分类",
            require: true,
            options: sType,
            value: "",
            placeholder: "请选择供应商分类"
        },
        industry_id: {
            type: 'select',
            label: "行业",
            require: true,
            options: sIndustry,
            value: "",
            placeholder: "请选择供应商所在行业"
        },
        contact: {
            type: 'text',
            label: "联系人",
            require: true,
            value: "",
            placeholder: "请输入联系姓名"
        },
        phone: {
            type: 'text',
            label: "手机号",
            require: true,
            value: "",
            placeholder: "请输入联系手机号"
        },
        introduce:{
            type: 'text',
            label: "简介",
           
            value: "",
            placeholder: "请输入供应商简介"
        },
        remark:{
            type: 'text',
            label: "备注",
            value: "",
            placeholder: "请输入备注"
        }
    })

    const submit = async (parmas) => {
        const { code, msg } = await addSupplier(parmas);
        if (code == 200) {
            ElMessage.success(msg)
            
        } else {
            ElMessage.error(msg)
        }
    }
    return { formData, submit }
}


export function useSupplier() {
    const searchData = reactive({
        
        s_type_id:{
            label:"分类",
            type:"select",
            placeholder:"选择供应商分类",
            value:"",
            options:[
                {
                    label:"原材料",
                    value:"1"
                },
                {
                    label:"成品",
                    value:"2"
                }
            ]
        }
    })
    const headers = [{
        label: "id",
        prop: "id"
    },
   
    
    {
        label: "供应商名称",
        prop: "name"
    }
        ,
    {
        label: "分类",
        prop: "t_name"
    }

        ,
    {
        label: "行业",
        prop: "i_name"
    },
       
    {
        label: "联系人",
        prop: "contact"
    },
    {
        label: "联系电话",
        prop: "phone"
    }
    , {
        label: "简介",
        prop: "introduce"
    },
    {
        label: "备注",
        prop: "remark"
    },
]
    const { search, tableObjet } = useBase(getSupplierList, searchData, null, null)

    return { headers, searchData, search, tableObjet }
}