<template>
    <div>
            <FormComponent :data="formData" title="新增产品" canEdit="true" @submit="submit">

            </FormComponent>
    </div>
</template>

<script  setup>
import FormComponent from '@/components/FormComponent.vue';
import {useAddProduct} from "@/use/product"
const {formData,submit}=useAddProduct();
</script>

<style scoped lang="scss">
::v-deep .cell{
    text-align: center;
}
</style>
