import { $post } from "./base";

export async function addSupplier(params) {
    return $post("/supplier/addSupplier",params);
}
export async function getSupplierType() {
  return $post("/supplier/getSupplierType");
}

export async function getSupplierIndustry() {
  return $post("/supplier/getSupplierIndustry");
}
export async function getSupplierList() {
    return $post("/supplier/getSupplierList");
  }
  export async function getSupplierO() {
    return $post("/supplier/getSupplierO");
  }

  
  
  