<template>
    <div class="top" @click="back">

        <div class="back">

            返回

        </div>
        <div class="t">
            产品详情
        </div>
    </div>
    <div v-if="product" style="text-align: center;">




        <img :src="'https://erp.jimoveebikes.com/storage/' + product.img" class="image"
            style="width: 100vw;height:30vh;object-fit:cover;" @click="checkImage()" />


        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                产品名称:
            </div>
            <div style="flex:1;text-align: left;font-weight: bold;">
                {{ product.name }}
            </div>
        </div>
        <div style="padding: 10px;box-sizing: border-box;align-items: center;" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                备注:
            </div>
            <div style="flex:1;text-align: left;">
                {{ product.remark }}
            </div>
            <div>
                <el-button @click="dialogFormVisible2 = true" type="warning">修改备注</el-button>
            </div>
        </div>


        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                供应商:
            </div>
            <div style="flex:1;text-align: left;">
                {{ product.s_name }}
            </div>
        </div>


        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                产品编号:
            </div>
            <div style="flex:1;text-align: left;">
                {{ product.number }}
            </div>
        </div>

        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                产品型号:
            </div>
            <div style="flex:1;text-align: left;font-size: 12px;">
                {{ product.model }}
            </div>
        </div>
        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                共用车型:
            </div>
            <div style="flex:1;text-align: left;display: flex; overflow-x: auto;">
                <el-tag v-for="b in product.gy" :key="b" style="margin:  5px;">{{ b }}</el-tag>
            </div>
        </div>







        <div style="padding: 12px" v-if="kucun.length > 0">
            <div style="margin-bottom: 20px; text-align: left;">
                库存信息:
            </div>
            <div style="padding: 12px; ">

                <div v-for="i in kucun" :key="i.id"
                    style="font-size: 12px;border-bottom: 1px solid #eee;width: 90vw;margin: auto; padding-bottom: 15px; display: flex;align-items: center;justify-content: center;">
                    仓库:<el-tag>{{ i.name }}</el-tag> 存放区域:<el-tag>{{ i.q_name }} </el-tag> 数量:<el-tag>{{ i.total
                        }}</el-tag>
                </div>
            </div>


        </div>
        <div v-else>
            当前产品无库存
        </div>

        <el-button @click="dialogFormVisible = true; type = 1" type="warning" style="width: 200px;margin-bottom: 20px;"
            v-if="kucun.length > 0">出库</el-button>
        <br>
        <el-button @click="dialogFormVisible = true; type = 2" type="primary" style="width: 200px;"> 入库</el-button>


        <div style="margin-top: 20px;">

        </div>


        <div>

        </div>
    </div>
    <div v-else style="text-align: center;margin: 50px;">
        <div>
            未能识别到产品信息,请关闭页面重新扫码
        </div>

    </div>


    <el-dialog v-model="dialogFormVisible" title="出入库" width="100%">


        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>
        <div class="flex d">
            <div class="l">仓库</div>
            <div class="r">


                <el-select v-model="w_id" clearable placeholder="请选择仓库" filterable style="width: 200px;">
                    <el-option v-for="o in ws" :key="o.id" :label="o.name" :value="o.id" />
                </el-select>
            </div>
        </div>
        <div class="flex d" v-if="type == 2 && w_id == 1">
            <div class="l">存放区域</div>
            <div class="r">


                <el-select v-model="q_id" clearable placeholder="请选择存放区域" filterable style="width: 200px;">
                    <el-option v-for="o in qs" :key="o.id" :label="o.name" :value="o.id" />
                </el-select>
            </div>
        </div>

        <div class="flex d" v-if="type == 25">

            <label for="pcm">生成批次码</label>
            <input type="checkbox" name="" id="pcm" v-model="createPCM" />

        </div>
        <div class="flex d" v-if="createPCM">
            <div class="l">
                批次码
            </div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="pcm" style="width: 200px;" />
            </div>
        </div>
        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    {{ type == 1 ? "出库" : "入库" }}
                </el-button>
            </span>

        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible2" title="修改备注" width="100%">


        <div class="flex d">
            <div class="l">备注</div>
            <div class="r"><el-input type="text" name="" id="" v-model="remark" placeholder="请输入备注"
                    style="width: 200px;" />
            </div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit2()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>
    <input type="file" @change="changefile" :data-name="img" hidden class="zjimg" accept="image/*" multiple="multiple"
        capture="camera">
</template>

<script setup>
import { useMainStore } from "/store/mainStore.js"
import { useRoute, useRouter } from "vue-router"
import { onMounted, ref, watch } from "vue";
import { getProduct, churuku, updateProdcutImg } from '@/api/product'
import { xgRemark } from "@/api/mobile"
import { ElMessage } from "element-plus";
const dialogFormVisible = ref(false)
const dialogFormVisible2 = ref(false)
const type = ref(1)
const number = ref()
const store = useMainStore()
const id = ref()
const q_id = ref()
const qs = ref([])
const product = ref()
const kucun = ref([])
const w_id = ref()
const ws = ref([])
const back = () => {
    router.back()
}

const createPCM=ref(false)
const pcm=ref("")
const remark = ref()
const router = useRouter()
const currItem = ref()
const route = useRoute()

let file
const src = ref()

const changefile = async (e) => {

    if (e.target.files.length > 0) {
        src.value = URL.createObjectURL(e.target.files[0]);

        file = e.target.files[0];

        // 创建一个 FileReader 对象
        var reader = new FileReader();

        // 设置读取完成后的回调函数
        reader.onload = function (e) {
            // 创建一个新的图片元素
            var img = new Image();

            // 设置图片的src为读取到的文件数据
            img.src = e.target.result;

            // 图片加载完成后执行
            img.onload = function () {
                // 创建一个Canvas元素
                var canvas = document.createElement("canvas");

                // 获取Canvas的2D上下文
                var ctx = canvas.getContext("2d");

                // 设置Canvas的大小为图片的实际大小
                canvas.width = img.width;
                canvas.height = img.height;

                // 将图片绘制到Canvas上
                ctx.drawImage(img, 0, 0);

                // 压缩图片，可以调整压缩质量（0.1 到 1 之间）
                var compressedDataURL = canvas.toDataURL("image/jpeg", 0.8);

                // 压缩后的数据可用于上传或展示

                function dataURLtoFile(dataURL, fileName) {
                    // 将Base64数据部分提取出来
                    var base64Data = dataURL.split(',')[1];

                    // 将Base64转换为Blob对象
                    var blob = b64toBlob(base64Data, 'image/jpeg');

                    // 创建一个File对象
                    var file = new File([blob], fileName, { type: 'image/jpeg' });

                    return file;
                }

                // 将Base64转换为Blob对象的辅助函数
                function b64toBlob(b64Data, contentType, sliceSize) {
                    contentType = contentType || '';
                    sliceSize = sliceSize || 512;

                    var byteCharacters = atob(b64Data);
                    var byteArrays = [];

                    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        var slice = byteCharacters.slice(offset, offset + sliceSize);

                        var byteNumbers = new Array(slice.length);
                        for (var i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        var byteArray = new Uint8Array(byteNumbers);

                        byteArrays.push(byteArray);
                    }

                    var blob = new Blob(byteArrays, { type: contentType });
                    return blob;
                }


                var fileName = compressedDataURL;
                var file2 = dataURLtoFile(compressedDataURL, fileName);
                ElMessage.info("正在上传图片!")
                updateProdcutImg({ id: product.value.id, img: file2 }).then(res => {
                    if (res.code == 200) {
                        getProduct(id.value).then(res => {
                            product.value = res.data
                            kucun.value = res.kucun
                            ws.value = res.ws
                            qs.value = res.qs
                        })
                        e.target.files = []

                        ElMessage.success("上传成功!")
                    }
                })
            };
        };

        // 读取文件数据
        reader.readAsDataURL(file);



    }

}
const checkImage = () => {


    src.value = ""
    file = null

    document.querySelector(".zjimg").click();
}
if (route.query.id) {

    id.value = route.query.id

    getProduct(id.value).then(res => {
        product.value = res.data
        kucun.value = res.kucun
        ws.value = res.ws
        qs.value = res.qs
    })
}

watch(() => route.query.id, (i) => {

    if (i != id.value) {
        id.value = i
        getProduct(id.value).then(res => {
            product.value = res.data
            kucun.value = res.kucun
            ws.value = res.ws
        })
    }

})

const commit2 = () => {
    if (!remark.value) {

        ElMessage.error("请输入备注")
        return
    }

    xgRemark({ remark: remark.value, id: product.value.id }).then(res => {
        dialogFormVisible2.value = false
        getProduct(id.value).then(res => {
            product.value = res.data
            kucun.value = res.kucun
            ws.value = res.ws
            qs.value = res.qs
        })
    })
}
const commit = () => {
    if (!number.value) {

        ElMessage.error("请输入数量")
        return
    }
    if (!w_id.value) {
        ElMessage.error("请选择仓库")
        return
    }
    if (w_id.value == 1 && !q_id.value && type == 1) {
        ElMessage.error("原材料仓库请选择存放区域")
        return
    }

    churuku({ id: id.value, number: number.value, type: type.value, w_id: w_id.value, q_id: q_id.value,createPCM:createPCM.value,pcm:pcm.value}).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
            getProduct(id.value).then(res => {
                product.value = res.data
                kucun.value = res.kucun
                ws.value = res.ws
                qs.value = res.qs
            })
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>


<style scoped lang="scss">
.el-dialog {
    --el-dialog-width: 80%;
}

.tabbar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    display: flex;
    height: 5vh;
    background-color: #f7f7f7;
    align-items: center;
    border-top: 1px solid #eee;

    div {
        flex: 1;
        text-align: center;
        color: #666;
    }

    .select {
        color: #5677fc;
        font-weight: bold;
    }
}

.time {
    font-size: 12px;
    color: #999;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    padding: 0;
    min-height: auto;
}

.image {
    width: 100%;
    display: block;
}

.card-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.box-card {
    width: 480px;
}

.top {
    display: flex;
    height: 5vh;
    align-items: center;
    border-bottom: 1px solid #eee;

    .back {
        width: 20vw;
        text-align: center;
        color: #5677fc;

    }

    .t {
        flex: 1;
        text-align: center;
        margin-left: -20vw;
        font-weight: bold;

    }
}
</style>