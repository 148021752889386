


<template>
    <div class="top" @click="back">

        <div class="back">

            返回

        </div>
        <div class="t">
            组件详情
        </div>
    </div>
    
    <div v-if="product" style="text-align: center;">




        <img :src="'https://erp.jimoveebikes.com/storage/' + product.zj.img" class="image"
            style="width: 100vw;height:30vh;object-fit:cover;" @click="checkImage()" />


        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                组件名称:
            </div>
            <div style="flex:1;text-align: left;font-weight: bold;">
                {{ product.zj.name }}
            </div>
        </div>
        
        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                共用车型:
            </div>
            <div style="flex:1;text-align: left;display: flex; overflow-x: auto;">
                <el-tag v-for="b in product.gy" :key="b" style="margin:  5px;">{{ b }}</el-tag>
            </div>
        </div>
        <div style="padding: 10px" class="flex">
            <div style="width: 25vw;text-align: left;color: #999;">
                库存信息:
            </div>
            <div style="flex:1;text-align: left;display: flex; overflow-x: auto;">
                <el-tag v-for="b in product.gy" :key="b" style="margin:  5px;">  {{ kucun }}</el-tag>
            </div>
        </div>






        

        <el-button @click="dialogFormVisible = true; type = 1" type="warning" style="width: 200px;margin-bottom: 20px;"
            v-if="kucun > 0">出库</el-button>
        <br>
        <el-button @click="dialogFormVisible = true; type = 2" type="primary" style="width: 200px;"> 入库</el-button>


        <div style="margin-top: 20px;">
            <div style="text-align: left; color: #5677fc;margin: 10px;">
                组件包含的产品
            </div> 
            <table border="1" style="width: 100%;">
                <tr>
                    <td>
                        产品名称
                    </td>
                    <td>
                        所需数量
                    </td>
                </tr>
                <tr v-for="i in product.list">
                    <td>
                        {{ i.name }}
                    </td>
                    <td>
                        {{ i.n_number }}
                    </td>
                </tr>
            </table>
        </div>


        <div>

        </div>
    </div>
    <div v-else style="text-align: center;margin: 50px;">
        <div>
            未能识别到产品信息,请关闭页面重新扫码
        </div>

    </div>


    <el-dialog v-model="dialogFormVisible" title="出入库" width="100%">


        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    {{ type == 1 ? "出库" : "入库" }}
                </el-button>
            </span>

        </template>
    </el-dialog>


    <input type="file" @change="changefile" :data-name="img" hidden class="zjimg" accept="image/*" multiple="multiple"
        capture="camera">
</template>

<script setup>
import { useMainStore } from "/store/mainStore.js"
import { useRoute, useRouter } from "vue-router"
import { onMounted, ref, watch } from "vue";
import { getzujianxq, churukuZ, updateProdcutImg } from '@/api/product'

import { ElMessage } from "element-plus";
const dialogFormVisible = ref(false)

const type = ref(1)
const number = ref()

const id = ref()
const q_id = ref()
const qs = ref([])
const product = ref()
const kucun = ref([])
const w_id = ref()
const ws = ref([])
const back = () => {
    router.back()
}

const router = useRouter()

const route = useRoute()

let file
const src = ref()

const changefile = async (e) => {

    if (e.target.files.length > 0) {
        src.value = URL.createObjectURL(e.target.files[0]);

        file = e.target.files[0];

        // 创建一个 FileReader 对象
        var reader = new FileReader();

        // 设置读取完成后的回调函数
        reader.onload = function (e) {
            // 创建一个新的图片元素
            var img = new Image();

            // 设置图片的src为读取到的文件数据
            img.src = e.target.result;

            // 图片加载完成后执行
            img.onload = function () {
                // 创建一个Canvas元素
                var canvas = document.createElement("canvas");

                // 获取Canvas的2D上下文
                var ctx = canvas.getContext("2d");

                // 设置Canvas的大小为图片的实际大小
                canvas.width = img.width;
                canvas.height = img.height;

                // 将图片绘制到Canvas上
                ctx.drawImage(img, 0, 0);

                // 压缩图片，可以调整压缩质量（0.1 到 1 之间）
                var compressedDataURL = canvas.toDataURL("image/jpeg", 0.8);

                // 压缩后的数据可用于上传或展示

                function dataURLtoFile(dataURL, fileName) {
                    // 将Base64数据部分提取出来
                    var base64Data = dataURL.split(',')[1];

                    // 将Base64转换为Blob对象
                    var blob = b64toBlob(base64Data, 'image/jpeg');

                    // 创建一个File对象
                    var file = new File([blob], fileName, { type: 'image/jpeg' });

                    return file;
                }

                // 将Base64转换为Blob对象的辅助函数
                function b64toBlob(b64Data, contentType, sliceSize) {
                    contentType = contentType || '';
                    sliceSize = sliceSize || 512;

                    var byteCharacters = atob(b64Data);
                    var byteArrays = [];

                    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        var slice = byteCharacters.slice(offset, offset + sliceSize);

                        var byteNumbers = new Array(slice.length);
                        for (var i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        var byteArray = new Uint8Array(byteNumbers);

                        byteArrays.push(byteArray);
                    }

                    var blob = new Blob(byteArrays, { type: contentType });
                    return blob;
                }


                var fileName = compressedDataURL;
                var file2 = dataURLtoFile(compressedDataURL, fileName);
                ElMessage.info("正在上传图片!")
                updateProdcutImg({ id: product.value.id, img: file2 }).then(res => {
                    if (res.code == 200) {
                        getProduct(id.value).then(res => {
                            product.value = res.data
                            kucun.value = res.kucun
                            ws.value = res.ws
                            qs.value = res.qs
                        })
                        e.target.files = []

                        ElMessage.success("上传成功!")
                    }
                })
            };
        };

        // 读取文件数据
        reader.readAsDataURL(file);



    }

}
const checkImage = () => {


    src.value = ""
    file = null

    document.querySelector(".zjimg").click();
}
if (route.query.id) {

    id.value = route.query.id

    getzujianxq(id.value).then(res => {
       
        product.value = res.data
        kucun.value = res.data.total

    })
}

watch(() => route.query.id, (i) => {

    if (i != id.value) {
        id.value = i
        getzujianxq(id.value).then(res => {
            product.value = res.data
            kucun.value = res.data.total

        })
    }

})

const commit = () => {
    if (!number.value) {

        ElMessage.error("请输入数量")
        return
    }
  

    churukuZ({ id: id.value, number: number.value, type: type.value}).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
            getzujianxq(id.value).then(res => {
                product.value = res.data
                kucun.value = res.data.total
               
            })
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>


<style scoped lang="scss">
.el-dialog {
    --el-dialog-width: 80%;
}

.tabbar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    display: flex;
    height: 5vh;
    background-color: #f7f7f7;
    align-items: center;
    border-top: 1px solid #eee;

    div {
        flex: 1;
        text-align: center;
        color: #666;
    }

    .select {
        color: #5677fc;
        font-weight: bold;
    }
}

.time {
    font-size: 12px;
    color: #999;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    padding: 0;
    min-height: auto;
}

.image {
    width: 100%;
    display: block;
}

.card-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.box-card {
    width: 480px;
}

.top {
    display: flex;
    height: 5vh;
    align-items: center;
    border-bottom: 1px solid #eee;

    .back {
        width: 20vw;
        text-align: center;
        color: #5677fc;

    }

    .t {
        flex: 1;
        text-align: center;
        margin-left: -20vw;
        font-weight: bold;

    }
}
</style>