<template>
    <XIASearchBar @search="search" :searchData="searchData" />

   
    <div class="title">
        资产回收站
    </div>

    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #all_price="s">
            {{ s.row.number * s.row.price }}
        </template>
        <template #zj_price="s">
            {{ s.row.number * s.row.price - ((s.row.number * s.row.price) * s.row.zjl * s.row.ysy_nx * 0.01) }}
        </template>

        <template #caozuo="s">

            <el-popconfirm title="确定还原资产?" @confirm="hfAsset(s.row.id)">
                <template #reference>
                    <el-button type="danger" plain round >还原资产</el-button>
                </template>
            </el-popconfirm>

        </template>
    </TableComponent>
   

</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useHSZ } from "@/use/asset"
import { churuku, getProduct } from "@/api/product"
import { addAsset,hfAssetForId } from "@/api/asset"


import { ref } from "vue"
import { ElMessage } from 'element-plus';

const { search, searchData, tableObjet, headers, o } = useHSZ()
const dialogFormVisible2 = ref()
const a_name = ref("")
const a_price = ref()
const a_number = ref()
const a_nx = ref()
const a_ysy_nx = ref()
const a_zjl = ref()
const a_type = ref()

const hfAsset=(id)=>{
    hfAssetForId({id}).then(res=>{
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>

<style lang="scss" scoped></style>