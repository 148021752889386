import { $post } from "./base";

export async function getKuncunList(params) {
    return $post("/kucun/getKuncunList",params);
  }
  export async function getZKuncunList(params) {
    return $post("/kucun/getZKuncunList",params);
  }
  export async function getWarehouseO(params) {
    return $post("/kucun/getWarehouseO",params);
  }
  export async function getWarehouseO2(params) {
    return $post("/kucun/getWarehouseO2",params);
  }
  export async function churuku(params) {
    return $post("/kucun/churuku",params);
  }

  export async function churukuwxc(params) {
    return $post("/kucun/churukuwxc",params);
  }
  


  export async function getWXKuncunList(params) {
    return $post("/kucun/getWXKuncunList",params);
  }

  export async function addkc(params) {
    return $post("/kucun/addkc",params);
  }
  export async function xgmc(params) {
    return $post("/kucun/xgmc",params);
  }
  


  
