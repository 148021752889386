<template>
    <div class="flex">

        <input type="file" @change="change">
        <el-button @click="piliang">导入价格</el-button>
    </div>

    <div class="tips">
        表格模板

    </div>
    <table border="1">
        <tr>
            <td>产品编号
            </td>

            <td>
                价格
            </td>
            <td>
                是否含税
            </td>
        </tr>
        <tr>
            <td>
                JM00121601
            </td>

            <td>
                10.3
            </td>
            <td>
                1(含税)  0(不含税)
            </td>
        </tr>
    </table>
</template>

<script setup>
import {importPrice} from "@/api/product"
import { ElMessage } from "element-plus";
let file = null

const piliang = () => {

    if (file == null) {
        alert("请上传文件")

    } else {
        importPrice({file}).then(res=>{

            ElMessage.info(res.msg)
        })

    }
}
const change = (e) => {
    file = e.target.files[0];
    let allowedExtensions = /(\.xls)$/i;
    if (!allowedExtensions.exec(file.name)) {
        file = null
        alert('只允许上传xls格式的文件!');
        e.target.value = '';
        return false;
    }

}
</script>