<template>
    <div>

        <div class="top">

            <div class='item'>
                <div class="left">
                    物品信息
                </div>
                <div class="right">
                    <div>
                        <span class="tag">租赁物品编号</span><span class="info">{{ device_number }}</span>

                    </div>
                    <div>
                        <span class="tag">设备编号</span><span class="info">{{ device_number }}</span>

                    </div>
                    <div>
                        <span class="tag">状态</span><span class="info">{{ deviceIfno.deviceStatus ? '在线' : '离线' }}</span>

                    </div>
                    <div>
                        <span class="tag">最后定位时间</span><span class="info">{{ deviceIfno.posDt }}</span>

                    </div>

                </div>
            </div>
            <div class='item'>
                <div class="left">
                    订单信息
                </div>
                <div class="right">
                    <div>
                        <span class="tag">最近租车人</span><span class="info">{{ orderInfo.phone }}</span>

                    </div>
                    <div>
                        <span class="tag">租赁日期</span><span class="info">{{ orderInfo.create_time }}</span>

                    </div>
                    <div>
                        <span class="tag">到期时间</span><span class="info">{{ orderInfo.end_time }}</span>

                    </div>
                </div>
            </div>
            <div class='item'>
                <div class="left">
                    租赁物品控制
                </div>
                <div class="right">

                    <div style="display: flex;">
                        <div>
                            <el-button @click="cron(11)" type="success">启动</el-button>


                        </div>
                        <div>
                            <el-button @click="cron(2)" type="primary">开锁</el-button>

                        </div>
                        <div>
                            <el-button @click="cron(1)" type="warning">关锁</el-button>

                        </div>
                        <div>
                            <el-button @click="cron(9)" type="info">寻车</el-button>

                        </div>
                        <div>
                            <el-button @click="cron(20)" type="danger">租车停用</el-button>
                            <el-button @click="cron(19)" type="primary">租车启用</el-button>
                        </div>

                    </div>
                    <div>
                        <span class="tag">车辆状态：</span> <span :class="deviceIfno.lock_state == 0 ? 'r' : 'g'">{{
                            deviceIfno.lock_state == 0 ? "已停用"
                            : "已启用" }}</span>
                    </div>
                    <div>
                        <span class="tag">车锁状态：</span> <span :class="deviceIfno.lock ? 'r' : 'g'">{{ deviceIfno.lock ? "已锁车"
                            : "已开锁" }}</span>
                    </div>
                    <div>
                        <span class="tag">通电状态：</span> <span :class="deviceIfno.power ? 'g' : 'r'">{{ deviceIfno.power ?
                            "已通电"
                            : "未通电" }}</span>
                    </div>
                    <div>
                        <span class="tag">自动关机时间：</span> <el-input type="number" style="width: 150px;"
                            v-model="autuOffTime" />
                        <el-button @click="cron(autuOffTime)">修改</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex_center">





            <div class="bar flex_center">
                <el-date-picker v-model="times" type="datetimerange" range-separator="To" start-placeholder="开始日期"
                    end-placeholder="结束日期" />

                <el-button @click="guiji" type="info" style="margin-left: 20px;">查询轨迹</el-button>

            </div>

            <el-button type="info" class="bar" @click="huifang">{{ huifang_interval ? "停止" : "回放" }}</el-button>
            <div class="bar">
                <el-button @click="carPosition" type="info">车辆位置</el-button>
            </div>
            <div class="bar">
                <el-button @click="storePosition" type="info">门店位置</el-button>
            </div>
        </div>

        <div class="flex" style="width: 50%;margin-left: 20px;">
            <el-slider v-model="value" :max="max" :marks="marks" />


        </div>
        <div id="allmap">

        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { itemInfo, deviceCron, lastLeaseUser, getGuiji } from "@/api/leaseItem"
import { ElMessage, ElNotification } from 'element-plus'
const device_number = ref()
const { query } = useRoute()
const autuOffTime = ref(1800)
const max = ref(100)
const router = useRouter()
const deviceIfno = ref({})
const orderInfo = ref({})
const locateTime = ref()
let coordinates = []
let newCoordinates = []
const huifang_interval = ref(null)
const value = ref([0, 10])
const curr_time = ref()
let _marks = {}
let map = null
const point = ref(0);
const storePoint = ref(0);
for (let i in coordinates) {
    _marks[i] = coordinates[i][2]
}

const marks = reactive(_marks)

device_number.value = query.device_number
if (!query.device_number) {
    router.push({ path: "/leaseitems/index" })
}
const cron = (i) => {
    let title = "修改"
    switch (i) {
        case 1:
            title = "关锁"
            break;
        case 2:
            title = "开锁"
            break;
        case 11:
            title = "启动"
            break;
        case 9:
            title = "寻车"
            break;
        case 20:
            title = "停用"
            break;
        case 19:
            title = "启用"
            break;
    }
    ElNotification({
        title,
        message: '正在' + title,
        type: 'info',
    })
    deviceCron({ code: i, device_number: device_number.value }).then(res => {
        count.value = 1
        if (res.ret == 1) {
            ElNotification({
                title,
                message: title + "命令发送成功",
                type: 'success',
            })
        }
    })
}


watch(value, (v) => {
    // let c=[]
    // for(let i in coordinates){
    //     if(i>=v[0]&&i<=v[1]){
    //         c.push(coordinates[i])
    //     }
    // }
    // if(c.length>0)
    if (!Array.isArray(v)) {
        _guiji(coordinates[v])
    }
    //
})
const huifang = () => {

    if (coordinates.length == 0) {
        ElMessage.info("请先获取轨迹")
        return
    }

    if (huifang_interval.value) {
        clearInterval(huifang_interval.value)
        newCoordinates = []
        huifang_interval.value = null
        return
    }

    let i = 0;
    huifang_interval.value = setInterval(() => {
        newCoordinates.push(coordinates[i])
        _guiji(newCoordinates[i], i != 0)
        i++
        if (i == coordinates.length) {
            clearInterval(huifang_interval.value)
            huifang_interval.value = null
        }
    }, 100);

}
const times = ref([new Date(new Date().getTime() - 86400 * 1000 * 3), new Date()])

let view = null
let lineLayer = null
const guiji = () => {



    function jisuanjili(lat1, lng1, lat2, lng2) {


        let radLat1 = lat1 * Math.PI / 180.0;
        let radLat2 = lat2 * Math.PI / 180.0;
        let a = radLat1 - radLat2;
        let b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
        let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
        s = s * 6378.137 * 1000; // 地球半径乘以1000，单位转换为米
        return s

    }
    ElMessage.info("正在查询")
    getGuiji({ start_time: times.value[0].getTime(), end_time: times.value[1].getTime(), device_number: device_number.value }).then(res => {
        if (res.code == 200) {

            if (res.data.length == 0) {
                ElMessage.error("暂无轨迹")
                return
            }
            coordinates = []
            ElMessage.success("获取成功")

            let length = res.data.length
            for (let i = 0; i < length; i++) {
                let curr = res.data[i]

                let next = res.data[i]
                if (i < length - 1) {
                    next = res.data[i + 1]
                }

                let s = jisuanjili(curr.lat, curr.lng, next.lat, next.lng)

                if (s > 5)
                    coordinates.push([
                        curr.lng, curr.lat, curr.create_time
                    ])
            }

            if(coordinates.length==0){
                ElMessage.error("暂无轨迹")
                return
            }
            max.value = coordinates.length
            value.value = [0, coordinates.length]
            _guiji()

        } else {

            ElMessage.error("获取轨迹失败")
        }


        //console.log(res);

    }).catch((err) => {
        console.log(err);
        ElMessage.error("服务器错误，请重试")
    })

}

const _guiji = (c = null, isNotMove) => {
    // map.clearOverlays();
    // 绘制线
    //     let arr=[]

    //     for(let i of coordinates){
    //         arr.push(
    //             new BMapGL.Point(i[0],i[1])
    //         )
    //     }

    // var polyline = new BMapGL.Polyline(arr, {
    //     strokeColor: 'blue',
    //     strokeWeight: 20,
    //     strokeOpacity: 0.5
    // });
    //     map.addOverlay(polyline);

    //     //console.log(arr);

    //     return




    var data = [{
        geometry: {
            type: 'LineString',
            coordinates: huifang_interval.value ? newCoordinates : coordinates
        }
    }];
    lineLayer.setData(data);
    //point.value = new BMapGL.Point(113.17368399306529, 23.020840839800254);
    if (!c) {

        if (!isNotMove)
            map.setCenter(new BMapGL.Point(coordinates[0][0], coordinates[0][1])); // 设置地图中心点
        addMarket(coordinates[0][0], coordinates[0][1])
        curr_time.value = coordinates[0][2]
    } else {
        if (!isNotMove)
            map.setCenter(new BMapGL.Point(c[0], c[1])); // 设置地图中心点
        addMarket(c[0], c[1])
        curr_time.value = c[2]
    }



}
const count = ref(0)
let interval = setInterval(() => {
    count.value++
    if (count.value < 5) {
        itemInfo({ device_number: device_number.value }).then(res => {

            if (res.code == 200) {

              
                storePoint.value = new BMapGL.Point(res.data.store_lng, res.data.store_lat);
                deviceIfno.value = res.data
                point.value = new BMapGL.Point(res.data.lonC, res.data.latC);
                locateTime.value = res.data.posDt
                curr_time.value = res.data.posDt


                if(count.value==4){
                    addMarket(res.data.lonC, res.data.latC,true)
                }else{
                    addMarket(res.data.lonC, res.data.latC)
                }
                
            }
        })
    }
}, 2000)
onUnmounted(() => {
    clearInterval(interval)
})

lastLeaseUser({ device_number: device_number.value }).then(res => {
    if (res.code == 200) {
        orderInfo.value = res.data
    }
})

const carPosition = () => {



    curr_time.value = locateTime.value
    map.setCenter(point.value); // 设置地图中心点

    addMarket(point.value.lng, point.value.lat, true)
}
const storePosition = () => {
    if (storePoint.value == "") {
        return;
    }
    map.setCenter(storePoint.value); // 设置地图中心点


    addMarket(point.value.lng, point.value.lat, true)
}
const addStoreMarket = () => {


    var myIcon = new BMapGL.Icon("/vue/md.png", new BMapGL.Size(50, 50));
    // 创建Marker标注，使用小车图标
    var pt = storePoint.value;
    var marker = new BMapGL.Marker(pt, {
        icon: myIcon
    });
    addOverlay(marker);


}
const addOverlay = (o) => {
    map.addOverlay(o);
}
const addMarket = (lonC, latC, isget) => {
    map.clearOverlays();
    // 创建小车图标
    var myIcon = new BMapGL.Icon("/vue/machine.png", new BMapGL.Size(50, 50));
    // 创建Marker标注，使用小车图标
    var pt = new BMapGL.Point(lonC, latC);
    var marker = new BMapGL.Marker(pt, {
        icon: myIcon
    });
    // 创建地理编码实例      
    var myGeo = new BMapGL.Geocoder();
    // 根据坐标得到地址描述    

    if (isget) {
        myGeo.getLocation(new BMapGL.Point(lonC, latC), function (result) {
            if (result) {

                // 创建信息窗口
                var opts = {
                    width: 200,
                    height: 100,
                    title: '设备编号:' + device_number.value
                };

                var infoWindow = new BMapGL.InfoWindow("定位时间:" + curr_time.value + "   位置：" + result.address, opts);
                // 点标记添加点击事件
                marker.addEventListener('click', function () {
                    map.openInfoWindow(infoWindow, pt); // 开启信息窗口
                });
            }
        });
    } else {
        // 创建信息窗口
        var opts = {
            width: 200,
            height: 100,
            title: '设备编号:' + device_number.value
        };

        var infoWindow = new BMapGL.InfoWindow("定位时间:" + curr_time.value, opts);
        // 点标记添加点击事件
        marker.addEventListener('click', function () {
            map.openInfoWindow(infoWindow, pt); // 开启信息窗口
        });
    }


    addStoreMarket()
    // 将标注添加到地图
    map.addOverlay(marker);
    map.addEventListener('click', function (e) {
        //alert('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat);
    });
    // // 绘制圆
    // var circle = new BMapGL.Circle(point.value, 500, {
    //     strokeColor: 'blue',
    //     strokeWeight: 2,
    //     strokeOpacity: 0.5
    // });
    // map.addOverlay(circle);
}
onMounted(() => {
    point.value = new BMapGL.Point(113.17268399306529, 23.020840839800254);

    // 百度地图API功能
    map = new BMapGL.Map("allmap");    // 创建Map实例
    map.centerAndZoom(point.value, 20);
    map.enableScrollWheelZoom(true);
    view = new mapvgl.View({
        map: map
    })
    lineLayer = new mapvgl.LineRainbowLayer({
        style: 'road', // road, arrow, normal
        width: 5,
        color: ['#00ac00']
    });

    view.addLayer(lineLayer);

})


const itemControll = reactive({

})
</script>

<style lang="scss" scoped>
.top {
    display: flex;

    .item {
        flex: 1;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        height: 20vh;
        background-color: #fff;
        border-radius: 5px;
        align-items: center;
        text-align: center;
        font-size: 20px;


        .left {
            width: 35%;
            background-color: var(--el-color-primary);

            margin: 10px;
            color: #fff;
        }

        .right {
            text-align: left;
            flex: 1;


            div {
                margin-top: 10px;
                font-size: 18px;

                .r {
                    color: #fff;
                    background-color: #999;
                    padding: 0 10px;
                    border-radius: 50px;
                }

                .g {
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 50px;
                    background-color: var(--el-color-primary);
                }
            }

            button {
                margin-right: 10px;
            }

        }

        .tag {
            color: #999;
            font-size: 18px;
            width: 150px;
            display: inline-flex;


        }

        .info {
            font-size: 18px;

        }

    }
}

.bar {
    margin: 20px;
}

#allmap {
    margin-left: 2%;
    margin-top: 5vh;
    width: 96%;
    height: 65vh;
    box-shadow: 15px 15px 15px #333;
}

.flex_center {
    display: flex;
    align-items: center;
}
</style>