import { $post } from "./base";

export async function addProduct(params) {
  return $post("/product/addProduct", params);
}
export async function getProductType(params) {
  return $post("/product/getProductType",params);
}

export async function addProductType(params) {
  return $post("/product/addProductType", params);
}
export async function getProductList(params) {
  return $post("/product/getProductList",params);
}


export async function addToAllBomForId(id){
  return $post("/product/addToAllBomForId",{id});
}
export async function getZujianList(params) {
  return $post("/product/getZujianList",params);
}
export async function updateProductType() {
  return $post("/product/updateProductType");
}
export async function getProductTypeO(parent_id) {
  return $post("/product/getProductTypeO",{parent_id});
}
export async function churuku(params){
    return $post("/product/churuku",params);
}
export async function churukuZ(params){
  return $post("/product/churukuZ",params);
}

export async function getJilu(params){
    return $post("/product/getJilu",params);
}
export async function getProductListO(){
  return $post("/product/getProductListO");
}
export async function getProduct(id){
  return $post("/product/getProduct",{id});
}
export async function getZujian(id){
  return $post("/product/getZujian",{id});
}

export async function addZujian(params){
  return $post("/product/addZujian",params);
}
export async function editZujian(params){
  return $post("/product/editZujian",params);
}
export async function removeZujianFromId(id){
  return $post("/product/removeZJForId",{id});
}

export async function getZujianListO(){
  return $post("/product/getZujianListO");
}
export async function updateProdcutImg(params){
  return $post("/product/updateProdcutImg",params);
}
export async function updateProdcut(params){
  return $post("/product/updateProdcut",params);
}

export async function getTypeO(){
  return $post("/product/getTypeO",);
}

export async function getQO(){
  return $post("/product/getQO",);
}

export async function getBomO(){
  return $post("/product/getBomO",);
}


export async function importPrice(params){
  return $post("/product/importPrice",params);
}
export async function importProduct(params){
  return $post("/product/importProduct",params);
}

export async function getzujianxq(id){
  return $post("/product/getzujianxq",{id});
}

export async function removeZJForId(id){
  return $post("/product/removeZJForId",{id});
}

