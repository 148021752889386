<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        库存查看
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #img="scoped">

            <div>
                <img :src="'https://erp.jimoveebikes.com/storage/' + scoped.row.img"
                    style="width: 100px;height: 100px;">
            </div>
        </template>

        <template #caozuo="scoped">


            <el-dropdown>
                <el-button type="primary">
                    操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>

                            <el-button @click="type = 2; product = scoped.row; ff()" type="primary"
                                style="margin: 5px;">
                                入库
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="type = 1; product = scoped.row; ff()" type="warning"
                                style="margin: 5px;">
                                出库
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="type = 3; product = scoped.row; ff()" type="warning"
                                style="margin: 5px;">
                                拆散
                            </el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <div style="display: flex;flex-direction: column;justify-content: center; align-items: center;">



            </div>

            <!-- <el-button @click="dialogFormVisible2 = true; product = scoped.row">修改备注</el-button> -->

        </template>

        <template #include_tax="scoped">

            <el-tag>
                {{ scoped.row.include_tax ? "含税" : "不含税" }}
            </el-tag>
        </template>
    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="出入库">


        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    {{ types[type] }}
                </el-button>
            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useZJKucun } from "@/use/kucun"
import { churuku } from "@/api/kucun"
import { ref } from "vue"
import { ElMessage } from 'element-plus';


const type = ref()
const number = ref(0)
const dialogFormVisible = ref(false)

const types = ["", "出库", "入库", "拆散"]

const product = ref()


const { search, searchData, tableObjet, headers } = useZJKucun()
const commit = () => {
    if (!number.value) {

        ElMessage.error("请输入数量")
        return
    }


    churuku({ id: product.value.id, number: number.value, type: type.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false

        } else {
            ElMessage.error(res.msg)
        }
    })
}

const ff = () => {

    dialogFormVisible.value = true
}
</script>

<style lang="scss" scoped></style>