<template>
    <div>
        <XIASearchBar @search="search" :searchData="searchData"/>   
        <div class="title">系统日志</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet">
            <template #type="scope">
            {{getType(scope.row.type)}}
            </template>

        </TableComponent>
    </div>
</template>

<script  setup>
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import TableComponent from '@/components/TableComponent.vue';
import {useSysLog} from "@/use/sysLog"

console.log(TableComponent);

const {headers,tableObjet,search,searchData}=useSysLog();
const getType=(type)=>{
const types={
    "1":"默认",
    "3":"订单相关",
    "4":"租金解冻",
    "369":"设备日志",
    "18":"支付回调"
}
return types[type]
}

let a =2
</script>

<style lang="scss" scoped>

</style>