import {
    addProduct,
    getProductType,
    addProductType,
    getProductTypeO,
    getProductList,
    getTypeO,
    getJilu,
    getBomO
  } from "@/api/product";
  


  import {getKuncunList,getWarehouseO,getZKuncunList,getWXKuncunList,getWarehouseO2} from "@/api/kucun"
  import { getSupplierO } from "@/api/supplier";
  import { reactive, ref, watch } from "vue";
  import { ElMessage } from "element-plus";
  import { useBase, useHandleEdit } from "@/use/base";

  export function useJilu() {
    const searchData = reactive({
      s_type_id: {
        label: "供应商",
        type: "select",
        placeholder: "选择供应商",
        value: "",
        options: [],
      },
      name:{
        label:"名称",
        type:"text",
        placeholder: "请输入产品名称",
        value: "",
      },
      number:{
        label:"编号",
        type:"text",
        placeholder: "请输入产品编号",
        value: "",
      },
      remark:{
        label:"备注",
        type:"text",
        placeholder: "请输入产品备注",
        value: "",
      },

      
    });
    const headers = [
      
  
      {
        label: "产品名称",
        prop: "name",
      },
      {
          label: "编号",
          prop: "p_number",
        },
        // {
        //   label: "型号",
        //   prop: "model",
        // },
        // {
        //   label: "参数",
        //   prop: "parameter",
        // },
        // {
        //   label: "说明",
        //   prop: "illustrate",
        // },
      {
        label: "照片",
        prop: "img",
      },
      {
        label: "分类",
        prop: "t_name",
      },
  
      {
        label: "所属供应商",
        prop: "s_name",
      },
      {
        label: "操作类型",
        prop: "type",
      },
      {
        label: "操作前数量",
        prop: "pre_number",
      },
      {
          label: "数量",
          prop: "number",
        },
  
        {
            label: "操作后数量",
            prop: "post_number",
          },
          {
            label:"仓库",
            prop:"warehouse"
          },
      {
        label:"操作人",
        prop:"user_name"
      },
      {
        label:"时间",
        prop:"create_time"
      },
      {
        label:"备注",
        prop:"remark"
      }
    ];
    const { search, tableObjet } = useBase(
        getJilu,
      searchData,
      null,
      null
    );
  
    return { headers, searchData, search, tableObjet };
  }
  export function useKucun() {


    const options = ref([])
  getTypeO().then(res=>{
    options.value=res.data
  })
    getBomO().then(res=>{
      bomOptions.value=res.data
    })
    const bomOptions=ref([])
    const searchData = reactive({
      supplier_id: {
        label: "供应商",
        type: "select",
        placeholder: "选择供应商",
        value: "",
        options: [],
      },
      name:{
        label:"产品名称",
        type:"text",
        placeholder: "输入产品名称搜索",
        value:""
      },
      number:{
        label:"产品编号",
        type:"text",
        placeholder: "输入产品编号搜索",
        value:""
      },
      remark:{
        label:"产品备注",
        type:"text",
        placeholder: "输入产品备注搜索",
        value:""
      },
      type:{
        label:"产品分类",
        type:"cascader",
        placeholder: "请选择产品分类",
        options:options,
        value: "",
      },
      warehouse:{
        label: "仓库",
        type: "select",
        placeholder: "选择仓库",
        value: "",
        options: [],
      },
      bom:{
        label:"所属Bom",
        type:"select",
        placeholder: "请选择所属Bom",
        options:bomOptions,
        value: "",
      },
    });
    const headers = [
      // {
      //   label: "id",
      //   prop: "id",
      // },
  
      {
        label: "产品名称",
        prop: "name",
      },
      {
        label: "产品备注",
        prop: "remark",
      },
      {
          label: "编号",
          prop: "number",
        },
        {
          label: "型号",
          prop: "model",
        },
        {
          label: "共用车型",
          prop: "gy",
        },
        // {
        //   label: "参数",
        //   prop: "parameter",
        // },
        // {
        //   label: "说明",
        //   prop: "illustrate",
        // },
      // {
      //   label: "照片",
      //   prop: "img",
      // },
      // {
      //   label: "分类",
      //   prop: "t_name",
      // },
  
      // {
      //   label: "所属供应商",
      //   prop: "s_name",
      // },
      // {
      //   label: "价格",
      //   prop: "price",
      // },
      // {
      //     label: "是否含税",
      //     prop: "include_tax",
      //   },
  
      {
        label: "仓库",
        prop: "warehouse",
      },
      {
        label: "库存数量",
        prop: "total",
      },
      {
        label: "成本价值",
        prop: "price",
      },
      {
        label: "变现价值",
        prop: "bx_price",
      },
      {
        label:"操作",
        prop:"caozuo"
      }
    ];

    getWarehouseO().then(res=>{
      searchData.warehouse.options=res.data
    })
    getSupplierO().then(res=>{
      searchData.supplier_id.options=res.data
    })
    const { search, tableObjet } = useBase(
      getKuncunList,
      searchData,
      null,
      null
    );
  
    return { headers, searchData, search, tableObjet };
  }
  export function useWXKucun() {


    const options = ref([])

    const bomOptions=ref([])
    const searchData = reactive({
      name:{
        label:"产品名称",
        type:"text",
        placeholder: "输入产品名称搜索",
        value:""
      },
      warehouse:{
        label: "仓库",
        type: "select",
        placeholder: "选择仓库",
        value: "",
        options: [],
      },
     
    });
    const headers = [
      {
        label: "产品名称",
        prop: "name",
      },
     
      {
        label: "仓库",
        prop: "w_name",
      },
      {
        label: "库存数量",
        prop: "total",
      },
      {
        label: "单价",
        prop: "price",
      },
      {
        label: "总价",
        prop: "all_price",
      },
      {
        label: "创建时间",
        prop: "create_time",
      },
      {
        label: "最后更新",
        prop: "update_time",
      },
      {
        label:"操作",
        prop:"caozuo"
      }
    ];

    getWarehouseO2().then(res=>{
      searchData.warehouse.options=res.data
    })
    
    const { search, tableObjet } = useBase(
      getWXKuncunList,
      searchData,
      null,
      null
    );
  
    return { headers, searchData, search, tableObjet };
  }
  export function useZJKucun() {
    getBomO().then(res=>{
      bomOptions.value=res.data
    })
    const bomOptions=ref([])
    const searchData = reactive({
     
      name:{
        label:"组件名称",
        type:"text",
        placeholder: "输入组件名称搜索",
        value:""
      },
      bom:{
        label:"所属Bom",
        type:"select",
        placeholder: "请选择所属Bom",
        options:bomOptions,
        value: "",
      },
    });
    const headers = [
      // {
      //   label: "id",
      //   prop: "id",
      // },
  
      {
        label: "组件名称",
        prop: "name",
      },
      {
        label: "备注",
        prop: "remark",
      },
     
      {
        label: "照片",
        prop: "img",
      },
      {
        label: "库存数量",
        prop: "total",
      },
      {
        label: "共用车型",
        prop: "gy",
      },
      {
        label: "成本价值",
        prop: "price",
      },
      {
        label: "变现价值",
        prop: "bx_price",
      },
      {
        label:"操作",
        prop:"caozuo"
      }
    ];

    getWarehouseO().then(res=>{
      searchData.warehouse.options=res.data
    })
    getSupplierO().then(res=>{
      searchData.supplier_id.options=res.data
    })
    const { search, tableObjet } = useBase(
      getZKuncunList,
      searchData,
      null,
      null
    );
  
    return { headers, searchData, search, tableObjet };
  }

  
 
  