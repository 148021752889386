import {$post} from "./base"
export async function getProductList(params){
   
   return $post("/mobile/getProductList",params)
}
export async function getKucunList(params){
   
    return $post("/mobile/getKucunList",params)
 }
 export async function getDBJL(params){
   
    return $post("/mobile/getDBJL",params)
 }
 export async function getBOMlist(params){
   
    return $post("/mobile/getBOMlist",params)
 }
 export async function getBomXQ(params){
   
    return $post("/mobile/getBomXQ",params)
 }
 export async function xgRemark(params){
   
    return $post("/mobile/xgRemark",params)
 }
 export async function getPO(params){
   
   return $post("/mobile/getPO",params)
}
 
export async function addToBom(params){
   
   return $post("/mobile/addToBom",params)
}
export async function removeToBom(params){
   
   return $post("/mobile/removeToBom",params)
}
export async function getZJList(params){
   
   return $post("/mobile/getZJList",params)
}
export async function getZCLB(params){
   
   return $post("/asset/index",params)
}
export async function getHSZZCLB(params){
   
   return $post("/asset/hsz",params)
}




 