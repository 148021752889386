import { getStoreList } from "@/api/store"
import { ElMessage } from "element-plus";
import { ref, watch, reactive } from "vue";
import { useGetAdminListForOption } from "@/use/admin"
import { useRouter } from "vue-router"
import { useMainStore } from "../../store/mainStore"
import { useBase } from "./base";


export function useStore() {
    const searchData = reactive({
        pre_name: {
            label: "门店前缀",
            type: 'text',
            placeholder: "输入门店前缀",
            value: ""
        },
        name: {
            label: "门店名",
            type: 'text',
            placeholder: "输入门店名称",
            value: ""
        },
        address: {
            label: "门店地址",
            type: 'text',
            placeholder: "输入门店地址",
            value: ""
        },
        phone: {
            label: "手机号",
            type: 'text',
            placeholder: "输入门店手机号",
            value: ""
        }
    })
    const { tableObjet, search } = useBase(getStoreList, searchData, null, null)
    return { search, searchData, tableObjet }
}
/**
 * 根据商户id 获取门店列表
 * @param {number} admin_id 
 * @returns 
 */
export function useGetStoreList() {

    const stores = ref([]);
    const stores_option = ref([])
    const currPage = ref(1);
    const dataCount = ref(0);
    const router = useRouter()
    const mainStore = useMainStore();
    //搜索框的数据
    const searchData = reactive({
        name: {
            label: "门店名",
            type: 'text',
            placeholder: "输入门店名称",
            value: ""
        }
    })
    _get()
    function _get() {
        getStoreList({isAll:true}).then((data) => {
            if (data.code == 200) {
                stores.value = data.data.data;
                for (let i in stores.value) {
                    stores.value[i].state = stores.value[i].state == 1 ? true : false
                }
                dataCount.value = data.data.dataCount;
                stores_option.value = data.data.data.map(i => {
                    return {
                        label: i.pre_name+"-"+i.name,
                        value: i.id
                    }
                })
            } else {
                ElMessage.error(data.msg);
            }
        })
    }
    const switchState = (item) => {
        item.state == 1 ? item.state = 0 : item.state = 1;
    }

    const storeConfig = (item) => {
        mainStore.menuTab.set("运营配置", "/store/setting")
        mainStore.currSelectAdmin_id = item.admin_id;
        mainStore.currSelectStore_id = item.id;
        router.push({ path: "/store/setting" })
    }
    const updateInfo = (item) => {
        console.log(item.admin_id, item.id);
    }
    const search = () => {

    }





    return { stores, stores_option, switchState, dataCount, currPage, search, searchData, storeConfig, updateInfo }
}
