<template>

    <div>
       
        <FormComponent @submit="submit" title="新增产品分类" :data="data" canEdit="true"></FormComponent>
    </div>
    </template>
    
    <script setup>
    import FormComponent from '@/components/FormComponent.vue';
    import {useAddType} from "@/use/product"
    
    const {data,submit}=useAddType();
    </script>