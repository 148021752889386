<template>
    <div class="search">
        <div class="left">
            <div class="item" v-for="(item, key) in searchData" :key="key" @keydown="keydown">
                <span>{{ item.label }}</span>
                <template v-if="item.type == 'text'">
                    <el-input type="text" v-model="item.value" :placeholder="item.placeholder" />
                </template>
                <template v-if="item.type == 'number'">
                    <el-input type="number" v-model="item.value" :placeholder="item.placeholder" />
                </template>
                <template v-if="item.type == 'select'">
                    <el-select v-model="item.value" clearable :placeholder="item.placeholder" filterable>
                        <el-option v-for="o in item.options" :key="o.value" :label="o.label" :value="o.value" />
                    </el-select>
                </template>
                <template v-if="item.type == 'datePick'">
                    <el-date-picker v-model="item.value" type="daterange" range-separator="To" start-placeholder="开始日期"
                        end-placeholder="结束日期" /></template>
                <template v-if="item.type == 'month'">
                    <el-date-picker v-model="item.value" type="month" />
                </template>
                <template v-if="item.type == 'cascader'">
                    <el-cascader v-model="item.value" :options="item.options" filterable  clearable :placeholder="item.placeholder"/>
                </template>

            </div>


        </div>
        <div class="right">
            <el-button type="primary" icon="Search" @click="search">查询</el-button>
            <el-button type="danger" icon="Delete" @click="clear">清空</el-button>
            <el-button type="info" @click="daochu">导出</el-button>
        </div>
    </div>
</template>

<script setup>

const { searchData } = defineProps(['searchData']);
const emit = defineEmits(['search', 'change', 'update:searchData', 'daochu'])

const keydown = (e) => {
    if (e.code == "NumpadEnter" || e.code == "Enter")
        emit("search")
}
const search = () => {
    emit("search")
}
const daochu = () => {

    emit("daochu")
}
const clear = () => {
    for (let i in searchData) {
        searchData[i].value = "";
    }
}
</script>
<style scoped lang="scss">
.left {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .item {
        display: flex;
        width: 25%;
        align-items: center;
        margin-bottom: 10px;

        span {
            font-size: 15px;
            color: #333;
            margin-right: 25px;
            width: 20%;
            text-align: center;

        }

        .el-input,
        .el-select {

            width: 70%;

            input {
                background-color: var(--base-color);
            }
        }
    }
}

.right {
    display: flex;
    flex-direction: column;

    .el-button {
        margin-left: 20px;
        width: 80px;
        margin-bottom: 10px;
        height: 30px;
    }
}

.search {
    display: flex;

    padding: 10px;
    background-color: var(--card-color);
    align-items: center;
    flex-wrap: wrap;
}
</style>