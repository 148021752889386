import { reactive, ref } from "vue";
import {getRCR,rcrTongguo} from "@/api/user"
import { ElMessage } from "element-plus";
import {useBase} from "@/use/base"
export function useRCR(){
    const searchData=reactive({
        phone:{
            label:"手机号",
            value:"",
            
            type:"text"
        },
        user_name:{
            label:"姓名",
            type:"text",
            placeholder:"输入用户姓名",
            value:""
        },
        device_number:{
            label:"设备编号",
            type:"text",
            placeholder:"输入设备编号",
            value:""
        },
        state:{
            label:"审核状态",
            value:2,
            type:"select",
            options:[
                {
                    label:"待审核",
                    value:2
                },
                {
                    label:"已通过",
                    value:1
                }
            ]
        }
    })
    const headers=[
        {label:"ID",prop:"Id"},
       
        {label:"所属门店",prop:"name"},
        {label:"手机号",prop:"phone"},
        {label:"姓名",prop:"user_name"},
        {label:"设备编号",prop:"device_number"},
        {label:"订单号",prop:"order_num"},
        {label:"申请日期",prop:"create_time"},
        {label:"审核日期",prop:"apply_time"},
        {label:"审核状态",prop:"state"},
        {label:"操作",prop:"caozuo"},
    ]
    const tongguo=async (item,isHuifu)=>{
        const {code ,msg}=await rcrTongguo(item.Id,isHuifu);
        if(code==200){
            item.state=1;
            ElMessage.success(msg)
        }else{
            ElMessage.error(msg)
        }
    }
    
    const {search,tableObjet}=useBase(getRCR,searchData,false,true)
    return {searchData,headers,search,tableObjet,tongguo}
}