<template>
    <XIASearchBar @search="search" :searchData="searchData"/>
    <div class="title">
        供应商列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">
    
        <template #state="scoped">
        
            <div>
                {{scoped.row.state==1?'待解冻':'已解冻'}}
            </div>
        </template>
        <template #type="scoped">
        
        <div>
            {{scoped.row.type==1?'默认':'次月提现'}}
        </div>
    </template>
    </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import {useSupplier} from "@/use/supplier"
const {search,searchData,tableObjet,headers}=useSupplier()
</script>
<style lang="scss" scoped>
</style>