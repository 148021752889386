<template>


    <el-button type="primary" style="margin: 20px;" @click="dialogFormVisible2 = true">新增</el-button>
    <div class="title">
        资产分类
    </div>

    <TableComponent :tableObjet="tableObjet" :headers="headers">


        <template #caozuo="s">

            <el-popconfirm title="确定删除该分类?" @confirm="removeType(s.row.id)">
                <template #reference>
                    <el-button  type="danger" plain round icon="Delete">删除该分类</el-button>
                </template>
            </el-popconfirm>
            
        </template>


    </TableComponent>

    <el-dialog v-model="dialogFormVisible2" title="新增资产分类">

        <div class="flex d">
            <div class="l">分类名称</div>
            <div class="r"><el-input type="text" name="" id="" v-model="a_name" placeholder="请输入分类名称"
                    style="width: 200px;" /></div>
        </div>




        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit2()">
                    新增
                </el-button>
            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useType } from "@/use/asset"
import { churuku, getProduct } from "@/api/product"
import { addAssetType,removeAssetType } from "@/api/asset"

import { ref } from "vue"
import { ElMessage } from 'element-plus';

const { search, searchData, tableObjet, headers } = useType()
const dialogFormVisible2 = ref()
const a_name = ref("")

const commit2 = () => {
    addAssetType({ name: a_name.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible2.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const removeType = (id) => {
    removeAssetType({id}).then(res=>{
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>

<style lang="scss" scoped></style>