<template>
    <div style="height: 100vh;display: flex;flex-direction: column;box-sizing: border-box;">
        <div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <RouterLink to="/" style="color: #5677fc; width: 20vw; text-align: center;">返回</RouterLink>
            <div style="flex:1;text-align: center;padding-right: 20vw;font-weight: bold;">
                库存信息
            </div>
        </div>

        <div class="flex" style="margin-top: 10px;">
            <el-input placeholder="输入名称搜索" style="margin:0 10px;" v-model="name"></el-input>
            <el-input placeholder="输入备注搜索" style="margin:0 10px;" v-model="remark"></el-input>
            <el-button @click="search">搜索</el-button>
        </div>
        <div style="flex:1;overflow-y: scroll;background-color: #eee;" v-if="data.length>0">
            <!-- <div class="flex h">
                <div style="width: 100px;margin: 10px;">
                    产品图片
                </div>
                <div>
                    产品名称
                </div>
                <div>
                    产品备注
                </div>
                <div>
                    数量
                </div>
                <div>
                    仓库
                </div>
                
                <div>
                    操作
                </div>

            </div> -->
            <div class="flex item" v-for="i in data" :key="i.id">
                <div class="left">
                    <img :src="'https://erp.jimoveebikes.com/storage/' + i.img" alt="">
                </div>
                <div class="right">
                    <div style="margin-bottom: 15px;">

                        <span style="font-size: 14px;">
                            {{ i.name }}
                        </span>
                    </div>
                    <div style="margin-bottom: 15px;">
                        <span style="margin-right: 15px;">
                            产品备注
                        </span>
                        <span>
                            {{ i.remark }}
                        </span>

                    </div>
                    <div style="margin-bottom: 15px;">
                        <span style="margin-right: 15px;">
                            库存数量
                        </span>
                        <span>
                            {{ i.total }}
                        </span>

                    </div>
                    <div style="margin-bottom: 15px;">
                        <span style="margin-right: 15px;">
                            所在仓库
                        </span>
                        <span>
                            {{ i.w_name }}
                        </span>

                    </div>
                    <div>
                        <RouterLink :to="'/moblie/cpxq?product_id=' + i.id">
                            <el-button style="width: 70%;">查看详情</el-button>
                        </RouterLink>
                    </div>
                </div>







            </div>
        </div>

        <div v-else  style="margin: 20px;text-align: center;">
                {{ msg }}
        </div>

        <div class="flex" style="padding: 20px;box-sizing: border-box;align-items: center;" v-if="data.length>0">
            <div>
                共{{ page }}页&nbsp;&nbsp;&nbsp;当前第{{ currPage }} 页
            </div>
            <el-button style="margin: 0 10px;" @click="currPage--" :disabled="currPage == 1">上一页</el-button>
            <el-button style="margin: 0 10px;" @click="currPage++" :disabled="currPage == page">下一页</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { getKucunList } from "@/api/mobile"
const name = ref("")
const page = ref(10)
const remark = ref("")
const currPage = ref(1)
const data = ref([])
const msg=ref("暂无库存")
const search = () => {
    getKucunList({ name: name.value, currPage: currPage.value, remark: remark.value }).then(res => {
        if (res.code === 200) {
            data.value = res.data.data
            page.value = res.data.page
            msg.value=res.msg
        }
    })
}
watch(currPage, val => {
    search()
})
search()
</script>
<style scoped lang="scss">
.h {
    height: 50px;
    align-items: center;

    div {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
    }
}

.item {
    background-color: #fff;
    margin: 5px 2vw;
    width: 96vw;
    box-sizing: border-box;
    padding: 20px;


    .left {
        width: 120px;
        height: 120px;

        img {
            width: 120px;
            height: 120px;
            object-fit: cover;
        }

    }

    .right {
        text-align: left;
        flex: 1;
        font-size: 12px;
    }



}
</style>