<template>
    <div style="height: 100vh;display: flex;flex-direction: column;box-sizing: border-box;">
        <div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <div style="color: #5677fc; width: 20vw; text-align: center;" @click="back">返回</div>
            <div style="flex:1;text-align: center;padding-right: 20vw;font-weight: bold;">
                组件列表
            </div>
        </div>

        <div class="flex" style="margin-top: 10px;">
            <el-input placeholder="输入名称搜索" style="margin:0 10px;" v-model="name"></el-input>
            <!-- <el-input placeholder="输入备注搜索" style="margin:0 10px;" v-model="remark"></el-input> -->
            <el-button @click="search" type="primary">搜索</el-button>
        </div>

        <div style="height: 80vh;overflow-y: scroll;">
            <RouterLink :to="'/moblie/zjcrk?id=' + i.id" class="flex item" v-for="i in data" :key="i.id">
                <img :src="'https://erp.jimoveebikes.com/storage/' + i.img" class="left"
                    style="object-fit: cover;border-radius: 5px;height: 80%;">
                <div class="right">
                    <div class="title2">
                        {{ i.name }}
                    </div>
                   
                    <div>
                       <span>库存数量:</span>{{ i.total }}
                    </div>
                    <div>
                        <span>共用车型:</span>
                    </div>
                    <div>
                        <el-tag v-for="k in i.gy" style="margin-right: 5px;">{{ k }}</el-tag>
                    </div>
                </div>


            </RouterLink>
        </div>


        <div class="flex" style="padding: 20px;box-sizing: border-box;align-items: center;">
            <div>
                共{{ page }}页&nbsp;&nbsp;&nbsp;当前第{{ currPage }} 页
            </div>
            <el-button style="margin: 0 10px;" @click="currPage--" :disabled="currPage == 1">上一页</el-button>
            <el-button style="margin: 0 10px;" @click="currPage++" :disabled="currPage == page">下一页</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { getZJList } from "@/api/mobile"
import { useRouter } from "vue-router"
const router = useRouter()
const back = () => {
    router.back()
}
const name = ref("")
const remark = ref("")
const page = ref(10)
const currPage = ref(1)
const data = ref([])
const search = () => {
    getZJList({ name: name.value, currPage: currPage.value, remark: remark.value }).then(res => {
        if (res.code === 200) {
            data.value = res.data.data
            page.value = res.data.page
        }
    })
}
watch(currPage, val => {
    search()
})
search()
</script>
<style scoped lang="scss">
.h {
    height: 50px;
    align-items: center;

    div {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
    }
}

.item {

    align-items: center;
    box-sizing: border-box;
    margin: 2vw;
    width: 96vw;
    
    padding: 2vw;
    border-bottom: 1px solid #b2c1fc;
    color: #000;

    img {
        height: 25vw;
        width: 25vw;
        object-fit: contain;
    }
    span{
        margin-right: 5px;
    }
    .right {
        margin-left: 5vw;
        flex: 1;
        text-align: left;

        .title2 {
            font-size: 15px;
            color: #000;
            font-weight: bold;
        }

        div {
            color: #666;
            font-size: 13px;
            margin-bottom: 2vw;
        }
    }


}
</style>