<template>
    <XIASearchBar @search="search" :searchData="searchData" />

    <el-button type="primary" style="margin: 20px;" @click="dialogFormVisible2 = true">新增</el-button>
    <div class="title">
        外协仓库存
    </div>

    <TableComponent :tableObjet="tableObjet" :headers="headers">


        <template #all_price="scoped">

            {{ scoped.row.price*scoped.row.total }}
        </template>
        <template #caozuo="scoped">

            <el-dropdown>
                <el-button type="primary">
                    操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <el-button @click="type = 2; product = scoped.row; ff()" >
                                入库
                            </el-button>
                        </el-dropdown-item>

                        <el-dropdown-item>
                            <el-button @click="type = 1; product = scoped.row; ff()" >
                                出库
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="dialogFormVisible4 = true; product = scoped.row;">
                                修改单价
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="dialogFormVisible3 = true; product = scoped.row;" >
                                修改名称
                            </el-button>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item>
                            <el-button @click="type = 1; product = scoped.row; ff()" type="warning">
                                删除
                            </el-button>
                        </el-dropdown-item> -->

                    </el-dropdown-menu>
                </template>
            </el-dropdown>

            <!-- <el-button @click="dialogFormVisible2 = true; product = scoped.row">修改备注</el-button> -->

        </template>



    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="出入库">


        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    {{ type == 1 ? "出库" : "入库" }}
                </el-button>
            </span>

        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible2" title="新增库存">

        <div class="flex d">
            <div class="l">产品名称</div>
            <div class="r"><el-input type="text" name="" id="" v-model="a_name" placeholder="请输入产品名称"
                    style="width: 200px;" /></div>
        </div>

        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="a_number" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>
        <div class="flex d">
            <div class="l">仓库</div>
            <div class="r">


                <el-select v-model="w_id" clearable placeholder="请选择仓库" filterable style="width: 200px;">
                    <el-option v-for="o in ws" :key="o.value" :label="o.label" :value="o.value" />
                </el-select>
            </div>
        </div>

        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit2()">
                    新增
                </el-button>
            </span>

        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible3" title="修改名称">

        <div class="flex d">
            <div class="l">产品名称</div>
            <div class="r"><el-input type="text" name="" id="" v-model="a_name" placeholder="请输入产品名称"
                    style="width: 200px;" /></div>
        </div>



        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit3()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible4" title="修改单价">

<div class="flex d">
    <div class="l">产品单价</div>
    <div class="r"><el-input type="number" name="" id="" v-model="a_price" placeholder="请输入产品单价"
            style="width: 200px;" /></div>
</div>



<template #footer>

    <span class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取消</el-button>
        <el-button type="primary" @click="commit4()">
            修改
        </el-button>
    </span>

</template>
</el-dialog>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useWXKucun } from "@/use/kucun"
import { churuku, getProduct } from "@/api/product"
import { churukuwxc, getWarehouseO2, addkc, xgmc } from "@/api/kucun"


import { ref } from "vue"
import { ElMessage } from 'element-plus';
import { xgRemark } from "@/api/mobile"
const currItem = ref()
const type = ref()
const number = ref(0)
const dialogFormVisible = ref(false)
const dialogFormVisible2 = ref(false)
const dialogFormVisible3 = ref(false)
const dialogFormVisible4 = ref(false)
const q_id = ref()
const qs = ref([])
const product = ref()
const kucun = ref([])

const ws = ref([])
const remark = ref()
const a_price = ref()
const a_name = ref()
const a_remark = ref()
const a_number = ref()
const w_id = ref()


const { search, searchData, tableObjet, headers } = useWXKucun()
const commit = () => {
    if (!number.value) {

        ElMessage.error("请输入数量")
        return
    }


    churukuwxc({ id: product.value.id, number: number.value, type: type.value, w_id: w_id.value, q_id: q_id.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false

        } else {
            ElMessage.error(res.msg)
        }
    })
}

const commit3 = () => {
    if (!a_name.value) {
        ElMessage.error("请输入产品名称")
        return
    }


    xgmc({ id: product.value.id, name: a_name.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible3.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}

const commit4 = () => {
    if (!a_price.value) {
        ElMessage.error("请输入产品单价")
        return
    }


    xgmc({ id: product.value.id, price: a_price.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible4.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit2 = () => {

    if (!a_name.value) {
        ElMessage.error("请输入产品名称")
        return
    }

    if (!a_number.value) {
        ElMessage.error("请输入数量")
        return
    }
    if (!w_id.value) {
        ElMessage.error("请选择存放仓库")
        return
    }

    addkc({ name: a_name.value, number: a_number.value, w_id: w_id.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible2.value = false
            search()
        } else {
            ElMessage.error(res.msg)
            dialogFormVisible2.value
        }

    })



}

const ff = () => {

    dialogFormVisible.value = true
}

getWarehouseO2().then(res => {
    ws.value = res.data
})
</script>

<style lang="scss" scoped></style>