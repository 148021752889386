<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search"/>
        <div class="add">

            <el-button class="btn" type="primary" @click="add">新增</el-button>
        </div>
        <TableComponent :tableObjet="tableObjet" :headers="headers">
            
           
        </TableComponent>
    </div>
</template>
    
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useRouter } from 'vue-router'
import {reactive,inject} from "vue"
import { useProductType } from "@/use/product"
import {updateProductType} from "@/api/product"
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")

const { headers,tableObjet,search,searchData } = useProductType();
const router = useRouter()

const add = () => {
    router.push({ path: "/product/addType" })
}

const handleEdit = (index, item) => {
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "所属门店",
            
            value: item.name,
            canEdit: false,
            type: "text"
        },
        rule_name: {
            label: "规则名称",
            
            value: item.rule_name,
            canEdit: false,
            type: "text"
        },

        
        price: {
            label: "30租金",
            
            value: item.price,
            canEdit: true,
            type: "text"
        },
        day_15_price: {
            label: "15天租金",
            
            value: item.day_15_price,
            canEdit: true,
            type: "text"
        },
        min_lease_count: {
            label: "最低租赁天数",
            
            value: item.min_lease_count,
            canEdit: true,
            type: "text"
        },
        remark: {
            label: "备注",
            value: item.remark,
            canEdit: true,
            type: "text"
        }
    })
    drawOptions.isEdit = true
    //drawOptions.getApi=getUserInfo
    drawOptions.postApi = updateBillingRule
    drawOptions.formData = formData
    drawOptions.title = "修改计费规则"
    isOpenDrawer.value = true
}

</script>
    
<style scoped lang="scss">
.add {
    margin-top: 20px;
    .btn{
        width: 100px;
        margin-right: 20px;
    }
}
</style>