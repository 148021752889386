<template>
    <XIASearchBar @search="search" :searchData="searchData" />

    
    <div class="title">
        客户码列表
    </div>

    <TableComponent :tableObjet="tableObjet" :headers="headers">

       
        <template #qrcode="scoped">

            <div @click="fangda(scoped.row)">
                <vue-qr :logoScale="0.2"  :margin="5"
                    :text="'https://erp.jimoveebikes.com/#/?id=' + scoped.row.id" :size="100"></vue-qr>
            </div>
        </template>
       

        <template #caozuo="s">

            <div>
                <div>
                    <el-popconfirm title="确定删除该客户码?" @confirm="removeAsset(s.row.id)">
                        <template #reference>
                            <el-button type="danger" plain round icon="Delete" small>删除</el-button>
                        </template>
                    </el-popconfirm>
                </div>
                <div style="margin-top: 10px;">
                    <el-button small round icon="Edit" @click="dialogFormVisible3 = true; item = s.row">编辑</el-button>
                </div>
            </div>

        </template>
    </TableComponent>
   
    <el-dialog v-model="dialogFormVisible3" title="修改客户码">

       
       
        <div class="flex d">
            <div class="l">车架码</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.cjm" placeholder="请输入车架码" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">电机码</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.djm" placeholder="请输入电机码" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">电池批次码</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.dcm" placeholder="请输入电池批次码" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">型号</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.model" placeholder="请输入型号" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">客户名称</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.user_name" placeholder="请输入客户名称" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">手机号</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.phone" placeholder="请输入手机号" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">收货地址</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.address" placeholder="请输入收货地址" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">价格</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.price" placeholder="请输入价格" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">业务员</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="item.ywy" placeholder="请输入业务员" style="width: 200px;" />
            </div>
        </div>
       

        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible3 = false">取消</el-button>
                <el-button type="primary" @click="commit3()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>

    <el-dialog v-model="dialogFormVisible" title="查看二维码">
        <div id="qrcode">
            <div >
                <div style="margin-bottom: 5px;text-align: center;">
                    <span style="margin: auto;">车架码：{{ leaseItem.name }}</span>
                </div>
                <div>
                    &nbsp;
                </div>
               


                <div  style="margin-top: 5px;text-align: center;">
                    <vue-qr :logoScale="0.2"  @click="fangda(item.row)" :margin="0"
                    :text="'https://erp.jimoveebikes.com/#/?aid=' + leaseItem.id" :size="180"></vue-qr>
                </div>
                <div>
                    &nbsp;
                </div>
                <div style="margin-top: 5px;text-align: center;">
                    电机码： {{ leaseItem.t_name }}
                </div>
            </div>

        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="dayin()">
                    打印
                </el-button>

                <el-button type="primary" @click="dialogFormVisible = false">
                    ok
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useIndex } from "@/use/khm"
import { churuku, getProduct } from "@/api/product"
import { removeKHMForId, xgKHM } from "@/api/khm"
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import print from "print-js"
import { ref } from "vue"
import { ElMessage } from 'element-plus';
const item=ref({})
const { search, searchData, tableObjet, headers, o } = useIndex()
const dialogFormVisible2 = ref()
const dialogFormVisible3 = ref()


const commit3 = () => {
    xgKHM({ ...item.value }).then(res => {
        if (res.code == 200) {
            dialogFormVisible3.value = false
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })

}
const removeAsset = (id) => {
    removeKHMForId({ id }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}


const fangda = (item) => {
    dialogFormVisible.value = true
    leaseItem.value = item
}
const dialogFormVisible = ref()
const currItem = ref()
const leaseItem = ref()

const dayin = () => {

// const LODOP = getLodop()

// LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('qrcode').innerHTML)
// LODOP.ADD_PRINT_HTM(0,0,'100%','100%',document.getElementById('qrcode').innerHTML)
// LODOP.SET_PRINT_PAGESIZE(1, 650, 710, "qrcode");
//LODOP.PREVIEW();
// LODOP.PRINT();
// LODOP.PRINT();
// return
print({
    printable: 'qrcode',
    type: "html",
    style: "",

})
return
// print the html string

// 获取要打印的节点
const node = document.getElementById('qrcode');

// 创建一个新的 jsPDF 实例
const doc = new jsPDF();

// 将节点的 HTML 内容添加到 PDF 中
doc.html(node, {
    callback: function () {
        // 调用浏览器的打印对话框
        window.print();
    }
});
//window.print()
// print the dom element
//const  content= document.getElementById('qrcode')
//callPrinter(`<img src="https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data=https://jimove.jimoveebikes.com/080265295"/>22`)
}
</script>

<style lang="scss" scoped></style>