import { reactive, ref, watch } from "vue";
import { addBillingRule, getBillingRules,getBillingRulesOptions } from "@/api/billingrule"
import { useGetStoreList } from "@/use/store"
import { useBase } from "@/use/base"
import { ElMessage } from 'element-plus'
export function useAddBillingRule() {
    
    const { stores_option } = useGetStoreList();
   
    const data = reactive({

        store_id: {
            type: "select",
            label: "所属门店",
            value: "",
            require: true,
            placeholder: "请选择门店",
            options: stores_option
        },
        rule_name: {
            type: "text",
            label: "方案名称",
            value: "",
            require: true,
            placeholder: "请输入方案名称",

        },
        day_15_price: {
            placeholder: "请输入物品的租金",
            type: "text",
            label: "15天租金",
            value: "299",
            require: true
        },
        price: {
            placeholder: "请输入物品的租金",
            type: "text",
            label: "30天租金",
            value: "500",
            require: true
        },
        
        day_60_price: {
            placeholder: "请输入物品的租金",
            type: "text",
            label: "60天租金",
            value: "1000",
            require: true
        },
        day_90_price: {
            placeholder: "请输入物品的租金",
            type: "text",
            label: "90天租金",
            value: "1500",
            require: true
        },
        min_lease_count: {
            placeholder: "请输入最低租赁天数",
            type: "text",
            label: "最低租赁天数",
            value: "150",
            require: true
        },
        xf_youhui: {
            placeholder: "请输入续费优惠",
            type: "text",
            label: "续费优惠",
            value: "100",
            require: true
        },
        fee: {
            placeholder: "请输入平台分成比例",
            type: "text",
            label: "分成比例",
            value: "0.6",
            require: true
        },
        gd_fee: {
            placeholder: "请输入门店固定分成",
            type: "text",
            label: "固定分成",
            value: "0",
            require: true
        },
        type: {
            type: "select",
            label: "计费类型",
            value: "",
            require: true,
            placeholder: "请选择计费类型",
            options: [
                {
                    label:"默认(租满多少次可以提现)",
                    value:1
                },
                {
                    label:"次月提现",
                    value:3
                }
            ]
        },
        remark: {
            placeholder: "请输入备注",
            type: "text",
            label: "备注",
            value: "",
            require: true
        },

        // big_img: {
        //     placeholder: "请选择物品的大图",
        //     type: "file",
        //     label: "物品大图",
        //     value: "",
        //     name: "big_img",
        //     require: true
        // },
        // min_img: {
        //     placeholder: "请选择物品的略缩图",
        //     type: "file",
        //     label: "物品略缩图",
        //     value: "",
        //     name: "min_img",
        //     require: true
        // }
    })
    const submit = (params) => {
        addBillingRule(params).then(res => {
            if (res.code == 200) {
                ElMessage.success({ type: "success", message: res.msg })
            } else {
                ElMessage.error(res.msg)
            }
        })
    }
    return { data, submit }

}
/**
 * 获取计费规则列表
 * @returns 
 */
export function useGetBillingRules() {
    const headers = [

        {
            prop: "id",
            label: "ID"
        },
        {
            prop: "pre_name",
            label: "门店前缀"
        },
        {
            prop: "name",
            label: "所属门店"
        },
        {
            prop: "rule_name",
            label: "规则名称"
        },
        {
            prop: "type",
            label: "提现规则"
        },
        {
            prop: "price",
            label: "30天价格"
        },
        {
            prop: "day_15_price",
            label: "15天价格"
        },
        {
            prop: "day_60_price",
            label: "60天价格"
        },
        {
            prop: "day_90_price",
            label: "90天价格"
        },
        {
            prop: "min_lease_count",
            label: "最低租赁天数"
        },
        
        {
            prop: "remark",
            label: "备注",

        },
        {
            prop: "caozuo",
            label: "操作"
        }
    ];
    const searchData = reactive({})
    const { tableObjet, search } = useBase(getBillingRules, searchData, false, true)
    return { headers, search, tableObjet, searchData }

}
/**
 * 根据门店id，获取这个门店的计费规则，放选项形式
 * @param {number} store_id 
 */
export function useBillingRulesOption(store_id){
    const billingRule_options=ref([])
    watch(store_id,i=>{
        getBillingRulesOptions(i).then(res=>{
            let data=res.data
            billingRule_options.value=[]
            data.forEach(item=>{
                billingRule_options.value.push({
                    label:item.rule_name,
                    value:item.id
                })
            })
        })
    })
    
    return {billingRule_options}

}