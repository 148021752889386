<template>
    <div style="height: 100vh;display: flex;flex-direction: column;box-sizing: border-box;">
        <div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <div style="color: #5677fc; width: 20vw; text-align: center;" @click="back">返回</div>
            <div style="flex:1;text-align: center;padding-right: 20vw;font-weight: bold;">
                BOM详情
            </div>
        </div>


        <div class="flex" style="margin-top: 10px;">
            <el-input placeholder="输入名称搜索" style="margin:0 10px;" v-model="name"></el-input>
            <el-input placeholder="输入备注搜索" style="margin:0 10px;" v-model="remark2"></el-input>
            <el-button @click="search" type="primary">搜索</el-button>
            <!-- <el-button  type="warning" @click="dialogFormVisible = true" style="margin-right: 5px;">添加产品</el-button> -->
        </div>
        


        <div style="height: 80vh;overflow-y: scroll;">
            <div v-for="i in data" :key="i.id" class="item">
                <RouterLink :to="'/moblie/cpxq?product_id=' + i.id" class="flex ">
                    <img :src="'https://erp.jimoveebikes.com/storage/' + i.img" class="left" style="object-fit: cover;height: 100%;border-radius: 5px;">
                    <div class="right">
                        <div >
                            <span>产品名称</span> <span class="title2">{{ i.name }}</span>
                        </div>
                        <div>
                            <span>产品备注</span>{{ i.remark }}



                        </div>
                        <div style="font-weight: bold;">
                            <span>所需数量</span><el-tag>{{ i.n_number }}</el-tag>
                        </div>
                        <div>
                           <span> 库存数量</span><el-tag :style="{ color: i.total < i.n_number ? '#f00' : '#409eff' }"
                               >{{ i.total }}</el-tag>
                        </div>
                        <div>
                            <span>产品编号</span>{{ i.number }}
                        </div>
                        <div>
                            <span>产品型号</span>{{ i.model }}
                        </div>
                        <div>
                            <span>产品参数</span>{{ i.parameter }}
                        </div>

                    </div>


                </RouterLink>
               
                <div style="display: flex;overflow-x: auto;border-top: 1px solid #eee;padding-top: 5px;padding-bottom: 5px;">
                    <el-tag v-for="k in i.gy" style="margin-right: 5px;font-size: 12px;">{{ k }}</el-tag>
                </div>
                <!-- <div style="text-align: center;">
                    <el-button @click="remove(i.id)">移除出BOM</el-button>
                    <el-button @click=" type = 2; product = i; ff()">入库</el-button>
                    <el-button @click="dialogFormVisible2 = true; product = i">修改备注</el-button>
                </div> -->
            </div>
        </div>


        <div class="flex" style="padding: 20px;box-sizing: border-box;align-items: center;">
            <div>
                共{{ page }}页&nbsp;&nbsp;&nbsp;当前第{{ currPage }} 页
            </div>
            <el-button style="margin: 0 10px;" @click="currPage--" :disabled="currPage == 1">上一页</el-button>
            <el-button style="margin: 0 10px;" @click="currPage++" :disabled="currPage == page">下一页</el-button>
        </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="添加产品到BOM" width="100%">


        <div class="flex d">
            <div class="l">产品</div>
            <div class="r">


                <el-select v-model="p_id" clearable placeholder="请选择产品" filterable style="width: 200px;">
                    <el-option v-for="o in ps" :key="o.id" :label="o.name + '-' + o.remark" :value="o.id"
                        style="font-size: 12px;" />
                </el-select>
            </div>


        </div>
        <div class="flex d">
            <div class="l">备注</div>
            <div class="r">{{ cp.remark }}</div>
        </div>
        <div class="flex d">
            <div class="l">编号</div>
            <div class="r">{{ cp.number }}</div>
        </div>
        <div class="flex d">
            <div class="l">型号</div>
            <div class="r">{{ cp.model }}</div>
        </div>
        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入所需数量"
                    style="width: 200px;" /></div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    添加
                </el-button>
            </span>

        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible2" title="修改备注" width="100%">


        <div class="flex d">
            <div class="l">备注</div>
            <div class="r"><el-input type="text" name="" id="" v-model="remark" placeholder="请输入备注" style="width: 200px;" />
            </div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit2()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible3" title="出入库" width="100%">


        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number2" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>
        <div class="flex d">
            <div class="l">仓库</div>
            <div class="r">


                <el-select v-model="w_id" clearable placeholder="请选择仓库" filterable style="width: 200px;">
                    <el-option v-for="o in ws" :key="o.id" :label="o.name" :value="o.id" />
                </el-select>
            </div>
        </div>
        <div class="flex d" v-if="type == 2 && w_id == 1">
            <div class="l">存放区域</div>
            <div class="r">


                <el-select v-model="q_id" clearable placeholder="请选择存放区域" filterable style="width: 200px;">
                    <el-option v-for="o in qs" :key="o.id" :label="o.name" :value="o.id" />
                </el-select>
            </div>
        </div>
        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible3 = false">取消</el-button>
                <el-button type="primary" @click="commit3()">
                    {{ type == 1 ? "出库" : "入库" }}
                </el-button>
            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import { xgRemark } from "@/api/mobile"
import { ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { getBomXQ, getPO, addToBom, removeToBom } from "@/api/mobile"
import { getProduct, churuku, updateProdcutImg } from '@/api/product'
import { ElMessage } from "element-plus";
const dialogFormVisible2 = ref(false)
const dialogFormVisible3 = ref(false)
const route = useRoute()
const cp = ref({})
const name = ref("")
const page = ref(10)
const currPage = ref(1)
const data = ref([])
const number = ref()
const remark = ref()
const remark2 = ref("")
const p_id = ref()
const ps = ref([])
const router = useRouter()
const product = ref()
const kucun = ref([])
const back = () => {
    router.back()
}
getPO().then(res => {
    ps.value = res.data
})
const ff = () => {
    getProduct(product.value.id).then(res => {
        product.value = res.data
        kucun.value = res.kucun
        ws.value = res.ws
        qs.value = res.qs
        dialogFormVisible3.value = true;
    })
}

const commit2 = () => {
    xgRemark({ remark: remark.value, id: product.value.id }).then(res => {
        dialogFormVisible2.value = false
        search()
    })
}

const dialogFormVisible = ref(false)
let id = route.query.id
const number2 = ref()
const type = ref()
const w_id = ref()
const q_id = ref()
const ws = ref([])
const qs = ref([])
const commit3 = () => {
    if (!number2.value) {

        ElMessage.error("请输入数量")
        return
    }
    if (!w_id.value) {
        ElMessage.error("请选择仓库")
        return
    }
    if (w_id.value == 1 && !q_id.value && type == 1) {
        ElMessage.error("原材料仓库请选择存放区域")
        return
    }

    churuku({ id: product.value.id, number: number2.value, type: type.value, w_id: w_id.value, q_id: q_id.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible3.value = false
            number2.value = 0
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit = () => {
    if (!p_id.value) {
        ElMessage.error("请选择产品")
        return

    }
    if (!number.value) {
        ElMessage.error("请输入数量")
        return
    }
    addToBom({ id: p_id.value, number: number.value, bom_id: id }).then(res => {
        if (res.code == 200) {
            search()
            ElMessage.success(res.msg)
            dialogFormVisible.value = false
        } else {
            ElMessage.error(res.msg)
        }
    })

}
const remove = (i) => {

    if (confirm("确认把此产品移除BOM吗?")) {
        removeToBom({ id: i, bom_id: id }).then(res => {
            if (res.code == 200) {
                search()
                ElMessage.success(res.msg)
                dialogFormVisible.value = false
            } else {
                ElMessage.error(res.msg)
            }
        })
    }

}
const search = () => {
    getBomXQ({ name: name.value, remark: remark2.value, currPage: currPage.value, id }).then(res => {
        if (res.code === 200) {
            data.value = res.data.data
            page.value = res.data.page
        }
    })
}
watch(currPage, val => {
    data.value = []
    search()



})
watch(() => route.query.id, val => {
    id = val
    data.value = []
    search()



})

watch(p_id, i => {

    ps.value.forEach(q => {
        if (q.id == i) {
            cp.value = q
        }
    })
})
search()
</script>
<style scoped lang="scss">
.h {
    height: 50px;
    align-items: center;

    div {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
    }
}

.item {

    align-items: center;
    box-sizing: border-box;
    margin: 2vw;
    width: 96vw;
    border-radius: 5px;
    padding: 2vw;
    box-shadow: #ccc 1px 1px 5px;
    color: #000;
    span{
        margin-right: 15px;
    }
    img {
        height: 100%;
        width: 25vw;
        object-fit: cover;
    }

    .right {
        margin-left: 5vw;
        flex: 1;
        text-align: left;

        .title2 {
            font-size: 15px;
            color: #000;
            font-weight: bold;
        }

        div {
            color: #666;
            font-size: 13px;
            margin-bottom: 2vw;
        }
    }



}

.d {
    .r {
        flex: 1;
        font-size: 12px;
    }
}
</style>