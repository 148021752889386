import { reactive, watch, ref, inject } from "vue"
import { useGetAdminListForOption } from "@/use/admin"
import { useGetStoreList } from '@/use/store'
import { useMainStore } from "/store/mainStore.js"

export function _post(api, tableObjet, searchData,isFile=false) {
    
    let params = {};
    for (let i in searchData) {
        
        let item = searchData[i].value;
        //params[i] = item 
        params[i] = item instanceof Date ? item.getTime() : item
        if (Array.isArray(item)) {
            params[i] = item.map(o => {
                return o instanceof Date ? o.getTime() : o
            })
        }

    }
    params.currPage = tableObjet.currPage
    api(params,isFile).then((res) => {
        if (!isFile&&res.code == 200) {
            
            tableObjet.data = res.data.data
            tableObjet.dataCount = res.data.dataCount
            tableObjet.otherData=res.data.otherData
        }
    })
}

export function useBase(api, searchData, needAdminOptios = false, needStoreOpitons = false) {

   
    const tableObjet = reactive({
        data: [],
        dataCount: 0,
        currPage: 1,
        otherData:{}
    })
    const { baseSearchData } = useBaseSearchData()
    const store=useMainStore()
    watch(()=>store.curr_select_store_id,(id)=>{
        if(id)
        {
            searchData.store_id.value=id
        }
    })
    if (needAdminOptios) {
        searchData.admin_id = baseSearchData.admin_id
    }
    if (needStoreOpitons) {
        searchData.store_id = baseSearchData.store_id

        if(store.curr_select_store_id){
            searchData.store_id.value=Number(store.curr_select_store_id)
           
        }
    }
    const search = (isFile) => {
        _post(api, tableObjet, searchData,isFile)
    }

    
    watch(() => tableObjet.currPage, () => {
        _post(api, tableObjet, searchData)
    })
    _post(api, tableObjet, searchData)
    return { search, tableObjet }
}
/**
 * 最基本的搜索数据，商户，门店
 */
export function useBaseSearchData() {
    
   
    const baseSearchData = reactive({
    })
   
    return { baseSearchData }

}
/**
 * 呼出修改侧边栏
 */
export function useHandleEdit() {
    const isOpenDrawer = inject("isOpenDrawer")
    const drawOptions = inject("drawOptions")
    const handleEtid= (formData, updateApi,title) => {
        
        drawOptions.isEdit = true
        drawOptions.postApi = updateApi
        drawOptions.formData = formData
        drawOptions.title = title
        isOpenDrawer.value = true
    }
    return {handleEtid}

}