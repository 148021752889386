<template>
    <div class="title">添加组件</div>

    <div style="margin: 20px;font-size: 20px;color: #5677fc;">
        选择零件
    </div>
    <div class="flex">
        <el-select v-model="currSelectId" clearable placeholder="请选择要添加的零件" filterable>


            <el-option v-for="o in pList" :key="o.value" :label="o.label" :value="o.value" />

        </el-select>
        <el-input placeholder="请输入需要的数量" type="number" v-model="number" style="width: 200px;margin-left: 20px;" />
        <div style="margin-left: 20px;">
            <el-button @click="addToZj">添加</el-button>
        </div>
    </div>

    <div style="margin: 20px;font-size: 20px;color: #5677fc;">
        已添加列表
    </div>
    <el-table :data="hasList" class="table" :border="true" sum-text="合计" :height="600">
        <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers2" :key="key">
            <template #default="scope">
                <div v-if="i.prop == 'caozuo'">
                    <el-button @click="removeFromZj(scope.row)">移除</el-button>
                </div>
                <div v-if="i.prop == 'img'">
                    <img :src="'https://erp.jimoveebikes.com/storage/' + scope.row.img" style="width: 100px;height: 100px;">
                </div>
                <div v-else>
                    {{ scope.row[i.prop] }}
                </div>
            </template>
        </el-table-column>

    </el-table>

    <div class="flex" style="margin-top: 20px;margin-left: 20px;">
        
        <input type="file" @change="changefile" :data-name="img" hidden class="zjimg">
          
          <img :src="src" alt="" width="400" height="200" @click="checkImage('img')">
    </div>
    <div class="flex" style="margin-top: 20px;">
        
        <el-input placeholder="请输入组件的名称" type="text" v-model="name" style="width: 200px;margin-left: 20px;" />
    </div>
    <div class="flex" style="margin-top: 20px;margin-left: 20px;">
        
        <el-button @click="createZj">创建组件</el-button>
    </div>
    
</template>
  
<script  setup>
import { ref } from 'vue'
import { getProductListO, getProduct,addZujian } from "@/api/product"
import { ElMessage } from 'element-plus';
import { list } from '@/api/banner';
const hasList = ref([])
const pList = ref([])
const currSelectId = ref()
const number = ref()
const name=ref()
const src=ref()
let file=null
getProductListO().then(res => {
    pList.value = res.data
})
const headers2 = [
    {
        label: "id",
        prop: "id",
    },

    {
        label: "产品名称",
        prop: "name",
    },
    {
        label: "编号",
        prop: "number",
    },
    {
        label: "型号",
        prop: "model",
    },
    {
        label: "参数",
        prop: "parameter",
    },
    {
        label: "说明",
        prop: "illustrate",
    },
    {
        label: "照片",
        prop: "img",
    },


    {
        label: "所属供应商",
        prop: "s_name",
    },
    {
        label: "价格",
        prop: "price",
    },
    {
        label: "是否含税",
        prop: "include_tax",
    },

    {
        label: "所需数量",
        prop: "n_number",
    },
    {
        label: "操作",
        prop: "caozuo"
    }
];
const addToZj = () => {
    if (!currSelectId.value) {
        ElMessage.error("请选择零件")
        return
    }
    if (!number.value) {
        ElMessage.error("请输入数量")
        return
    }
    let isHas = false
    hasList.value.forEach(i => {
        if (i.id == currSelectId.value) {
            isHas = true
        }
    })

    if (isHas) {
        ElMessage.error("该零件已添加到组件")
        return
    }

    getProduct(currSelectId.value).then(res => {
        res.data.n_number = number.value

        hasList.value.push(res.data)
        number.value = ""
        currSelectId.value = ""

    })
}
const removeFromZj = (item) => {
    hasList.value = hasList.value.filter(i => i.id != item.id)
}
const createZj=()=>{
    if(!file){
        ElMessage.error("请上传组件图片")
        return
    }
    if(!name.value){
        ElMessage.error("请输入组件名称")
        return
    }
    addZujian({img:file,name:name.value,list:JSON.stringify(hasList.value)}).then(res=>{
        ElMessage.info(res.msg)
    })
}

const changefile = async (e) => {
  if (e.target.files.length > 0) {
    src.value = URL.createObjectURL(e.target.files[0]);
    file = e.target.files[0];
 
  }

}

const checkImage = (className) => {
    src.value =""
    file =null

  document.querySelector(".zjimg" ).click();
}

</script>
  