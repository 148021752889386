<template>
  <div>

    <FormComponent canEdit="true" :data="data" title="添加门店" @submit="onSubmit"></FormComponent>
    <div style="margin-left: 20px;color: #666;">选择门店位置</div>
    <div id="allmap">

    </div>
  </div>
</template>
<script>

</script>
<script  setup>
import { addStore,getPMList,getJMSList } from "@/api/store.js"
import { reactive, onMounted, watchEffect } from 'vue'
import { ElMessage } from "element-plus"
import { ref, watch } from 'vue'
import FormComponent from "@/components/FormComponent.vue";
import { geocoding } from "@/api/map";
const point = ref(0);
let map = null;

getPMList().then(res=>{
  data.pm_id.options=res.data
})
getJMSList().then(res=>{
  data.jms_id.options=res.data
})

const data = reactive({
  store_type: {
    type: "select",
    require: true,
    placeholder: "",
    label: "收费模式",
    value: 1,
    options:[
      {
        label:"默认(租五次可以提现)",
        value:1
      },
      {
        label:"模式A(前N次租金归平台)",
        value:2
      },
      {
        label:"模式B(次月提现)",
        value:3
      }
    ]

  },
  name: {
    type: "text",
    require: true,
    placeholder: "请输入门店(格式：区号或者县+门店名)",
    label: "门店名",
    value: ""

  },
  pm_id:{
    label:"业务经理",
    type:"select",
    options:[],
    placeholder:"选择业务经理，可以为空"
  },
  jms_id:{
    label:"所属加盟商",
    type:"select",
    options:[],
    placeholder:"选择所属加盟商,可以为空"
  },
  address: {
    type: "text",
    require: true,
    placeholder: "请输入门店地址",
    label: "门店地址",
    value: ""

  },
  radius: {
    type: "text",
    require: true,
    placeholder: "请输入运营半径",
    label: "运营半径",
    value: 500

  },
  phone: {
    type: "text",
    require: true,
    placeholder: "请输入联系手机号",
    label: "手机号",
    value: ""

  },
  user_name: {
    type: "text",
    require: true,
    placeholder: "请输入老板姓名",
    label: "老板姓名",
    value: ""

  },
  pre_name: {
    type: "text",
    require: true,
    placeholder: "请输入门店前缀（城市名称+老板姓名）",
    label: "门店前缀",
    value: ""

  },
  state: {
    type: "switch",
    placeholder: "",
    label: "运营开关",
    value: true,
  },
  image: {
    type: "image",
    require: true,
    label: "门店图片",
    value: ""

  },
})

watch(() => data.address.value, (address) => {
  geocoding(address).then(res => {
    let data = res.data
    point.value.lng = data.result.location.lng
    point.value.lat = data.result.location.lat
    map.setCenter(point.value); // 设置地图中心点
    map.clearOverlays();
    createCarMarker();
    createCircl();
  })
})


const onSubmit = async (formData) => {
  formData.append("lng", point.value.lng);
  formData.append("lat", point.value.lat);
  const { msg, code } = await addStore(formData);
  if (code == 200) {
    ElMessage({ type: 'success', message: msg });
  } else {
    ElMessage.error(msg);
  }
}

const createMap = () => {
  point.value = new BMapGL.Point(113.17336647603713, 23.02111443101253);
  // 百度地图API功能
  map = new BMapGL.Map("allmap");    // 创建Map实例
  map.centerAndZoom(point.value, 17);
  map.enableScrollWheelZoom(true);
}
const addLocationControl = () => {
  // 创建定位控件
  var locationControl = new BMapGL.LocationControl({
    // 控件的停靠位置（可选，默认左上角）
    anchor: BMAP_ANCHOR_TOP_RIGHT,
    // 控件基于停靠位置的偏移量（可选）
    offset: new BMapGL.Size(20, 20)
  });
  // 将控件添加到地图上
  map.addControl(locationControl);

  // 添加定位事件
  locationControl.addEventListener("locationSuccess", function (e) {
    var address = '';
    address += e.addressComponent.province;
    address += e.addressComponent.city;
    address += e.addressComponent.district;
    address += e.addressComponent.street;
    address += e.addressComponent.streetNumber;
    alert("当前定位地址为：" + address);
  });

}
const addOverlay = (o) => {
  map.addOverlay(o);
}
const createCarMarker = () => {
  // 创建小车图标
  var myIcon = new BMapGL.Icon("/vue/machine.png", new BMapGL.Size(50, 50));
  // 创建Marker标注，使用小车图标
  var pt = point.value;
  var marker = new BMapGL.Marker(pt, {
    icon: myIcon
  });


  // 创建信息窗口
  var opts = {
    width: 200,
    height: 100,
    title: '物品编号080195036'
  };
  var infoWindow = new BMapGL.InfoWindow('地址描述: 广东省佛山市禅城区张槎街道朗宝西二路润园', opts);
  // 点标记添加点击事件
  // marker.addEventListener('click', function () {
  //   map.openInfoWindow(infoWindow, point.value); // 开启信息窗口
  // });
  // 将标注添加到地图
  addOverlay(marker);
}
const createCircl = () => {
  // 绘制圆
  var circle = new BMapGL.Circle(point.value, data.radius.value, {
    strokeColor: 'blue',
    strokeWeight: 2,
    strokeOpacity: 0.5
  });
  addOverlay(circle);
}

const cityControll = () => {
  // 创建城市选择控件
  var cityControl = new BMapGL.CityListControl({
    // 控件的停靠位置（可选，默认左上角）
    anchor: BMAP_ANCHOR_TOP_LEFT,
    // 控件基于停靠位置的偏移量（可选）
    offset: new BMapGL.Size(10, 5)
  });
  // 将控件添加到地图上
  map.addControl(cityControl);
}
/**
 * 点击选择位置，并设置为地图中心
 */
const checkPoint = () => {

  setTimeout(() => {
    map.addEventListener('click', (e) => {
      // alert(e)

      point.value.lng = e.latlng.lng;
      point.value.lat = e.latlng.lat;
      map.setCenter(point.value); // 设置地图中心点
      map.clearOverlays();
      createCarMarker();
      createCircl();
    });
  }, 2000);
}
const mapInit = () => {
  createMap();
  createCarMarker();
  createCircl();
  cityControll();
  checkPoint();
  addLocationControl();

  watchEffect(() => {

    map.clearOverlays();
    createCarMarker();
    createCircl();
  })
}
onMounted(() => {

  mapInit();
})

</script>

<style scoped lang="scss">
.el-form {
  width: 700px;
}

.xia-error {

  color: #f00;

  position: absolute;
  right: 2%;
  text-align: right;
  font-size: 14px;

}

#allmap {
  margin-left: 2%;
  margin-top: 20px;
  width: 50vw;
  height: 50vh;
  box-shadow: 0px 0px 0px #333;
}
</style>
