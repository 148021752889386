<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        库存查看
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #model="scoped">
            <div style="max-height: 100px;overflow-y: auto;">
                {{ scoped.row.model }}
            </div>

        </template>

        <template #caozuo="scoped">

            <el-dropdown>
                <el-button type="primary">
                    操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item><el-button @click="type = 2; product = scoped.row; ff()" type="primary">
                                入库
                            </el-button></el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="type = 1; product = scoped.row; ff()" type="warning">
                                出库
                            </el-button>
                        </el-dropdown-item>

                    </el-dropdown-menu>
                </template>
            </el-dropdown>

            <!-- <el-button @click="dialogFormVisible2 = true; product = scoped.row">修改备注</el-button> -->

        </template>

        <template #include_tax="scoped">

            <el-tag>
                {{ scoped.row.include_tax ? "含税" : "不含税" }}
            </el-tag>
        </template>

        <template #gy="scoped">

            <div style="display: flex;flex-direction: column;max-height: 100px;overflow-y: auto;">
                <el-tag v-for="i in scoped.row.gy" :key="i.id" style="margin-bottom: 5px;padding: 5px;">
                    <RouterLink :to="'/bom/xq/?id=' + i.id" style="color: #79BBFF;">{{ i.name }}</RouterLink>
                </el-tag>
            </div>
        </template>
    </TableComponent>

    <el-dialog v-model="dialogFormVisible" title="出入库" width="100%">


        <div class="flex d">
            <div class="l">数量</div>
            <div class="r"><el-input type="number" name="" id="" v-model="number" placeholder="请输入要操作的数量"
                    style="width: 200px;" /></div>
        </div>
        <div class="flex d">
            <div class="l">仓库</div>
            <div class="r">


                <el-select v-model="w_id" clearable placeholder="请选择仓库" filterable style="width: 200px;">
                    <el-option v-for="o in ws" :key="o.id" :label="o.name" :value="o.id" />
                </el-select>
            </div>
        </div>
        <div class="flex d" v-if="type == 2 && w_id == 1">
            <div class="l">存放区域</div>
            <div class="r">


                <el-select v-model="q_id" clearable placeholder="请选择存放区域" filterable style="width: 200px;">
                    <el-option v-for="o in qs" :key="o.id" :label="o.name" :value="o.id" />
                </el-select>
            </div>
        </div>
        <div class="flex d" v-if="type == 25">
            <div class="l">
                生成批次码
            </div>
            <div class="r">
                <input type="checkbox" name="" id="" v-model="createPCM"/>
            </div>
        </div>
        <div class="flex d" v-if="createPCM">
            <div class="l">
                批次码
            </div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="pcm" />
            </div>
        </div>

        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="commit()">
                    {{ type == 1 ? "出库" : "入库" }}
                </el-button>
            </span>

        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible2" title="修改备注" width="100%">


        <div class="flex d">
            <div class="l">备注</div>
            <div class="r"><el-input type="text" name="" id="" v-model="remark" placeholder="请输入备注"
                    style="width: 200px;" />
            </div>
        </div>


        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit2()">
                    修改
                </el-button>
            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useKucun } from "@/use/kucun"
import { churuku, getProduct } from "@/api/product"
import { ref } from "vue"
import { ElMessage } from 'element-plus';
import { xgRemark } from "@/api/mobile"
const currItem = ref()
const type = ref()
const number = ref(0)
const dialogFormVisible = ref(false)
const dialogFormVisible2 = ref()
const q_id = ref()
const createPCM=ref(false)
const pcm=ref("")
const qs = ref([])
const product = ref()
const kucun = ref([])
const w_id = ref()
const ws = ref([])
const remark = ref()
const { search, searchData, tableObjet, headers } = useKucun()
const commit = () => {
    if (!number.value) {

        ElMessage.error("请输入数量")
        return
    }
    if (!w_id.value) {
        ElMessage.error("请选择仓库")
        return
    }
    if (w_id.value == 1 && !q_id.value && type == 1) {
        ElMessage.error("原材料仓库请选择存放区域")
        return
    }
    if(createPCM.value){
        if(pcm.value==""){
            ElMessage.error("请输入批次码")
            return
        }
    }

    churuku({ id: product.value.id, number: number.value, type: type.value, w_id: w_id.value, q_id: q_id.value,createPCM:createPCM.value,pcm:pcm.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible.value = false

        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit2 = () => {
    if (!remark.value) {

        ElMessage.error("请输入备注")
        return
    }

    xgRemark({ remark: remark.value, id: product.value.id }).then(res => {
        dialogFormVisible2.value = false
        search()
    })
}
const ff = () => {

    getProduct(product.value.id).then(res => {

        kucun.value = res.kucun
        ws.value = res.ws
        qs.value = res.qs
        dialogFormVisible.value = true
        search()
    })
}
</script>

<style lang="scss" scoped></style>