<template>
<div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <RouterLink to="/" style="color: #5677fc; width: 20vw; text-align: center;">返回首页</RouterLink>
            <div style="flex:1;text-align: center;padding-right: 20vw;font-weight: bold;">
                仓库示意图
            </div>
        </div>
    <div>
        <svg width="500" height="800" xmlns="http://www.w3.org/2000/svg">
 <g>
  <title>Layer 1</title>
  <rect fill="#ffff56" stroke="#000" x="321.72931" y="285.69492" height="1" id="svg_10" width="0"/>
  <path fill="#ffd4aa" stroke="#000" opacity="undefined" d="m400.00001,281.69492l100,0l0,100l-100,0l0,-100z" id="svg_25"/>
  <rect fill="#ffd4aa" x="400" y="173.36158" width="100" height="100" id="svg_26" stroke="#000"/>
  <rect transform="rotate(90 377.729 399.362)" fill="#b2b2b2" stroke="#000" y="384.36159" width="800" height="30" id="svg_27" x="-22.27069"/>
  <path transform="rotate(90 152.359 151.048)" fill="#aaffd4" opacity="undefined" d="m2,-50.30509l486.54238,2.54238l-0.54238,98.98179l329.29263,0.67659l3.38984,300.50609l-818.68247,-1.69492l0,-401.01193z" id="svg_28" stroke="#000"/>
  <rect transform="rotate(90 40.6954 79.0283)" fill="#ffd4aa" x="-37.27069" y="39.02824" width="155.93209" height="80" id="svg_30" stroke="#000"/>
  <rect transform="rotate(90 225.625 320.362)" fill="#ffff00" x="175.62473" y="195.36159" width="100" height="250" id="svg_31" stroke="#000"/>
  <rect transform="rotate(90 134.831 464.747)" fill="#7fff00" x="99.83102" y="424.74707" width="70" height="80" id="svg_32" stroke="#000"/>
  <rect transform="rotate(90 38.2265 669.362)" fill="#ffaaff" x="-121.94292" y="629.36159" width="320.33889" height="80" id="svg_33" stroke="#000"/>
  <rect transform="rotate(90 136.396 669.747)" fill="#aad4ff" stroke="#000" x="-23.60401" y="629.74706" width="320" height="80" id="svg_35"/>
  <text transform="rotate(90 379.425 264.164)" fill="#000000" stroke="#000" x="355.42488" y="272.1638" id="svg_36" stroke-width="0" font-size="24" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">公路</text>
  <text transform="rotate(90 458.905 231.912)" fill="#ffd4aa" stroke="#000" x="440.40547" y="236.41243" id="svg_37" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve" font-weight="bold">电池仓</text>
  <text transform="rotate(90 457.729 329.246)" fill="#007f3f" stroke="#000" x="439.72932" y="333.74577" id="svg_38" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">成品仓</text>
  <path transform="rotate(90 227.396 129.028)" fill="#aaff56" stroke="#000" opacity="undefined" d="m102.39598,4.02826l250,0l0,250l-250,0l0,-250z" id="svg_39"/>
  <text transform="rotate(90 240.729 109.585)" fill="#ffd4aa" stroke="#000" x="228.22931" y="114.0847" id="svg_40" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">车架</text>
  <text transform="rotate(90 38.0287 72.2458)" fill="#ffd4aa" stroke="#000" x="17.02868" y="76.74576" id="svg_41" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">电池仓2</text>
  <text fill="#ffff00" stroke="#000" x="180.95807" y="340.0847" id="svg_42" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">仪表控制器</text>
  <text transform="rotate(90 139.38 459.298)" fill="#ffff00" stroke="#000" x="126.88045" y="463.79793" id="svg_44" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">电机</text>
  <text transform="rotate(90 -380.761 601.142) matrix(1.68911 0 0 1 -797.815 128)" stroke="#000" fill="#aad4ff" x="234.42515" y="477.46462" id="svg_45" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">配件</text>
  <text transform="rotate(90 29.396 686.746)" fill="#aad4ff" stroke="#000" x="17.39597" y="690.74576" id="svg_46" font-size="12" font-family="'Gemunu Libre'" text-anchor="start" xml:space="preserve">纸箱</text>
  <path fill="#ffd4aa" stroke="#000" d="m-119.84588,-109.33869l0.745,-0.54203l0.745,0.54203l-0.28456,0.87702l-0.92087,0l-0.28456,-0.87702l-0.00001,0z" id="svg_47"/>
  <text transform="rotate(90 139 690)" xml:space="preserve" text-anchor="start" font-family="'Gemunu Libre'" font-size="12" id="svg_1" y="693.9999" x="126.6667" stroke="#000" fill="#aad4ff">配件</text>
 </g>

</svg>
    </div>
</template>