import {$post} from "./base"
export async function addBom(params){
   
   return $post("/bom/add",params)
}
export async function list(params){
   
    return $post("/bom/list",params)
 }

 export async function list2(params){
   
   return $post("/bom/list2",params)
}
 
 export async function listO(){
   
    return $post("/bom/listO")
 }
 export async function xq(id,pName,pNumber){
   
    return $post("/bom/xq",{id,pName,pNumber})
 }
 export async function removeBomFormId(id){
   
    return $post("/bom/removeBomFormId",{id})
 }

 export async function addBToBomFromId(params){
   
    return $post("/bom/addBToBomFromId",params)
 }
 export async function removeForId(id){
   
    return $post("/bom/removeForId",{id})
 }
 export async function huanyuan(id){
   
   return $post("/bom/huanyuan",{id})
}

 
 export async function upForId(id,name){
   
    return $post("/bom/upForId",{id,name})
 }
 export async function upBPForId(params){
   
    return $post("/bom/upBPForId",params)
 }
 export async function fenxi(id,number,daochu=false){
   
   return $post("/bom/fenxi",{id,number,daochu},daochu)
}
export async function fenxi2(params,daochu=false){
   
   return $post("/bom/fenxi2",params,daochu)
}


export async function copyForId(id,name){
   
   return $post("/bom/copyForId",{id,name})
}
export async function daochuForId(id){
   
   return $post("/bom/daochuForId",{id},true)
}
export async function fenxi3(params,daochu=false){
   
   return $post("/bom/fenxi3",params,daochu)
}
export async function importBom(params){
   
   return $post("/bom/importBom",params)
}


 