<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">还车审核</div>
        <TableComponent :headers="headers" :tableObjet="tableObjet">
            <template #state="item">
                {{ item.row.state == 0 ? "待审核" : "已通过" }}
            </template>
            <template #caozuo="item">

                <el-button v-if="item.row.state == 0" type="info">
                    <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon-color="#626AEF" title="确定通过还车审核?"
                        @confirm="tongguo(item.row)">
                        <template #reference>
                            通过
                        </template>
                    </el-popconfirm>
                </el-button>

                <el-button v-if="item.row.state == 1" type="info">
                    <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon-color="#626AEF" title="确定恢复订单?"
                        @confirm="tongguo(item.row,true)">
                        <template #reference>
                            恢复订单
                        </template>
                    </el-popconfirm>
                </el-button>

            </template>
        </TableComponent>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useRCR } from "@/use/returnCarReview"
const { search, searchData, headers, tableObjet, tongguo } = useRCR();
</script>

<style lang="scss" scoped></style>