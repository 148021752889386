<template>
    <div style="height: 100vh;display: flex;flex-direction: column;box-sizing: border-box;">
        <div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <RouterLink to="/" style="color: #5677fc; width: 20vw; text-align: center;">返回</RouterLink>
            <div style="flex:1;text-align: center;padding-right: 20vw;font-weight: bold;">
                调拨记录
            </div>
        </div>

        <div class="flex" style="margin-top: 10px;">
            <el-input placeholder="输入名称搜索" style="margin:0 10px;" v-model="name"></el-input>
            <el-input placeholder="输入备注搜索" style="margin:0 10px;" v-model="remark"></el-input>
            <el-button @click="search" type="primary" style="margin-right: 5px;">搜索</el-button>
        </div>
        <div style="flex:1">
            <div class="flex h" >

                <div style="width: 50vw;">
                    产品名称
                </div>
                <div>
                    类型
                </div>
                <div>
                    数量
                </div>


                <div>
                    操作
                </div>

            </div>
            <div class="flex item" v-for="i in data" :key="i.id">

                <div style="font-size: 12px;width: 50vw;">
                    {{ i.name }}
                </div>
                <div style="font-size: 12px;">
                    <el-tag>{{ i.type == 1 ? "出库" : "入库" }}</el-tag>
                </div>

                <div>
                    {{ i.number }}
                </div>


                <div>

                    <el-button @click="currItem = i; dialogFormVisible = true" type="info" size="small" > 详情</el-button>

                </div>





            </div>
        </div>

        <div class="flex" style="padding: 20px;box-sizing: border-box;align-items: center;">
            <div>
                共{{ page }}页&nbsp;&nbsp;&nbsp;当前第{{ currPage }} 页
            </div>
            <el-button style="margin: 0 10px;" @click="currPage--" :disabled="currPage == 1">上一页</el-button>
            <el-button style="margin: 0 10px;" @click="currPage++" :disabled="currPage == page">下一页</el-button>
        </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="详情" width="100%">


        <div class="flex d">
            <div class="l">产品名称</div>
            <div class="r">{{ currItem.name }}</div>
        </div>
        <div class="flex d">
            <div class="l">仓库</div>
            <div class="r">{{ currItem.w_name }}</div>
        </div>
        <div class="flex d">
            <div class="l">类型</div>
            <div class="r"><el-tag>{{ currItem.type == 1 ? "出库" : "入库" }}</el-tag></div>
        </div>
        <div class="flex d">
            <div class="l">操作前数量</div>
            <div class="r">{{ currItem.pre_number }}</div>
        </div>
        <div class="flex d">
            <div class="l">数量</div>
            <div class="r">{{ currItem.number }}</div>
        </div>
        <div class="flex d">
            <div class="l">操作后数量</div>
            <div class="r">{{ currItem.post_number }}</div>
        </div>
        <div class="flex d">
            <div class="l">操作人</div>
            <div class="r">{{ currItem.user_name }}</div>
        </div>
        <div class="flex d">
            <div class="l">操作日期</div>
            <div class="r">{{ currItem.create_time }}</div>
        </div>
        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">关闭</el-button>

            </span>

        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue"
import { getDBJL } from "@/api/mobile"
const dialogFormVisible = ref(false)
const name = ref("")
const page = ref(10)
const currPage = ref(1)
const data = ref([])
const currItem = ref()
const search = () => {
    getDBJL({ name: name.value, currPage: currPage.value }).then(res => {
        if (res.code === 200) {
            data.value = res.data.data
            page.value = res.data.page
        }
    })
}
watch(currPage, val => {
    search()
})
search()
</script>
<style scoped lang="scss">
.h {
    margin-top: 20px;
    height: 30px;
    align-items: center;
    background-color: #eee;
    div {
        width: 15vw;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
       
    }
}

.item {
    height: 110px;
    align-items: center;

    div {
       width: 15vw;
        text-align: center;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
    }

}

.l {
    width: 100px;
}

.r {
    flex: 1;
}
</style>