<template>
    <div style="height: 100vh;display: flex;flex-direction: column;box-sizing: border-box;">
        <div class="flex" style="height: 50px; align-items: center;border-bottom: 1px solid #eee;">
            <div style="color: #5677fc; width: 20vw; text-align: center;" @click="back">返回</div>
            <div style="flex:1;text-align: center;padding-right: 20vw;font-weight: bold;">
                BOM列表
            </div>
        </div>

        <div class="flex" style="margin-top: 10px;">
            <el-input placeholder="输入名称搜索" style="margin:0 10px;" v-model="name"></el-input>

            <el-button @click="search" type="primary">搜索</el-button>
        </div>
        <div style="flex:1;overflow-y: scroll;background-color: #eee;">
            <!-- <div class="flex h">

                <div>
                    BOM名称
                </div>


                <div>
                    操作
                </div>

            </div> -->
            <RouterLink class="flex item" v-for="i in data" :key="i.id" :to="'/moblie/bomxq?id=' + i.id">

                <div class="left">
                    <img :src="'https://erp.jimoveebikes.com/storage/' + i.img" class="left"
                        style="object-fit: cover;border-radius: 5px;height: 80%;">
                </div>
                <div class="right">

                    <div class="flex">
                        <span style="margin-right: 5px;color: #999;width: 70px;text-align: left;">
                            bom名称
                        </span>
                        <span>
                            {{ i.name }}
                        </span>
                    </div>
                    <div class="flex">
                        <span style="margin-right: 5px;color: #999;width: 70px;text-align: left;">
                            成品名称
                        </span>
                        <span>
                            {{ i.pName }}
                        </span>
                    </div>
                    <div class="flex">
                        <span style="margin-right: 5px;color: #999;width: 70px;text-align: left; ">
                            零件种类
                        </span>
                        <span>
                            {{ i.num }}
                        </span>
                    </div>
                    <div class="flex">
                        <span style="margin-right: 5px;color: #999;width: 70px;text-align: left; ">
                            可以组装
                        </span>
                        <span>
                            {{ i.pNumber }}
                        </span>
                    </div>
                    <div class="flex">
                        <span style="margin-right: 5px;color: #999;width: 70px;text-align: left; ">
                            最后修改
                        </span>
                        <span>
                            {{ i.last_time }}
                        </span>
                    </div>
                    <div>



                    </div>

                </div>







            </RouterLink>
        </div>

        <div class="flex" style="padding: 20px;box-sizing: border-box;align-items: center;">
            <div>
                共{{ page }}页&nbsp;&nbsp;&nbsp;当前第{{ currPage }} 页
            </div>
            <el-button style="margin: 0 10px;" @click="currPage--" :disabled="currPage == 1">上一页</el-button>
            <el-button style="margin: 0 10px;" @click="currPage++" :disabled="currPage == page">下一页</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { getBOMlist } from "@/api/mobile"
import { useRouter } from "vue-router"
const router = useRouter()
const back = () => {
    router.back()
}
const name = ref("")
const page = ref(10)
const currPage = ref(1)
const data = ref([])
const search = () => {
    getBOMlist({ name: name.value, currPage: currPage.value }).then(res => {
        if (res.code === 200) {
            data.value = res.data.data
            page.value = res.data.page
        }
    })
}

watch(currPage, val => {
    search()
})
search()
</script>
<style scoped lang="scss">
.h {
    height: 50px;
    align-items: center;

    div {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
    }
}

.item {

    box-sizing: border-box;
    margin-left: 2vw;
    width: 96vw;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    padding-top: 20px;

    .left {
        width: 100px;
        height: 150px;

        img {
            height: 150px;
        }
    }

    .right {
        font-size: 14px;
        flex: 1;
        color: #000;
        .flex{
            margin-bottom: 10px;
        }
    }


}
</style>