import {$post} from "./base"

export async function add(params) {
    return $post("/khm/add",params);
  }
  export async function addForFile(params) {
    return $post("/khm/addForFile",params);
  }
  export async function index(params) {
    return $post("/khm/index",params);
  }

  export async function removeKHMForId(params) {
    return $post("/khm/removeKHMForId",params);
  }
  export async function xgKHM(params) {
    return $post("/khm/xgKHM",params);
  }
  export async function getpcmList(params) {
    return $post("/khm/getpcmList",params);
  }
  export async function createPCM(params) {
    return $post("/khm/createPCM",params);
  }
  export async function getpcmO(params) {
    return $post("/khm/getpcmO",params);
  }
  export async function removePCMFORID(params) {
    return $post("/khm/removePCMFORID",params);
  }
  
  
