<template>
  <div class="common-layout" v-if="token">
    <el-container v-if="!showMobile">
      <el-aside width="200px">
        <drawoLayout />
      </el-aside>
      <el-main id="right">
        <HeaderComponent />

        <div class="flex1">
          <el-scrollbar>
            <router-view v-slot="{ Component }">
              <keep-alive>
                <component :is=Component />
              </keep-alive>
            </router-view>
            <!-- <router-view >
                
              </router-view> -->
          </el-scrollbar>
        </div>
      </el-main>
    </el-container>

    <template v-else>

      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is=Component />
        </keep-alive>
      </router-view>
      <!-- <router-view >
                
              </router-view> -->
    </template>
    <draw />
  </div>

  <Loginpage v-else />
</template>

<script setup>
import draw from "@/components/draw.vue"
import drawoLayout from "@/components/drawlayoutComponent.vue"
import HeaderComponent from "./components/headerComponent.vue"

import { useMainStore } from "../store/mainStore.js"
import { storeToRefs } from "pinia"
import Loginpage from "@/pages/login/loginPage.vue"
import { provide, reactive, ref, onMounted } from "vue"


//是否打开侧边栏
const isOpenDrawer = ref(false)
provide("isOpenDrawer", isOpenDrawer)
//打开侧边栏的参数
const drawOptions = reactive({})
provide("drawOptions", drawOptions)
const store = useMainStore()
const { token } = storeToRefs(store)
let showMobile = ref(true)
let width = window.document.body.clientWidth
if (width <= 800) {
  showMobile.value = true
} else {
  showMobile.value = false
}
window.addEventListener('resize', () => {

  width = window.document.body.clientWidth
  if (width <= 800) {
    showMobile.value = true
  } else {
    showMobile.value = false
  }
})

// onMounted(()=>{
//   console.log(document.body.clientWidth);




// //callPrinter(content)
// })

console.log();
//window.location.href=/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)?"http://localhost:8888/mobile_web":"http://localhost:8888/PC";
</script>
<style lang="scss">
body {
  padding: 0 !important;
  margin: 0 !important;
  --base-color: #F0F2F5;
  --card-color: #ffffff;
}


.el-main {
  padding: 0 !important;
  margin: 0 !important;
}

* {
  padding: 0;
}

#right {
  margin: 0;
  height: 100vh;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.border-box-content {

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flex1 {
  flex: 1;
  overflow: hidden;
  padding: 10px;
  background-color: var(--base-color);
}

.title {
  height: 40px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 20px;
  color: #fff;


  background-color: var(--el-color-primary);
  font-size: 18px;
}

a {
  text-decoration: none;
}

.add {
  position: relative;
  width: 100%;
  text-align: right;
  margin-top: -40px;
  margin-bottom: 10px;

  .btn {
    width: 120px;

  }


}

.el-sub-menu {
  background-color: var(--base-color) !important;
  ;
  color: #000 !important;
  ;

}

.el-sub-menu .el-menu {
  background-color: var(--base-color) !important;
  ;
  color: #000 !important;
  ;

}

.el-menu-item:hover {

  background-color: #ECF5FF !important;

}

.flex {
  display: flex;
}

.flex_1 {
  flex: 1;
}
</style>
