<template>
    <div>

        <el-table :data="tableObjet ? tableObjet.data : data" class="table" :stripe="true" :table-layout="tableLayout"
            :border="true" sum-text="合计" :show-summary="showSummary" :max-height="height ? height : 800">
            <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers" :key="key">
                <template #default="scope">


                    <slot :name="i.prop" :row="scope.row" :$index="scope.$index">
                        {{ scope.row[i.prop] }}
                    </slot>

                </template>
            </el-table-column>
        </el-table>

        <div class="flex">
            <span style="color: #666;margin:0 20px;">共{{ dataCount ? dataCount : tableObjet.dataCount }}条</span>
            <el-pagination background layout="prev, pager, next" :total="tableObjet.dataCount"
                v-model:current-page="tableObjet.currPage" :page-size="psize ? psize : 10" v-if="tableObjet" />
            <el-pagination background layout="prev, pager, next" :total="dataCount" v-bind:current-page="currPage"
                :page-size="psize ? psize : 10" v-else />

        </div>
    </div>
</template>
<script setup>
import { ref } from "vue"
const tableLayout = ref('auto')
defineProps(['tableObjet', 'data', 'headers', 'showSummary', 'dataCount', 'currPage', "psize", 'height'])
</script>
<style scoped lang="scss">
.flex {
    align-items: center;
}

.table {
    width: 100vw;

    margin-bottom: 10px;

    background-color: var(--card-color);

    ::v-deep th .cell {
        text-align: center !important;
        color: #666 !important;


    }

    ::v-deep td .cell {
        text-align: center !important;
        color: #777;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}</style>