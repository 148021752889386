<template>
    <div class="title">组件详情</div>

    <div>
        <div style="margin: 20px;font-size: 20px;color: #5677fc;">
            选择零件
        </div>
        <div class="flex">
            <el-select v-model="currSelectId" clearable placeholder="请选择要添加的零件" filterable>


                <el-option v-for="o in pList" :key="o.value" :label="o.label" :value="o.value" />

            </el-select>
            <el-input placeholder="请输入需要的数量" type="number" v-model="number" style="width: 200px;margin-left: 20px;" />
            <div style="margin-left: 20px;">
                <el-button @click="addToZj">添加</el-button>
            </div>
        </div>
    </div>

    <div style="margin: 20px;font-size: 20px;color: #5677fc;">
        已添加列表
    </div>
    <el-table :data="hasList" class="table" :border="true" sum-text="合计" :height="500">
        <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers2" :key="key">
            <template #default="scope">
                <div v-if="i.prop == 'caozuo'" style="text-align: center;">
                    <el-button @click="removeFromZj(scope.row)">移除</el-button>
                </div>
                <div v-else-if="i.prop == 'img'" style="text-align: center;">
                    <img :src="'https://erp.jimoveebikes.com/storage/' + scope.row.img" style="width: 100px;height: 100px;object-fit:cover;border-radius: 5px;"/>
                </div>
                <div v-else-if="i.prop == 'include_tax'">
                   {{ scope.row.include_tax==1?"含税":"不含税"}}
                </div>
                <div v-else style="text-align: center;">
                    {{ scope.row[i.prop] }}
                </div>
            </template>
        </el-table-column>

    </el-table>
   


    <div class="flex" style="margin-top: 20px;margin-left: 20px;">

        <el-button @click="saveZJ">保存</el-button>
        <el-button @click="deleteZj">删除</el-button>
    </div>
</template>
  
<script  setup>
import { ref, watch } from 'vue'
import { useRoute,useRouter } from "vue-router"
import { getProductListO, getProduct, removeZujianFromId,editZujian ,getZujian} from "@/api/product"
import { ElMessage } from 'element-plus';

const hasList = ref([])
const pList = ref([])
const currSelectId = ref()
const number = ref()


const route = useRoute()
const router=useRouter()
const zid = ref()

if (route.query.id) {
    zid.value = route.query.id
}

getZujian(zid.value).then(res=>{
    hasList.value=res.data
})
watch(() => route.query.id, i => {
    zid.value = i
    getZujian(zid.value).then(res=>{
    hasList.value=res.data
})
})



getProductListO().then(res => {
    pList.value = res.data
})
const headers2 = [
    {
        label: "id",
        prop: "id",
    },

    {
        label: "产品名称",
        prop: "name",
    },
    {
        label: "编号",
        prop: "number",
    },
    {
        label: "型号",
        prop: "model",
    },
    {
        label: "参数",
        prop: "parameter",
    },
    {
        label: "说明",
        prop: "illustrate",
    },
    {
        label: "照片",
        prop: "img",
    },


    {
        label: "所属供应商",
        prop: "s_name",
    },
    {
        label: "价格",
        prop: "price",
    },
    {
        label: "是否含税",
        prop: "include_tax",
    },

    {
        label: "所需数量",
        prop: "n_number",
    },
    {
        label: "操作",
        prop: "caozuo"
    }
];
const addToZj = () => {
    if (!currSelectId.value) {
        ElMessage.error("请选择零件")
        return
    }
    if (!number.value) {
        ElMessage.error("请输入数量")
        return
    }
    let isHas = false
    hasList.value.forEach(i => {
        if (i.id == currSelectId.value) {
            isHas = true
        }
    })

    if (isHas) {
        ElMessage.error("该零件已添加到组件")
        return
    }

    getProduct(currSelectId.value).then(res => {
        res.data.n_number = number.value

        hasList.value.push(res.data)
        number.value = ""
        currSelectId.value = ""

    })
}
const removeFromZj = (item) => {
    hasList.value = hasList.value.filter(i => i.id != item.id)
}
const saveZJ = () => {
    
    editZujian({id:zid.value, list: JSON.stringify(hasList.value) }).then(res => {
        if(res.code==200){
            ElMessage.success(res.msg)
         
        }else{
            ElMessage.error(res.msg)
        }
    })
}
const deleteZj=()=>{
    removeZujianFromId(zid.value).then(res=>{
        if(res.code==200){
            ElMessage.success(res.msg)
            router.back()
        }else{
            ElMessage.error(res.msg)
        }
    })
}



</script>
  