<template>
    <div>
        <XIASearchBar :searchData="searchData" @search="search" />
        <div class="title">租赁记录</div>
        <TableComponent :tableObjet="tableObjet" :headers="headers" :height="750">
            <template #state="item">

                <el-tag :class="'state' + item.row.state">{{ state[item.row.state] }}</el-tag>

            </template>
            <template #price="item">

                <el-tag :class="'state' + item.row.state">{{ item.row.price }}</el-tag>

            </template>
            <template #caozuo="item">

                <el-dropdown>
                    <el-button type="primary">
                        操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu >
                            <el-dropdown-item>
                                <div @click="goDetail(item.row.device_number)" type="info" size="small" round>设备详情</div>

                            </el-dropdown-item>
                            <el-dropdown-item v-if="item.row.state == 2 ">
                                <div @click="dialogFormVisible=true;deviceInfo=item.row;type=2" type="info" size="small" round>更换设备</div>

                            </el-dropdown-item>
                            <el-dropdown-item v-if="item.row.state == 2 || item.row.state == 3">
                                <div @click="dialogFormVisible=true;deviceInfo=item.row;type=1" type="info" size="small" round>修改结束时间</div>

                            </el-dropdown-item>
                            <el-dropdown-item >
                                <template v-if="item.row.state == 2 || item.row.state == 3">
                                    <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" 
                                        icon-color="#626AEF" title="是否确定结束订单?" @confirm="endOrder(item.row)"
                                        >
                                        <template #reference>
                                            结束订单
                                        </template>
                                    </el-popconfirm>
                                </template>

                            </el-dropdown-item>
                        </el-dropdown-menu>
                        
                    </template>
                </el-dropdown>




            </template>
        </TableComponent>
       
        <el-dialog v-model="dialogFormVisible" title="更改租赁信息">
      
    <el-input v-model="device_number" placeholder="输入新设备编号" v-if="type==2"></el-input>

    
    <el-date-picker v-if="type==1"
        v-model="time"
        type="datetime"
        placeholder="Select date and time"
      />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogFormVisible=fasle;switchDevice(device_number,deviceInfo,time)">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
    </div>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from "@/components/XIA-SearchBar.vue";
import { useLeaseList, useEndOrder } from "@/use/user"
import { useRouter } from "vue-router"
import {ref} from "vue"
const router = useRouter()
const type=ref(1)
const { endOrder } = useEndOrder()
const state = ["", "未支付", "租赁中", "已超期", "已完成", "已取消"]
const { searchData, headers, tableObjet, search,switchDevice } = useLeaseList();
const dialogFormVisible=ref(false)
const device_number=ref()
const deviceInfo=ref()
const time=ref()
const goDetail = (device_number) => {
    router.push({
        path: "/leaseitem/detail",
        query: {
            device_number
        }
    })
}
</script>

<style lang="scss" scoped>
.state3 {
    color: #f00;
}

.state4 {
    color: #00f800;
}

.state2 {
    color: #00f;
}

.state1 {
    color: #333;
}

.state5 {
    color: #aaa;
}
</style>