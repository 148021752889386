<template>
    <XIASearchBar @search="search" :searchData="searchData" />
    <div class="title">
        产品列表
    </div>
    <TableComponent :tableObjet="tableObjet" :headers="headers" :psize="5">

        <template #img="scoped">

            <div>
                <img :src="'https://erp.jimoveebikes.com/storage/' + scoped.row.img" style="width: 100px;height: 100px;"
                    @click="checkImage(scoped.row)">
            </div>
        </template>
        <template #qrcode="scoped">

            <div @click="fangda(scoped.row)">
                <vue-qr :logoScale="0.2" logoSrc="favicon.ico" :margin="5"
                    :text="'https://erp.jimoveebikes.com/#/?id=' + scoped.row.id" :size="100"></vue-qr>
            </div>
        </template>
        <template #include_tax="scoped">

            <el-tag>
                {{ scoped.row.include_tax == 1 ? '含税' : '不含税' }}
            </el-tag>
        </template>

        
        <template #caozuo="scoped">


            <div  >
                <div style="margin-bottom: 5px;">

                    <el-button type="primary" icon="Edit" circle   @click="handleEdit(scoped.row)"/>
                   
                </div>
                <div>
                    <el-button type="danger" icon="Delete" circle />
                </div>
                <div style="margin-top: 5px;">

                    <el-popconfirm title="加到所有BOM?" @confirm="addToAllBom(scoped.row)">
                    <template #reference>
                        <el-button  icon="Plus" circle  type="primary"></el-button>
                    </template>
                </el-popconfirm>
                    
                </div>
            </div>

        </template>
        <template #gy="scoped">

            <div style="display: flex;flex-wrap: wrap; max-height: 120px;overflow-y: auto; ">
                <el-tag v-for="i in scoped.row.gy" style="margin-bottom: 15px;" >{{ i }}</el-tag>
            </div>

        </template>

        <template #model="scoped">

            <div style="max-height: 100px;overflow-y:auto;">
                {{ scoped.row.model }}
            </div>

        </template>
    </TableComponent>
    <el-dialog v-model="dialogFormVisible" title="查看二维码">
        <div id="qrcode">
            <div style="text-align: center; color: #000;font-weight: bold;">
                <div style="margin-bottom: 5px;text-align: center;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;零件名称：{{ leaseItem.name }}
                </div>
                <div style="margin-bottom: 5px;text-align: center;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>


                <vue-qr :logoScale="0.2" logoSrc="favicon.ico" @click="fangda(item.row)" :margin="5"
                    :text="'https://erp.jimoveebikes.com/#/?id=' + leaseItem.id" :size="180"></vue-qr>

                <div style="margin-top: 5px;text-align: center;">
                    &nbsp;&nbsp;&nbsp;&nbsp;供应商： {{ leaseItem.s_name }}
                </div>
            </div>

        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="dayin()">
                    打印
                </el-button>

                <el-button type="primary" @click="dialogFormVisible = false">
                    ok
                </el-button>
            </span>
        </template>
    </el-dialog>
    <input type="file" @change="changefile" :data-name="img" hidden class="zjimg">
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { useProduct } from "@/use/product"
import { updateProdcutImg, updateProdcut,addToAllBomForId } from "@/api/product"
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { ref, inject, reactive } from "vue"
import { ElMessage } from 'element-plus';
const src = ref()
let file = null
const dialogFormVisible = ref()
const currItem = ref()
const leaseItem = ref()
const addToAllBom=(item)=>{
    addToAllBomForId(item.id).then(res=>{
        ElMessage.info(res.msg)
    })
}
const isOpenDrawer = inject("isOpenDrawer")
const drawOptions = inject("drawOptions")
const fangda = (item) => {
    dialogFormVisible.value = true
    leaseItem.value = item
}
const { search, searchData, tableObjet, headers } = useProduct()

const changefile = async (e) => {

    if (e.target.files.length > 0) {
        src.value = URL.createObjectURL(e.target.files[0]);

        file = e.target.files[0];
        updateProdcutImg({ id: currItem.value.id, img: file }).then(res => {
            if (res.code == 200) {
                search()
                e.target.files = []
            }
        })


    }

}
const checkImage = (i) => {
    console.log(0);
    currItem.value = i
    src.value = ""
    file = null

    document.querySelector(".zjimg").click();
}
const dayin = () => {

    // const LODOP = getLodop()

    // LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('qrcode').innerHTML)
    // LODOP.ADD_PRINT_HTM(0,0,'100%','100%',document.getElementById('qrcode').innerHTML)
    // LODOP.SET_PRINT_PAGESIZE(1, 650, 710, "qrcode");
    //LODOP.PREVIEW();
    // LODOP.PRINT();
    // LODOP.PRINT();
    // return
    print({
        printable: 'qrcode',
        type: "html",
        style: "text-align: center; color: #f00;font-weight: bold;border: 1px solid #e00;",

    })
    return
    // print the html string

    // 获取要打印的节点
    const node = document.getElementById('qrcode');

    // 创建一个新的 jsPDF 实例
    const doc = new jsPDF();

    // 将节点的 HTML 内容添加到 PDF 中
    doc.html(node, {
        callback: function () {
            // 调用浏览器的打印对话框
            window.print();
        }
    });
    //window.print()
    // print the dom element
    //const  content= document.getElementById('qrcode')
    //callPrinter(`<img src="https://api.qrserver.com/v1/create-qr-code/?size=150%C3%97150&data=https://jimove.jimoveebikes.com/080265295"/>22`)
}

const handleEdit = (item) => {
    // if (item.state != 0) {
    //     ElMessage.error("只有闲置中的物品才能修改!")
    //     return
    // }
    const formData = reactive({
        id: {
            label: "ID",
            placeholder: "",
            value: item.id,
            canEdit: false,
            type: "text"
        },
        name: {
            label: "名称",

            value: item.name,
            canEdit: true,
            type: "text"
        },
        number: {
            label: "编号",

            value: item.number,
            canEdit: true,
            type: "text"
        },


        model: {
            label: "型号",

            value: item.model,
            canEdit: true,
            type: "text"
        },
        parameter: {
            label: "参数",

            value: item.parameter,
            canEdit: true,
            type: "text"
        },
        illustrate: {
            label: "说明",

            value: item.illustrate,
            canEdit: true,
            type: "text"
        },
        price: {
            label: "价格",

            value: item.price,
            canEdit: true,
            type: "text"
        },
        include_tax: {
            label: "是否含税",

            value: item.include_tax == 1 ? true : false,
            canEdit: true,
            type: "switch"
        }

    })
    drawOptions.isEdit = true
    //drawOptions.getApi=updateProdcut
    drawOptions.postApi = updateProdcut
    drawOptions.formData = formData
    drawOptions.title = "修改产品信息"
    isOpenDrawer.value = true
}
</script>
<style lang="scss" scoped></style>