
import {
 
    list,
    list2
  } from "@/api/bom";
  
 
  import { reactive, ref, watch } from "vue";

  import { useBase, useHandleEdit } from "@/use/base";
export function useBom() {
    const searchData = reactive({
      
      name:{
        label:"名称",
        type:"text",
        placeholder: "请输入Bom名称",
        value: "",
      },
    });
    const headers = [
      {
        label: "id",
        prop: "id",
      },
  
      {
        label: "名称",
        prop: "name",
      },

      // {
      //   label: "关联的成品",
      //   prop: "pName",
      // },
      {
        label: "零件种类",
        prop: "base_number",
      },
      // {
      //   label: "组件种类",
      //   prop: "zuhe_number",
      // },
      {
        label: "可以组装",
        prop: "c_number",
      },
      {
        label: "含税造价",
        prop: "price",
      },
      {
        label: "不含税造价",
        prop: "bhs_price",
      },
      {
        label:"操作",
        prop:"caozuo"
      }
    ];
    const { search, tableObjet } = useBase(
        list,
      searchData,
      null,
      null
    );
  
    return { headers, searchData, search, tableObjet };
  }
  export function useBom2() {
    const searchData = reactive({
      
      name:{
        label:"名称",
        type:"text",
        placeholder: "请输入Bom名称",
        value: "",
      },
    });
    const headers = [
      {
        label: "id",
        prop: "id",
      },
  
      {
        label: "名称",
        prop: "name",
      },

      // {
      //   label: "关联的成品",
      //   prop: "pName",
      // },
      {
        label: "零件种类",
        prop: "base_number",
      },
      // {
      //   label: "组件种类",
      //   prop: "zuhe_number",
      // },
      {
        label: "可以组装",
        prop: "c_number",
      },
      {
        label: "含税造价",
        prop: "price",
      },
      {
        label: "不含税造价",
        prop: "bhs_price",
      },
      {
        label:"操作",
        prop:"caozuo"
      }
    ];
    const { search, tableObjet } = useBase(
        list2,
      searchData,
      null,
      null
    );
  
    return { headers, searchData, search, tableObjet };
  }