<template>


    <div class="title">
        批次码列表
    </div>

    <el-button type="primary" style="margin: 20px;" @click="dialogFormVisible2 = true">新增</el-button>
    <XIASearchBar @search="search" :searchData="searchData" />

    <TableComponent :tableObjet="tableObjet" :headers="headers">

        <template #caozuo="s">

            <div>
                <div>
                    <el-popconfirm title="确定删除该批次码?" @confirm="removePCM(s.row.id)">
                        <template #reference>
                            <el-button type="danger" plain round icon="Delete" small>删除</el-button>
                        </template>
                    </el-popconfirm>
                </div>
               
            </div>

        </template>
    </TableComponent>

    <el-dialog v-model="dialogFormVisible2" title="新增批次码">

        <div class="flex d">
            <div class="l">产品</div>
            <div class="r">
                <el-select v-model="product_id" filterable>
                    <el-option v-for="i in ps" :value="i.value" :key="i.value" :label="i.label">{{ i.label
                        }}</el-option>
                </el-select>
            </div>
        </div>
        <div class="flex d">
            <div class="l">批次码</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="pcm" placeholder="请输入批次码" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">数量</div>
            <div class="r">
                <el-input type="number" name="" id="" v-model="number" placeholder="请输入这一批回货数量" style="width: 200px;" />
            </div>
        </div>
        <div class="flex d">
            <div class="l">备注</div>
            <div class="r">
                <el-input type="text" name="" id="" v-model="remark" placeholder="请输入备注,可以为空" style="width: 200px;" />
            </div>
        </div>
        <template #footer>

            <span class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取消</el-button>
                <el-button type="primary" @click="commit2()">
                    新增
                </el-button>
            </span>

        </template>
    </el-dialog>


</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue';
import XIASearchBar from '@/components/XIA-SearchBar.vue';
import { usePCM } from "@/use/khm"

import { createPCM,removePCMFORID } from "@/api/khm"
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import print from "print-js"
import { ref } from "vue"
import { ElMessage } from 'element-plus';
const item = ref({})
const pcm = ref("")
const remark = ref("")
const number = ref()
const product_id = ref("")
const { search, searchData, tableObjet, headers, ps } = usePCM()
const dialogFormVisible2 = ref()

const removePCM=(id)=>{
    removePCMFORID({ id }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
const commit2 = () => {
    if (!product_id.value) {
        ElMessage.error("请选择产品")
        return
    }
    if (pcm.value == "") {
        ElMessage.error("请输入批次码")
        return
    }
    if (number.value == "") {
        ElMessage.error("请输入数量")
        return
    }
    createPCM({ product_id: product_id.value, title: pcm.value, number: number.value, remark: remark.value }).then(res => {
        if (res.code == 200) {
            ElMessage.success(res.msg)
            dialogFormVisible2.value = false
            search()
        } else {
            ElMessage.error(res.msg)
        }
    })
}
</script>

<style lang="scss" scoped></style>