<template>
    <div>

        <div class="flex" style="margin: 20px;">
            <el-select v-model="currSelectId" clearable placeholder="请选择需要分析的BOM">


                <el-option v-for="o in pList" :key="o.value" :label="o.label" :value="o.value" />

            </el-select>
            <el-input placeholder="请输入需要的数量" type="number" v-model="number" style="width: 200px;margin-left: 20px;" />
            <div style="margin-left: 20px;">
                <el-button @click="add">添加</el-button>
            </div>
            <div>
                <el-button class="fenxi" @click="fenxi">分析</el-button>
            </div>
            <div>
                <el-button class="fenxi" @click="daochu">导出</el-button>
            </div>



        </div>
        <el-table :data="list" class="table" :border="true" sum-text="合计" :max-height="600">
            <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers3" :key="key">
                <template #default="scope">
                    <div v-if="i.prop == 'caozuo'">
                        <el-button @click="removeBom(scope.row)">移除</el-button>
                    </div>

                    <div v-else>
                        {{ scope.row[i.prop] }}
                    </div>
                </template>
            </el-table-column>

        </el-table>

    </div>

    <div style="margin-top:20px">
        <el-button @click="t = 1; setOption()" :class="t == 1 ? 'select' : ''">
            全部
        </el-button>

        <el-button @click="t = 2; setOption()" :class="t == 2 ? 'select' : ''">
            足够
        </el-button>
        <el-button @click="t = 3; setOption()" :class="t == 3 ? 'select' : ''">
            需要补充
        </el-button>
    </div>

    <div class="flex">

        <div id="chart"></div>
        <div class="right2">
            <el-table :data="list3" class="table" :border="true" sum-text="合计" :max-height="600">
                <el-table-column :prop="i.prop" :label="i.label" v-for="(i, key) in headers4" :key="key">
                    <template #default="scope">
                        <div v-if="i.prop == 'caozuo'">
                            <el-button @click="removeBom(scope.row)">移除</el-button>
                        </div>

                        <div v-else>
                            {{ scope.row[i.prop] }}
                        </div>
                    </template>
                </el-table-column>

            </el-table>
        </div>
    </div>
    <div class="flex">

        <div id="chart2"></div>



    </div>
</template>
  
<script  setup>
import * as echarts from 'echarts';
import { ref, onMounted } from 'vue'
import { listO, fenxi3 } from "@/api/bom"
import { ElMessage } from 'element-plus';
const active = ref(0)
const pList = ref([])
const currSelectId = ref()
const number = ref()
const t = ref(1)
const headers3 = ref([
    {
        label: "BOM名称",
        prop: 'name'
    }, {
        label: "生产数量",
        prop: 'number'
    },
    {
        label: "操作",
        prop: 'caozuo'
    },
])
const headers4 = ref([
    {
        label: "原材料名称",
        prop: 'name'
    }, {
        label: "需要数量",
        prop: 'n_number'
    },
    {
        label: "库存数量",
        prop: 'total'
    },
])
const myChart = ref({})
const myChart2 = ref({})
listO().then(res => {
    pList.value = res.data
})
const removeBom = (i) => {
    list.value = list.value.filter(j => {
        return j.value != i.value
    })

}
const list = ref([])
const list2 = ref([])
const list3 = ref([])
const add = () => {

    if (!number.value) {
        ElMessage.error("请输入数量")
        return
    }
    if (!currSelectId.value) {
        ElMessage.error("请选择BOM表")
        return
    }
    let has = false
    list.value.forEach(i => {
        if (i.value == currSelectId.value) {
            has = true
        }
    })
    if (!has) {

        let name = ""
        pList.value.forEach(i => {
            if (i.value == currSelectId.value) {
                name = i.label
            }
        })

        list.value.push({
            value: currSelectId.value,
            number: number.value,
            name
        })
    } else {
        ElMessage.error("已经添加")
    }
}
const next = () => {
    if (active.value++ > 2) active.value = 0
}
const fenxi = () => {

    if (list.value.length == 0) {
        ElMessage.error("请先添加BOM")
        return
    }
    let boms = list.value.map(i => { return { id: i.value, number: i.number } })

    fenxi3({ boms: JSON.stringify(boms) }).then(res => {
        list2.value = res.data
        setOption()
        setOption2()
    })

}
const daochu = () => {

}
/**
 * 销毁 Echart实例
 */
const dispose = () => {
    echarts.init(document.getElementById("chart")).dispose();
    echarts.init(document.getElementById("chart2")).dispose();

}
/**
 * 初始化 Echart实例
 */
const init = () => {
    myChart.value = echarts.init(document.getElementById("chart"))
    myChart2.value = echarts.init(document.getElementById("chart2"))

}
onMounted(() => {
    dispose();
    init();




})
const setOption2 = () => {
    // prettier-ignore
    let dataAxis = list2.value.map(i => i.name)
    // prettier-ignore
    let data = list2.value.map(i => i.n_number)
    let yMax = 500;
    let dataShadow = [];
    for (let i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
    }
    let option = {
        title: {
            text: '原材料成本',

        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            data: dataAxis,
            axisLabel: {
                inside: true,
                color: '#fff'
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            z: 10
        },
        yAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color: '#999'
            }
        },
        dataZoom: [
            {
                type: 'inside'
            }
        ],
        series: [
            {
                type: 'bar',
                showBackground: true,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#83bff6' },
                        { offset: 0.5, color: '#188df0' },
                        { offset: 1, color: '#188df0' }
                    ])
                },
                emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#2378f7' },
                            { offset: 0.7, color: '#2378f7' },
                            { offset: 1, color: '#83bff6' }
                        ])
                    }
                },
                data: data
            }
        ]
    };
    // Enable data zoom when user click bar.
    const zoomSize = 6;

    myChart2.value.setOption(option)
}
const setOption = () => {





    let d1 = list2.value.map(i => i.n_number)
    let d2 = list2.value.map(i => i.b)
    let names = list2.value.map(i => i.name)
    list3.value=list2.value
    if (t.value == 2) {
        let dd = list2.value.filter(i => i.c > 0)

        d1 = dd.map(i => i.n_number)
        names = dd.map(i => i.name)
        d2 = []
        list3.value=dd
    } else if (t.value == 3) {
        let dd = list2.value.filter(i => i.b > 0)
        list3.value=dd
        d1 = dd.map(i => i.b)
        names = dd.map(i => i.name)
        d2 = []
    }
    let series = [

    ];
    series = [
        {
            data: d1,
            type: 'bar',
            stack: 'a',
            name: '需要数量'
        },
        {
            data: d2,
            type: 'bar',
            stack: 'a',
            name: '需要补充'
        }
    ]


    const stackInfo = {};
    for (let i = 0; i < series[0].data.length; ++i) {
        for (let j = 0; j < series.length; ++j) {
            const stackName = series[j].stack;
            if (!stackName) {
                continue;
            }
            if (!stackInfo[stackName]) {
                stackInfo[stackName] = {
                    stackStart: [],
                    stackEnd: []
                };
            }
            const info = stackInfo[stackName];
            const data = series[j].data[i];
            if (data && data !== '-') {
                if (info.stackStart[i] == null) {
                    info.stackStart[i] = j;
                }
                info.stackEnd[i] = j;
            }
        }
    }
    for (let i = 0; i < series.length; ++i) {
        const data = series[i].data;
        const info = stackInfo[series[i].stack];
        for (let j = 0; j < series[i].data.length; ++j) {
            // const isStart = info.stackStart[j] === i;
            const isEnd = info.stackEnd[j] === i;
            const topBorder = isEnd ? 20 : 0;
            const bottomBorder = 0;
            data[j] = {
                value: data[j],
                itemStyle: {
                    borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
                }
            };
        }
    }


    let option = {
        title: {
            text: '原材料数量',
            subtext: '',
            left: 'left'
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: names
        },
        yAxis: {
            type: 'value'
        },
        series: series
    };
    myChart.value.setOption(option);
}
</script>

<style lang="scss">
.fenxi {

    margin-left: 20px;
}

.select {
    background-color: #5677fc !important;
    color: #fff !important;
}

#chart,
#chart3,
#chart4,
#chart5,
#chart6,
#chart2 {
    flex: 1;
    height: 500px;
    border-radius: 5px;
    margin: 10px 20px;
    background-color: #fff;
    box-shadow: 5px 5px 5px #eee;
    padding: 20px;
}

.right2 {
    width: 20vw;
    background: #fff;
    margin: 10px 20px;
    box-shadow: 5px 5px 5px #eee;
}
</style>
  