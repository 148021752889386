import {
  addProduct,
  getProductType,
  addProductType,
  getProductTypeO,
  getProductList,
  getZujianList,
  getZujianListO,
  getTypeO,
  getQO,
  getBomO
} from "@/api/product";

import { getSupplierO } from "@/api/supplier";
import { reactive, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { useBase, useHandleEdit } from "@/use/base";
export function useAddProduct() {
  const o1 = ref([]);
  getProductTypeO().then((res) => {
    o1.value = res.data;
  });

  getSupplierO().then((res) => {
    sO.value = res.data;
  });
  const options = ref([])
  getTypeO().then(res=>{
    options.value=res.data
  })
  const sType = ref([]);
  const sO = ref([]);
const qO=ref([])
getQO().then(res=>{
  qO.value=res.data
})
  const formData = reactive({
    name: {
      type: "text",
      label: "名称",
      require: true,
      placeholder: "请输入产品名称",
      value: "",
    },
    supplier_id: {
      type: "select",
      label: "供应商",
      require: true,
      options: sO,
      value: "",
      placeholder: "请选择供应商",
    },

    s_type_id: {
      type: "cascader",
      label: "产品分类",
      require: true,
      options: options,
      value: "",
      placeholder: "请选择产品分类",
    },
    quantifier_id: {
      type: "select",
      label: "产品单位",
      require: true,
      options: qO,
      value: "",
      placeholder: "请选择产品单位",
    },
    

    include_tax: {
      type: "switch",
      label: "含税",
      
      value: false,
    },
    price: {
      type: "text",
      label: "价格",
      require: true,
      value: "",
      placeholder: "请输入价格",
    },
    total: {
        type: "text",
        label: "现有数量",
  
        value: "",
        placeholder: "请输入现有数量",
      },
    number: {
      type: "text",
      label: "编号",

      value: "",
      placeholder: "请输入产品编号",
    },

    model: {
      type: "text",
      label: "型号",

      value: "",
      placeholder: "请输入产品型号",
    },
    parameter: {
      type: "text",
      label: "参数",

      value: "",
      placeholder: "请输入产品参数",
    },
    illustrate: {
      type: "text",
      label: "说明",

      value: "",
      placeholder: "请输入产品说明",
    },
    remark: {
      type: "text",
      label: "备注",
      value: "",
      placeholder: "请输入备注",
    },
    img: {
      type: "image",
      label: "图片",
      require: true,
      value: "",
    },
  });

  const submit = async (parmas) => {
    const { code, msg } = await addProduct(parmas);
    if (code == 200) {
      ElMessage.success(msg);
    } else {
      ElMessage.error(msg);
    }
  };


  return { formData, submit };
}
export function useZujian() {
  const so=ref([])
  getSupplierO().then(res=>{
    so.value=res.data
  })
  const searchData = reactive({
   
    name:{
      label:"名称",
      type:"text",
      placeholder: "请输入组件名称",
      value: "",
    },
  });
  const headers = [
    {
      label: "id",
      prop: "id",
    },

    {
      label: "组件名称",
      prop: "name",
    },
    
    {
      label: "照片",
      prop: "img",
    },
    {
      label: "二维码",
      prop: "qrcode",
    },
    {
      label: "共用车型",
      prop: "gy",
    },
    {
      label: "零件种类",
      prop: "number",
    },
    {
      label: "可以组装数量",
      prop: "c_number",
    },
    {
      label: "造价",
      prop: "price",
    },
    {
      label:"操作",
      prop:"caozuo"
    }
  ];
  const { search, tableObjet } = useBase(
    getZujianList,
    searchData,
    null,
    null
  );

  return { headers, searchData, search, tableObjet };
}
export function useProduct() {
  const so=ref([])
  getSupplierO().then(res=>{
    so.value=res.data
  })
  const options = ref([])
  getTypeO().then(res=>{
    options.value=res.data
  })
  getBomO().then(res=>{
    bomOptions.value=res.data
  })

  const bomOptions=ref([])
  const searchData = reactive({
    s_id: {
      label: "供应商",
      type: "select",
      placeholder: "选择供应商",
      value: "",
      options:so,
    },
    name:{
      label:"名称",
      type:"text",
      placeholder: "请输入产品名称",
      value: "",
    },
    number:{
      label:"编号",
      type:"text",
      placeholder: "请输入产品编号",
      value: "",
    },
    remark:{
      label:"备注",
      type:"text",
      placeholder: "请输入产品备注",
      value: "",
    },
    type:{
      label:"产品分类",
      type:"cascader",
      placeholder: "请选择产品分类",
      options:options,
      value: "",
    },
    bom:{
      label:"所属Bom",
      type:"select",
      placeholder: "请选择所属Bom",
      options:bomOptions,
      value: "",
    },
    
  });
  const headers = [
    // {
    //   label: "id",
    //   prop: "id",
    // },

    {
      label: "产品名称",
      prop: "name",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
        label: "编号",
        prop: "number",
      },
      {
        label: "型号",
        prop: "model",
      },
      {
        label: "参数",
        prop: "parameter",
      },
      {
        label: "说明",
        prop: "illustrate",
      },
      {
        label: "单位",
        prop: "q_name",
      },
    {
      label: "照片",
      prop: "img",
    },
    {
      label: "二维码",
      prop: "qrcode",
    },
    {
      label: "分类",
      prop: "t_name",
    },

    {
      label: "所属供应商",
      prop: "s_name",
    },
    {
      label: "共用车型",
      prop: "gy",
    },
    {
      label: "价格",
      prop: "price",
    },
    {
        label: "是否含税",
        prop: "include_tax",
      },

    {
      label:"操作",
      prop:"caozuo"
    }
  ];
  const { search, tableObjet } = useBase(
    getProductList,
    searchData,
    null,
    null
  );

  return { headers, searchData, search, tableObjet };
}
export function useProductType() {
  const headers = [
    {
      prop: "id",
      label: "ID",
    },
    {
      prop: "name",
      label: "分类名称",
    },
    {
      prop: "p_name",
      label: "上级分类名称",
    },

    {
      prop: "caozuo",
      label: "操作",
    },
  ];
  const searchData = reactive({
    name:{
      label:"分类名称",
      type:"text",
      value:""
    }
  });
  const { tableObjet, search } = useBase(
    getProductType,
    searchData,
    false,
    false
  );
  return { headers, search, tableObjet, searchData };
}

export function useAddType() {
  const o = ref([]);
  getProductTypeO().then((res) => {
    o.value = res.data;
  });
  const data = reactive({
    name: {
      type: "text",
      label: "名称",
      value: "",
      require: true,
      placeholder: "请输入分类名称",
    },
    parent_id: {
      type: "select",
      label: "上级",
      value: "",

      placeholder: "选择上级分类,可以不选",
      options: o,
    },
  });
  const submit = (params) => {
    addProductType(params).then((res) => {
      if (res.code == 200) {
        ElMessage.success({ type: "success", message: res.msg });
      } else {
        ElMessage.error(res.msg);
      }
    });
  };
  return { data, submit };
}
